export const mapping = {
  backToOverview: "Back to overview",
  connectionsToggle: "Show connection lines",
  connectionDeleteTitle: "Delete connection?",
  connectionDeleteText:
    "If the connection gets deleted, already configured transformers may not work properly anymore. Do you really want to delete the connection?",
  dataStructureSearch: "Search {name}",
  undo: "Undo",
  redo: "Redo",
  modeMapping: "Data Mapper",
  modeCode: "Code",
  modeDebug: "Debug",
  mappingSaved: "Mapping saved",
  initialVariables: "Input variables",
  transformersOutput: "Transformers output",
  transformersOutputEmpty: "No output defined yet.",
  selectDataSet: "Select data set",
  selectDataSetFirst: "A data set has to be selected first in debug mode.",
  noDataSets: "No data sets found for this structure.",
  mappingUpdated: "Mapping saved",
  mappings: "Mappings",
  mappingCreate: "Create mapping",
  mappingLabel: "Label",
  mappingDescription: "Description",
  mappingActive: "Active",
  mappingDataStructureSource: "Source DataStructure",
  mappingDataStructureTarget: "Target DataStructure",
  mappingSave: "Save mapping",
  dataStructureSource: "Source DataStructure",
  dataStructureTarget: "Target DataStructure",
  detailsBasicConfiguration: "Basis configuration",
  maxInputValues:
    'Maximum amount of input values for transformer "{label}": {maxValues}',
  configurationTitle: "Configuration of {label}",
  configurationNoType: "No configuration available",
  configurationNoTransformer: "Select transformer for configuration",
  availableVariables: "Available variables",
  noAvailableVariables: "No input variables available",
  mappingNotSavedTitle: "Discard changes?",
  mappingNotSavedText:
    "Mapping configuration changes were not saved yet. Do you want to continue despite? These changes will be discarded irreversibly.",
  continueDespite: "Continue despite",
  startMapping: "Start mapping",
  runningMapping: "Running mapping",
  notMapped: "Field not mapped",
  mappingFinished: "Mapping finished successfully",
  transformers: "Transformers",
  transformer: "Transformer",
  transformerLabel: "Label",
  transformerTransformer: "Selected transformer",
  transformerInputVars: "Input variables",
  transformerOutput: "Output",
  runMappingFirst:
    "To show debug information you have to select a data set and start a mapping first.",
  dataStructureChangeTitle: "Edit DataStructure?",
  dataStructureChangeText:
    "Editing the DataStructure can lead to error in current mapping. Also unsaved changes to current mapping will be discarded. Do you want to continue despite?",
  delete: "Delete mapping",
  edit: "Open mapping",
  configuration: "Edit mapping",
  outputValueDefault: "By default, the output of the last transformer run.",
  addTransformer: "Add transformer",
  noInputVars: "No input variables defined.",
  conditions: "Conditions",
  conditionsEmpty: "No conditions set.",
  noLabel: "Label...",
  transformerConfigurationBasic: "Basic configuration",
  transformerConfigurationConditions: "Conditions",
  transformerConfigurationTransformer: "Transformer configuration",
  transformerConfigurationUpdated: "Configuration accepted",
  transformerEdit: "Configure Transformers",
  selectField: "Select field",
  selectOperator: "Select operator",
  addAndCondition: "+ and condition",
  addOrCondition: "+ or condition",
  removeConditionHelp: "Delete condition",
  clearConditionHelp: "Empty condition",
  typeString: "Text",
  typeNumber: "Number",
  typeBoolean: "Bool",
  typeNull: "null",
  typeVariable: "Variable",
  addConditionGroup: "+ and",
  resultElements: "Result elements",
  removeEmptyValues: "Remove empty values",
  elementIndex: "Index",
  onlyRequiredFields: "Only required fields",
  currentCollectionIndex: "Collection index",
  projects: "Projects",
  transformersWizardCollections: "Merge data",
  transformersWizardPostFilter: "Filter data",
  data: "Data",
  noData: "No data",
  noTransformersAvailable: "No transformers available",
  transformersCount: "{count} Transformer",
  mappedSourceFields: "Connected source fields",
  collection: "Type of merge",
  collectionLabel: "Collection",
  collections: "Collections",
  parentConnectionCreated:
    "A necessary link between the parent fields was automatically created",
  collectionsConfiguration: "Collections configuration",
  configureCollectionTransformers: "Configure",
  noFilters: "No filter",
  filterMergedCollections: "Filter merged data",
  fixedNumber: "Fixed number",
  multiply: "Multiply",
  sum: "Sum",
  assign: "Assign",
  uniqueValues: "Unique values",
  valueCount: "Number",
  assignmentType: "Type of merger",
  selectFieldsSource: "Select fields source",
  conditionsNoFields: "No variables available",
  noSelectOptions: "No options available",
  noInputConnections: "No input connections available.",
  staticCollection: "Static collection",
  noValue: "No value",
  transformerDelete: "Delete transformer",
  noOutputVar: "No output variable name defined.",
  mappingConnectionDependenciesTitle: "Dependencies detected!",
  mappingConnectionDependenciesText:
    "The connection selected for deletion has dependent child connections. To proceed with the deletion, all child connections must also be removed. Continue despite this?",
  deleteChildDependencies: "Delete dependent connections as well",
  cancelDeletion: "Cancel deletion operation",
  removeConnection: "Delete connection",
  collectionEntry: "Collection entry",
  collectionEntries: "Number of entries: {count}",
  useDifferentDataStructures:
    "The same DataStructure cannot be selected as the source and target structure.",
  deleteMappingTitle: "",
  deleteMappingText:
    "Do you really want to permanently delete the mapping? This operation cannot be undone.",
  mappingDeleted: "Mapping has been deleted",
  collectionTransformerRequired: 'Transformer "Merge Data" is missing',
  selectValue: "Enter value",
  conditionSourceSource: "Input variables",
  conditionSourceObject: "Source DataStructure",
  conditionSourceConfig: "Config values",
  conditionSourceOutput: "Output variables",
  collectionsConfigurationCollection: "Merge data",
  collectionsConfigurationFilter: "Filter",
  transformersWizardPreFilter: "Pre-filter raw data",
  transformerOutputInvalid: "Output name is reserved or already in use.",
  staticCollectionTitle: "Delete Collection Transformer",
  staticCollectionText:
    "If you proceed, the Collection Transformer including its configuration will be permanently deleted. Do you still want to proceed?",
  resolveRelation: "Resolve Relation",
  unresolveRelation: "Remove resolved fields",
  relationHasConnections:
    "A child field of this relation has an existing connection."
};
