export const queues = {
  queue: "Queue",
  queues: "Queues",
  queuedJobs: "Queued Jobs",
  jobClass: "Job class",
  processId: "Process ID",
  processName: "Process name",
  deleteConfirm: 'Delete queue "{queue}" ?',
  deleteConfirmText:
    'Do you really want to delete the queue "{queue}"? You cannot restore this after delete.',
  deleteSucessText: 'Queue "{queue}" has been deleted',
  processIterationId: "Process Iteration ID",
  tenantId: "Tenant ID",
  tenantName: "Tenant name",
  time: "Time",
  confirmBulkDelete: "Confirm bulk delete",
  confirm: "Confirm {action}?",
  command: "Command",
  allSelectedRowsDelete: "All selected ({count}) rows will be deleted",
  selectAll: "Select all",
  supervisorRecreateConfig: "Generate config",
  supervisorStart: "Start supervisor",
  supervisorRestart: "Restart Supervisor",
  supervisorStop: "Stop supervisor",
  supervisorRecreateConfigSuccess: "Config successfully generated",
  supervisorStartSuccess: "Supervisor successfully started",
  supervisorRestartSuccess: "Supervisor successfully restarted",
  supervisorStopSuccess: "Supervisor successfully stopped",
  output: "Output",
  unselectAll: "Unselect all",
  deleteSelected: "Delete selected",
  startedBy: "Started by",
  maxParallelJobs: "Max parallel jobs",
  name: "Name",
  nameEnter: "Enter name",
  queueCreate: "Create Queue",
  queueCreated: "Queue created",
  queueUpdate: "Update Queue",
  queueUpdated: "Queue updated",
  high: "High",
  low: "Low",
  beforeFiveMinutes: "Before 5 minutes",
  beforeTenMinutes: "Before 10 minutes",
  beforeFifteenMinutes: "Before 15 minutes",
  beforeThirtyMinutes: "Before 30 minutes",
  customDateTime: "Choose time period"
};
