export const dpd = {
  authentication: {
    connectorConfig_wsdl: "WSDL",
    connectorConfig_authenticationWSDL: "Login Service WSDL",
    connectorConfig_username: "Benutzername",
    connectorConfig_password: "Passwort",
    connectorActionTestConnection: "Verbindung zur DPD-Instanz testen",
    connectorActionTestConnectionSuccess:
      "Verbindungsaufbau zur DPD-Instanz erfolreich",
    connectorActionTestConnectionError:
      "Fehler beim Verbindungsaufbau zur DPD-Instanz",
    connectorActionGetRequests: "Verfügbare Requests laden",
    connectorActionGetRequestsError: "Requests konnten nicht geladen werden",
    connectorActionGetRequestsSuccess: "Requests erfolreich geladen",
    version: "Konnektor-Version"
  },
  configuration: {
    request: "Request auswählen",
    connectorConfig_businessUnitId: "Geschäftseinheit des Mandanten",
    connectorConfig_languageId: "Sprach-Code",
    connectorConfig_options: "Weitere Optionen",
    connectorConfig_optionsValues: {
      key: "Schlüssel",
      value: "Wert"
    },
    dataInput: "Dateneingabe via",
    createRequestDataStructure: "Datenstruktur aus Request erstellen",
    createRequestDataStructureSuccess: "Datenstruktur erfolgreich angelegt",
    createRequestDataStructureError: "Fehler beim Erstellen der Datenstruktur"
  },
  input: {
    parameter: "Parameter (json)",
    connectorActionTestRequest: "Request testen",
    connectorActionTestRequestSuccess: "Request testen erfolgreich",
    connectorActionTestRequestError: "Request testen fehlgeschlagen"
  },
  output: {
    createResponseDataStructure: "Datenstruktur aus Response erstellen",
    createResponseDataStructureError: "Fehler beim Erstellen der Datenstruktur",
    createResponseDataStructureSuccess: "Datenstruktur erfolgreich angelegt"
  }
};
