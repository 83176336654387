export const ftp = {
  authentication: {
    password: "Passwort",
    username: "Benutzername"
  },
  configuration: {
    host: "Host",
    root: "Root",
    port: "Port",
    protocol: "Protokoll",
    protocolOptions: {
      ftp: "FTP",
      sftp: "SFTP"
    },
    ssl: "SSL",
    testConnection: "Verbindung testen",
    testConnectionSuccess: "Verbindung testen erfolgreich",
    testConnectionError: "Verbindung testen fehlgeschlagen",
    type: "Typ",
    typeOptions: {
      downloadMove: "Herunterladen und löschen",
      downloadCopy: "Herunterladen",
      exists: "Existiert",
      listFiles: "Dateien auflisten",
      uploadMove: "Hochladen und löschen",
      uploadCopy: "Hochladen",
      delete: "Löschen"
    }
  },
  input: {
    filePath: "Dateipfad",
    files: "Dateien",
    pattern: "Pattern"
  },
  output: {
    files: "Dateien"
  },
  error: {
    log_level: "Log Level",
    log_levelOptions: {
      process: {
        error: "Error",
        info: "Info",
        warning: "Warning"
      }
    },
    log_method: "Logging-Stufe",
    error_handling: "Fehlerbehandlung",
    error_handlingOptions: {
      process_continues: "Workflow läuft weiter",
      process_aborts: "Workflow bricht automatisch ab"
    },
    coreError:
      "Etwas ist schief gelaufen. Wir können die Dateien aus dem Pfad nicht lesen"
  }
};
