import { email } from "@/i18n/en-us/components/workflowElements/email";
import { rest } from "@/i18n/en-us/components/workflowElements/rest";
import { time } from "@/i18n/en-us/components/workflowElements/time";
import { dataWarehouse } from "@/i18n/en-us/components/workflowElements/dataWarehouse";
import { branch } from "@/i18n/en-us/components/workflowElements/branch";
import { loop } from "./workflowElements/loop";
import { php } from "./workflowElements/php";
import { returnData } from "./workflowElements/returnData";
import { mapping } from "./workflowElements/mapping";
import { dataStore } from "@/i18n/en-us/components/workflowElements/dataStore";
import { dataSets } from "@/i18n/en-us/components/workflowElements/dataSets";
import { logging } from "@/i18n/en-us/components/workflowElements/logging";
import { datanorm } from "@/i18n/en-us/components/workflowElements/datanorm";
import { cache } from "@/i18n/en-us/components/workflowElements/cache";
import { descriptionElement } from "@/i18n/en-us/components/workflowElements/descriptionElement";
import { pimcore } from "./workflowElements/pimcore";
import { demoData } from "./workflowElements/demoData";
import { zip } from "./workflowElements/zip";
import { fileAccess } from "./workflowElements/fileAccess";
import { graphQL } from "./workflowElements/graphQL";
import { csv } from "./workflowElements/csv";
import { contentIdentifier } from "./workflowElements/contentIdentifier";
import { deltaStatus } from "./workflowElements/deltaStatus";
import { soap } from "@/i18n/de-de/components/workflowElements/soap";
import { ftp } from "@/i18n/en-us/components/workflowElements/ftp";
import { xml } from "@/i18n/en-us/components/workflowElements/xml";
import { image } from "@/i18n/en-us/components/workflowElements/image";
import { soapConnector } from "@/i18n/en-us/components/workflowElements/soapConnector";
import { breakLoop } from "@/i18n/en-us/components/workflowElements/breakLoop";
import { continueLoop } from "@/i18n/en-us/components/workflowElements/continueLoop";
import { externalTrigger } from "@/i18n/en-us/components/workflowElements/externalTrigger";
import { counter } from "@/i18n/en-us/components/workflowElements/counter";
import { assignment } from "@/i18n/en-us/components/workflowElements/assignment";
import { deepl } from "@/i18n/en-us/components/workflowElements/deepl";
import { csvBatch } from "@/i18n/en-us/components/workflowElements/csvBatch";
import { enventaConnector } from "@/i18n/en-us/components/workflowElements/enventaConnector";
import { shopware5Connector } from "@/i18n/en-us/components/workflowElements/shopware5Connector";
import { restConnector } from "@/i18n/en-us/components/workflowElements/restConnector";
import { xlsxBatch } from "@/i18n/en-us/components/workflowElements/xlsxBatch";
import { xmlBatch } from "@/i18n/en-us/components/workflowElements/xmlBatch";
import { internalTrigger } from "@/i18n/en-us/components/workflowElements/internalTrigger";
import { mysql } from "@/i18n/en-us/components/workflowElements/mysql";

export const workflowElements = {
  selectElement: "Select element",
  clickOnTheElement: "Click on the element do you want to select",
  addSettingsEntry: "Add entry",
  aspectRatioHint:
    "Hint: It's recommended to reupload und recrop the icon after changing the aspect ratio to ensure an optically optimal element.",
  canOnlyBeReferencedOnce: "Kann nur von einem Element referenziert werden",
  communication: "Communication",
  communicationCreate: "Create communication element",
  communicationDetails: "Edit communication element",
  component: "Component",
  components: "Components",
  componentsCreate: "Create component element",
  componentsDetails: "Create component element",
  connector: "Connector",
  connectors: "Connectors",
  connectorsCreate: "Create connector element",
  connectorsDetails: "Edit connector element",
  createElement: "Create element",
  createdSuccessfully: "Workflow element created successfully!",
  data: "Data saving element",
  dataCreate: "Create data saving element",
  dataDetails: "Edit data saving element",
  description: "Description",
  descriptionPlaceholder: "Short description here...",
  elementData: "Element data",
  height: "Aspect ratio height",
  icon: "Icon",
  iconCrop: "Crop",
  iconDropArea: "Drop icon anywhere to upload",
  iconRemove: "Remove icon",
  iconUpload: "Upload icon",
  label: "Label",
  layoutOptions: "Layout options",
  loadDummyData: "Load Dummy Data",
  setDummyData: "Dummy Data set",
  mappingTitle: "Mapping",
  mappings: "Mappings",
  mappingsCreate: "Create mapping element",
  mappingsDetails: "Edit mapping element",
  name: "Name",
  operator: "Operator",
  operatorCreate: "Create operator",
  operatorDetails: "Edit operator",
  operators: "Operators",
  trigger: "Trigger",
  triggers: "Triggers",
  triggersCreate: "Create trigger element",
  triggersDetails: "Edit trigger element",
  type: "Type",
  updatedSuccessfully: "Workflow element updated successfully!",
  width: "Aspect ratio width",
  email: email,
  rest: rest,
  time: time,
  branch: branch,
  loop: loop,
  php: php,
  returnData: returnData,
  dataWarehouse: dataWarehouse,
  mapping: mapping,
  dataStore: dataStore,
  dataSets: dataSets,
  logging: logging,
  datanorm: datanorm,
  cache: cache,
  descriptionElement: descriptionElement,
  pimcore: pimcore,
  demoData: demoData,
  zip: zip,
  fileAccess: fileAccess,
  graphQL: graphQL,
  csv: csv,
  contentIdentifier: contentIdentifier,
  deltaStatus: deltaStatus,
  elementDoesNotLongerExists:
    "The red marked elements cannot be found. It may be necessary to replace them.",
  saveButtonDisabledText:
    "Unavailable items (marked in red) must be removed from the workflow in order for it to be saved.",
  testWorkflow: "Test workflow",
  soap: soap,
  ftp: ftp,
  xml: xml,
  soapConnector: soapConnector,
  image: image,
  breakLoop: breakLoop,
  continueLoop: continueLoop,
  externalTrigger: externalTrigger,
  counter: counter,
  assignment: assignment,
  deepl: deepl,
  csvBatch: csvBatch,
  enventaConnector: enventaConnector,
  shopware5Connector: shopware5Connector,
  restConnector: restConnector,
  xlsxBatch: xlsxBatch,
  xmlBatch: xmlBatch,
  internalTrigger: internalTrigger,
  mysql: mysql
};
