export const dataStore = {
  configuration: {
    type: "Type",
    typeOptions: {
      write: "Write",
      read: "Read"
    },
    createDataStructure: "Select input data",
    dataStructure: "DataStructure",
    dataStructureRead: "DataStructure",
    dataStructureSource: "Source structure",
    dataStructureSourceOptions: {
      array: "Array",
      csv: "CSV"
    },
    dataStructureSelect: "Select/create DataStructure",
    dataStructureSelectOptions: {
      existing: "DataStructure exists",
      new: "Create new DataStructure"
    },
    csvDataStructure: "Select DataStructure",
    readMethod: "Read method",
    readMethodOptions: {
      all: "All records",
      getDirty: "Unprocessed records",
      getClean: "Processed records",
      getByPrimaryId: "By primary key",
      getByFieldValue: "By field value"
    },
    returnMethod: "Choice of return",
    returnMethodOptions: {
      fullRecord: "Full record",
      primaryKey: "Primary key"
    },
    testConfig: "Test configuration",
    testConfigSuccess: "Test config success",
    limit: "Limit",
    offset: "Offset"
  },
  input: {
    help: {
      csvPath: 'Please use a relative file path (eg. "in/test.csv")'
    },
    dataToSave: "Data to save",
    selectCsvPath: "File path",
    warning: "Warning",
    archiveCsvFile: "Archive file",
    primaryKeyInput: "Primary key",
    fieldValueField: "Field",
    fieldValueOperator: "Condition",
    fieldValueOperatorOptions: {
      "=": "==",
      "!=": "!=",
      ">": ">",
      ">=": ">=",
      "<": "<",
      "<=": "<=",
      "IS NULL": "IS NULL",
      "IS NOT NULL": "IS NOT NULL",
      "IS EMPTY": "IS EMPTY",
      "IS NOT EMPTY": "IS NOT EMPTY"
    },
    fieldValueInput: "Field value",
    testConfig: "Test configuration",
    testConfigSuccess: "Test config success"
  }
};
