export const login = {
  hintMail: "Please enter your email address.",
  hintMailFalse: "The given email is not formatted correctly.",
  hintPassword: "Please enter your password",
  imprint: "Imprint",
  label: "We Connect<br/>Applications Together", // HTML allowed here
  login: "Login",
  loginFailed: "Login data do not match.",
  mail: "Email address",
  numberCustomer: "Client number",
  password: "Password",
  passwordChange: "Change password",
  passwordRepeat: "Repeat password",
  passwordForgotten: "Forgot password?",
  passwordResetTitle: "Reset password",
  passwordResetDescription: "Enter email address to reset password.",
  passwordResetFor: "Reset password for {email}",
  passwordResetSubmit: "Submit",
  passwordResetMailSentTitle: "Check your inbox!",
  passwordResetMailSent:
    "If the provided email address is linked to an existing user, a mail with a password reset link has been sent to that email address.",
  backToLogin: "Back to login",
  privacyPolicy: "Privacy policy",
  welcomeAt: "Welcome at",
  tooManyLoginAttempts:
    "Too many login attempts. Please wait before trying again!",
  loginDetailsWrong: "Could not sign you in with those details",
  accountLocked:
    "Your account is locked. Please contact us for more informations",
  twoFaRequired:
    "It seems you are connecting from a new device. Please provide the 2FA code you received.",
  twoFaRequiredTitle: "Two factor authentication",
  twoFaCode: "Two factor authentication code",
  twoFaCodeWrongTitle: "Two factor authentication failed",
  twoFaCodeWrong: "The specified 2FA code was incorrect.",
  twoFaSend: "Send code",
  enterCode: "Enter the code",
  saveDeviceAsTrusted: "Save this device as trusted for 30 days.",
  unstrustedIP:
    "You are not allowed to login from your current IP address ({ip})",
  sendMeAnotherCode: "Send me another code",
  submit: "Submit",
  accountDeactivated:
    "Your account has been deactivated. Please contact your partner for further information",
  formInvalid: "Login form invalid"
};
