export const formHelper = {
  acceptedFileLength: "Es darf nur eine Datei auf einmal hochgeladen werden",
  acceptedFileTypes: "Dateitypen: {fileTypes}",
  addGroup: "Gruppe hinzufügen",
  array: "Array",
  arrayAddEntry: "Eintrag hinzufügen",
  browse: "Auswählen",
  cancel: "Abbrechen",
  condition: "Bedingung",
  conditionClear: "Bedingung leeren",
  conditionNest: "Bedingung verschachteln",
  conditionRemove: "Bedingung entfernen",
  copy: "Wert kopieren",
  datePicker: {
    itemsSelected: "{0} ausgewählt"
  },
  default: "Default",
  directInput: "Direkteingabe",
  dropFilesToUpload: "Legen Sie hier Dateien zum Hochladen ab",
  dropZoneLabel: "Dateien hierhin ziehen oder klicken, um sie hochzuladen",
  fileSizeLimitError:
    "Die ausgewählte Datei überschreitet die Upload Größe ({size}) | Die ausgewählten Dateien überschreiten die Upload Größe ({size})",
  imageAlt: "Bilddatei",
  imageCropLabel: "Zuschneiden",
  imageDropZoneLabel: "Bild zum Hochladen an eine beliebige Stelle ziehen",
  imageRemove: "Entfernen",
  imageUploadLabel: "Bild hochladen",
  jsonAddField: "Feld hinzufügen",
  jsonRemoveField: "Feld entfernen",
  key: "Schlüssel",
  noActionDefined: "Keine Action zur Ausführung übergeben",
  noFileChosen: "Keine Datei ausgewählt",
  noMatchingVariables: "Keine passenden Variablen gefunden.",
  noSelectOptions: "Keine Optionen verfügbar.",
  noValueFound: "Kein Wert gefunden.",
  noVariables: "Keine Variablen verfügbar.",
  notPictureError: "Ausgewählte Datei ist keine Bilddatei.",
  ok: "OK",
  remove: "Entfernen",
  removeGroup: "Gruppe löschen",
  reloadOptions: "Optionen neu laden",
  requestCanceled: "Request wurde abgebrochen",
  returnType: "Rückgabewert",
  selectDate: "Datum auswählen",
  selectDateTime: "Datum und Zeit auswählen",
  selectTime: "Zeit auswählen",
  uploadLabel: "Dateien hierhin ziehen oder klicken, um sie hochzuladen",
  uploadSizeLimit: "Maximale Dateigröße: {limit}",
  value: "Wert",
  variableFieldPlaceholder: "Variable auswählen",
  variablesFieldSwitch: "Variablenauswahl",
  selectVariable: "Variable auswählen",
  selectOption: "Option auswählen",
  moveConditionUp: "Bedingung nach links verschieben",
  moveConditionDown: "Bedingung nach rechts verschieben",
  errors: {
    required: 'Feld "{label}" ist ein Pflichtfeld.',
    minLength: "Minimale Länge: {min}.",
    maxLength: "Maximale Länge: {max}.",
    alpha: "Nur Buchstaben erlaubt.",
    alphaNum: "Nur aplhanumerische Zeichen erlaubt.",
    alphaSpace: "Nur Buchstaben und Leerzeichen erlaubt.",
    alphaNumSpace: "Nur aplhanumerische Zeichen und Leerzeichen erlaubt.",
    email: "Keine gültige E-Mail Adresse.",
    numberOrVariable: "Nur Zahlen oder Variablen erlaubt.",
    numeric: "Nur natürliche Zahlen erlaubt.",
    integer: "Nur ganze Zahlen erlaubt.",
    decimal: "Nur Ganz- und Fließkommazahlen erlaubt.",
    json: "Eingabe entspricht nicht dem JSON Format.",
    invalidNumberValue: "Nur numerische Werte erlaubt.",
    invalidNumberOrVariableValue:
      "Nur numerische Werte oder Variablen erlaubt.",
    variable: "Wert muss Variable sein."
  },
  returnTypes: {
    text: "Text",
    number: "Zahl",
    variable: "Variable",
    true: "Bool (true)",
    false: "Bool (false)",
    null: "Null"
  },
  editor: {
    paragraph: "Paragraph",
    heading1: "Überschrift 1",
    heading2: "Überschrift 2",
    heading3: "Überschrift 3"
  },
  /*************************** Test Values ***************************/
  test: {
    label: "Test Text",
    prepend: "Awesome",
    append: "Dude",
    yedi: "yedi Hilfe",
    placeholder: "Your awesome text here...",
    labelNum: "Test Nummer",
    placeholderNum: "Hier nur Nummern oder Variablen",
    selectFeld: "Select Feld",
    selectFeldChildren: "Verschachteltes Select Feld",
    select: "Auswählen",
    multiSelectFeld: "Multi Select",
    actionLabel: "Test Action",
    json: "JSON Test",
    key: "Schlüssel",
    value: "Wert"
  },
  dataOptions: {
    loading:
      "Die Optionen werden gerade geladen. Bitte warten Sie einen Moment.",
    notFound: "Es konnten keine Optionen gefunden werden.",
    search: "Tippen Sie, um nach einer Option zu suchen."
  },
  jsonStructure: {
    title: "JSON Struktur",
    invalid: "Ungültiges oder leeres JSON",
    string: "String",
    number: "Number",
    boolean: "Boolean",
    object: "Object",
    array: "Array",
    unknown: "Unknown"
  }
};
