export const mysql = {
  connectionTestToViewTables:
    "To view the database tables, please run 'Test connection' first",
  databaseTables: "Database Tables",
  showDatabaseTables: "Show database tables",
  disclaimer:
    "Disclaimer: All mysql query will be runned on your own behalf. We cannot revert the modified data.",
  parsedTwig: "Parsed Twig",
  authentication: {
    help: {
      testConnection: "Test Connection",
      parseTwig: "Parse Twig"
    },
    testConnection: "Test Connection"
  },
  configuration: {
    testQuery: "Run (TEST) Mysql Query",
    parseTwig: "Parse Twig",
    help: {
      testQuery: "Run (TEST) Mysql Query"
    }
  }
};
