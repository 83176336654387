import { processStatus } from "@/i18n/en-us/components/processStatus";

export const tableHelper = {
  addFilter: "Add filter",
  applyFilter: "Apply Filter",
  content_identifier: "Content identifier",
  filter: "Filter",
  filterReset: "Reset Filter",
  finished_at: "End date",
  id: "ID",
  process: processStatus,
  recordsPerPage: "Records per page",
  removeFilter: "Remove filter",
  search: "Search",
  searchTerm: "Search term",
  started_at: "Start date",
  status: "Status",
  type: "Type",
  hasError: "Pending import/export",
  updated_at: "Update date",
  standardDs: "Is standard",
  deleteSelectedRows: "Delete the selected entries",
  confirmDeletion: "Do you really want to delete the selected entries?",
  export: {
    title: "Export CSV",
    selectColumns: "Please select the columns you want to export.",
    columnName: "Column name",
    selectedColumns: "Selected columns",
    noColumnsSelected: "No columns selected",
    export: "Export",
    onExport:
      "Export started. This may take a while. You will find the exported file in your File Explorer (/exports).",
    selectFilters: "Please select filters for the data."
  },
  rows: {
    allSelected: "Selected all {count} records that match this search query.",
    pageSelected: "{count} records on this page selected.",
    selectAll: "Select all records that match this search query."
  },
  withSoftDeleted: "Softdelete"
};
