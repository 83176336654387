import { BaseService } from "@/components/Tools/baseService";
import ApiService from "@/core/services/api.service";

let url = process.env.VUE_APP_API_ADMIN;

class PresetVersions extends BaseService {
  getNonArchived(params) {
    let optionalHeaders = {};
    let options = new URLSearchParams(params).toString();

    const filter = [
      {
        key: "is_archived",
        op: "equals",
        value: false
      }
    ];
    const filterParams = this.getParams(filter, params);

    return ApiService.get(
      url,
      "preset_versions?" + options + filterParams,
      optionalHeaders
    );
  }
}
export default new PresetVersions();
