import { email } from "@/i18n/de-de/components/workflowElements/email";
import { rest } from "@/i18n/de-de/components/workflowElements/rest";
import { time } from "@/i18n/de-de/components/workflowElements/time";
import { dataWarehouse } from "@/i18n/de-de/components/workflowElements/dataWarehouse";
import { branch } from "./workflowElements/branch";
import { loop } from "./workflowElements/loop";
import { php } from "./workflowElements/php";
import { returnData } from "./workflowElements/returnData";
import { mapping } from "./workflowElements/mapping";
import { logging } from "@/i18n/de-de/components/workflowElements/logging";
import { dataStore } from "@/i18n/de-de/components/workflowElements/dataStore";
import { dataSets } from "@/i18n/de-de/components/workflowElements/dataSets";
import { internalTrigger } from "./workflowElements/internalTrigger";
import { datanorm } from "./workflowElements/datanorm";
import { cache } from "@/i18n/de-de/components/workflowElements/cache";
import { descriptionElement } from "@/i18n/de-de/components/workflowElements/descriptionElement";
import { pimcore } from "./workflowElements/pimcore";
import { demoData } from "./workflowElements/demoData";
import { zip } from "./workflowElements/zip";
import { fileAccess } from "./workflowElements/fileAccess";
import { graphQL } from "./workflowElements/graphQL";
import { csv } from "./workflowElements/csv";
import { contentIdentifier } from "./workflowElements/contentIdentifier";
import { deltaStatus } from "./workflowElements/deltaStatus";
import { soap } from "@/i18n/de-de/components/workflowElements/soap";
import { ftp } from "@/i18n/de-de/components/workflowElements/ftp";
import { xml } from "@/i18n/de-de/components/workflowElements/xml";
import { image } from "@/i18n/de-de/components/workflowElements/image";
import { counter } from "@/i18n/de-de/components/workflowElements/counter";
import { soapConnector } from "@/i18n/de-de/components/workflowElements/soapConnector";
import { breakLoop } from "@/i18n/de-de/components/workflowElements/breakLoop";
import { continueLoop } from "@/i18n/de-de/components/workflowElements/continueLoop";
import { externalTrigger } from "@/i18n/de-de/components/workflowElements/externalTrigger";
import { mssql } from "@/i18n/de-de/components/workflowElements/mssql";
import { assignment } from "@/i18n/de-de/components/workflowElements/assignment";
import { deepl } from "@/i18n/de-de/components/workflowElements/deepl";
import { csvBatch } from "@/i18n/de-de/components/workflowElements/csvBatch";
import { enventaConnector } from "@/i18n/de-de/components/workflowElements/enventaConnector";
import { shopware5Connector } from "@/i18n/de-de/components/workflowElements/shopware5Connector";
import { restConnector } from "@/i18n/de-de/components/workflowElements/restConnector";
import { xlsxBatch } from "@/i18n/de-de/components/workflowElements/xlsxBatch";
import { xmlBatch } from "@/i18n/de-de/components/workflowElements/xmlBatch";
import { msbcSoapConnector } from "@/i18n/de-de/components/workflowElements/msbcSoapConnector";
import { myFactory } from "@/i18n/de-de/components/workflowElements/myFactory";
import { dpd } from "@/i18n/de-de/components/workflowElements/dpd";
import { mysql } from "@/i18n/de-de/components/workflowElements/mysql";

export const workflowElements = {
  selectElement: "Element auswählen",
  clickOnTheElement: "Klicken Sie auf das Element, die Sie auswählen möchten",
  addSettingsEntry: "Eintrag anlegen",
  aspectRatioHint:
    "Hinweis: Nach jeder Änderung des Seitenverhältnisses wird empfohlen das Icon neu hochzuladen und zuzuschneiden, um ein optisch optimales Element zu gewährleisten.",
  canOnlyBeReferencedOnce:
    "This element can only be referenced by one selector",
  communication: "Kommunikation",
  communicationCreate: "Kommunikation anlegen",
  communicationDetails: "Kommunikation bearbeiten",
  component: "Komponente",
  components: "Komponenten",
  componentsCreate: "Komponente  anlegen",
  componentsDetails: "Komponente bearbeiten",
  connector: "Konnektor",
  connectors: "Konnektoren",
  connectorsCreate: "Konnektor anlegen",
  connectorsDetails: "Konnektor bearbeiten",
  createElement: "Element anlegen",
  createdSuccessfully: "Workflow Element wurde erfolgreich erstellt!",
  data: "Datenspeicherung",
  dataCreate: "Datenspeicherung anlegen",
  dataDetails: "Datenspeicherung bearbeiten",
  description: "Beschreibung",
  descriptionPlaceholder: "Kurzer Beschreibungstext...",
  elementData: "Element Daten",
  height: "Seitenverhältnis Höhe",
  icon: "Icon",
  iconCrop: "Zuschneiden",
  iconDropArea: "Icon zum Hochladen an eine beliebige Stelle ziehen",
  iconRemove: "Icon entfernen",
  iconUpload: "Icon hochladen",
  label: "Bezeichnung",
  layoutOptions: "Layout Einstellungen",
  loadDummyData: "Dummy Daten laden",
  setDummyData: "Dummy Daten gesetzt",
  mappingTitle: "Mapping",
  mappings: "Mappings",
  mappingsCreate: "Mapping anlegen",
  mappingsDetails: "Mapping bearbeiten",
  name: "Name",
  operator: "Operator",
  operatorCreate: "Operator anlegen",
  operatorDetails: "Operator bearbeiten",
  operators: "Operatoren",
  trigger: "Trigger",
  triggers: "Trigger",
  triggersCreate: "Trigger anlegen",
  triggersDetails: "Trigger bearbeiten",
  type: "Typ",
  updatedSuccessfully: "Workflow Element wurde erfolgreich aktualisiert!",
  width: "Seitenverhältnis Breite",
  email: email,
  rest: rest,
  time: time,
  branch: branch,
  loop: loop,
  php: php,
  returnData: returnData,
  dataWarehouse: dataWarehouse,
  mapping: mapping,
  logging: logging,
  dataStore: dataStore,
  dataSets: dataSets,
  internalTrigger: internalTrigger,
  datanorm: datanorm,
  cache: cache,
  descriptionElement: descriptionElement,
  pimcore: pimcore,
  demoData: demoData,
  zip: zip,
  fileAccess: fileAccess,
  graphQL: graphQL,
  csv: csv,
  contentIdentifier: contentIdentifier,
  deltaStatus: deltaStatus,
  elementDoesNotLongerExists:
    "Achtung! Die rot markierten Elemente können nicht gefunden werden. Eventuell ist es notwendig, die Elementen zu ersetzen.",
  saveButtonDisabledText:
    "Nicht verfügbare Elemente (rot markiert) müssen aus dem Workflow entfernt werden, damit dieser gespeichert werden kann.",
  testWorkflow: "Workflow testen",
  soap: soap,
  ftp: ftp,
  xml: xml,
  soapConnector: soapConnector,
  image: image,
  counter: counter,
  breakLoop: breakLoop,
  continueLoop: continueLoop,
  externalTrigger: externalTrigger,
  mssql: mssql,
  assignment: assignment,
  requiredFieldsError: "Folgende Felder müssen ausgefüllt sein: <br>",
  deepl: deepl,
  csvBatch: csvBatch,
  enventaConnector: enventaConnector,
  shopware5Connector: shopware5Connector,
  restConnector: restConnector,
  xlsxBatch: xlsxBatch,
  xmlBatch: xmlBatch,
  MsbcSoapConnector: msbcSoapConnector,
  myfactoryConnector: myFactory,
  dpdConnector: dpd,
  mysql: mysql
};
