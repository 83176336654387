export const tracking = {
  cancelOrders: "Aufträge automatisch im Verkaufskanal stornieren?",
  cancelOrdersHelp:
    "Hier kannst du auswählen, ob Aufträge, die in Xentral storniert werden, auch im Verkaufskanal automatisch storniert werden sollen.",
  carrierAssignmentHelp:
    "Hier können die Versandarten aus Xentral den entsprechenden Versanddienstleistern in {appName} zugeordnet werden.",
  city: "Stadt",
  completed: "Sobald der Auftrag oder Teilauftrag abgeschlossen ist",
  countryCode: "Land (ISO 3166-1 alpha-3 code)",
  notifyByMail: "E-Mail Benachrichtigung aktivieren",
  notifyByMailHelp:
    "Hier kannst du auswählen, ob {appName} eine E-Mail an den Kunden verschicken soll, wenn die Lieferung verschickt wurde.",
  returns: "Retouren zurückmelden",
  returnsHelp:
    "Hier kannst du auswählen, ob Retouren, die in Xentral erstellt werden, automatisch an den Verkaufskanal übertragen werden sollen.",
  shipFromAddress:
    "Die hier angegebene Adresse wird als Lageradresse bei der Rückmeldung von  Auftragsstatus und Trackings an Otto verwendet.",
  trackingAvailable: "Sobald Tracking-Informationen verfügbar sind",
  updateStrategyOrderStatus:
    "Update Strategie für Auftragsstatus & Tracking Export",
  updateStrategyOrderStatusHelp:
    "Wann soll der Auftragsstatus zurückgemeldet werden?",
  updateStrategyOrderStatusPerPositionHelp:
    "Wann soll der Auftragsstatus pro Position zurückgemeldet werden?",
  zipCode: "Postleitzahl (PLZ)",
  provisionalReturnNumbers: "Retourennummern",
  provisionalReturnNumbersInfo:
    "Füge hier deine Retourennummern kommagetrennt ein. Bei der Versandrückmeldung werden dann aus diesem Pool die Nummern verwendet."
};
