// action types
export const UPDATE_TABLE_ENTRIES = "updateTableEntries";
export const SET_TABLE_ENTRIES = "setTableEntries";
export const INSERT_TABLE_ENTRIES = "setTableEntries";
export const UPDATE_TEST_PROCESS_DATA = "updateTestProcessData";

// mutation types
export const SET_TEST_PROCESS_DATA = "setTestProcessData";
export const EMPTY_ENTRY_STATE = "emptyEntryState";
export const ADD_TABLE_ENTRY = "addTableEntry";
export const DELETE_TABLE_ENTRY_GROUP = "deleteTableEntryGroup";
export const DELETE_TABLE_ENTRY = "deleteTableEntry";

function deleteAndMoveUp(array, process_id) {
  for (let i = 0; i < array.length; i++) {
    const entry = array[i];
    if (entry.id === process_id) {
      // delete the entry
      array.splice(i, 1);

      // move children up
      for (let j = 0; j < entry.children.length; j++) {
        array.splice(i + j, 0, entry.children[j]);
      }

      // return true to indicate deletion
      return true;
    } else if (entry.children) {
      // recursively call function for children
      const childDeleted = deleteAndMoveUp(entry.children, process_id);
      if (childDeleted) {
        // if a child was deleted, move its children up
        for (let j = 0; j < entry.children.length; j++) {
          array.splice(i + j + 1, 0, entry.children[j]);
        }
        // remove the empty children array
        delete entry.children;
        // return true to indicate deletion
        return true;
      }
    }
  }
  // return false to indicate no deletion
  return false;
}

export default {
  state: {
    testProcessData: "",
    processTableEntries: []
  },
  getters: {
    processTableEntries(state) {
      return state.processTableEntries;
    },
    testProcessData(state) {
      return state.testProcessData ? JSON.parse(state.testProcessData) : [];
    }
  },
  mutations: {
    [UPDATE_TABLE_ENTRIES](state, payload) {
      payload.forEach(processEntry => {
        state.processTableEntries.push(processEntry);
      });
    },
    [INSERT_TABLE_ENTRIES](state, payload) {
      payload.forEach(processEntry => {
        if (state.processTableEntries.indexOf(processEntry) === -1)
          state.processTableEntries.push(processEntry);
      });
    },
    [SET_TABLE_ENTRIES](state, payload) {
      state.processTableEntries = [];
      state.processTableEntries = payload;
    },
    [EMPTY_ENTRY_STATE](state) {
      state.processTableEntries = [];
    },
    [ADD_TABLE_ENTRY](state, payload) {
      state.processTableEntries.push(payload);
    },
    [DELETE_TABLE_ENTRY_GROUP](state, payload) {
      deleteAndMoveUp(state.processTableEntries, payload);
    },
    [DELETE_TABLE_ENTRY](state, processTableId) {
      let index = state.processTableEntries.findIndex(
        entry => entry.id === processTableId
      );
      if (index !== -1) {
        state.processTableEntries.splice(index, 1);
      }
    },
    [SET_TEST_PROCESS_DATA](state, payload) {
      // we need to do this, because JSON.stringify ignores string key values in arrays
      const data = Object.assign({}, payload);
      state.testProcessData = JSON.stringify(data);
    }
  },
  actions: {
    [UPDATE_TABLE_ENTRIES](context, payload) {
      context.commit(UPDATE_TABLE_ENTRIES, payload);
    },
    [INSERT_TABLE_ENTRIES](context, payload) {
      context.commit(INSERT_TABLE_ENTRIES, payload);
    },
    [SET_TABLE_ENTRIES](context, payload) {
      context.commit(SET_TABLE_ENTRIES, payload);
    },
    [EMPTY_ENTRY_STATE](context) {
      context.commit(EMPTY_ENTRY_STATE, context);
    },
    [ADD_TABLE_ENTRY](context, payload) {
      payload = {
        id: payload.id,
        process_id: payload.process_id,
        type: payload.type,
        order_index: payload.order_index,
        collapsed: payload.collapsed,
        parent_id: payload.parent_id,
        label: payload.label,
        process: payload.process,
        children: []
      };
      context.commit(ADD_TABLE_ENTRY, payload);
    },
    [DELETE_TABLE_ENTRY_GROUP](context, payload) {
      context.commit(DELETE_TABLE_ENTRY_GROUP, payload);
    },
    [DELETE_TABLE_ENTRY](context, payload) {
      context.commit(DELETE_TABLE_ENTRY, payload);
    },
    [UPDATE_TEST_PROCESS_DATA](context, payload) {
      context.commit(SET_TEST_PROCESS_DATA, payload);
    }
  }
};
