import Vue from "vue";
import ApiService from "@/core/services/api.service";
import _ from "lodash";
import Presets from "@/components/Settings/Presets/presets";

let url = process.env.VUE_APP_API_ADMIN;

export const LOAD_CONFIG_VALUES = "loadConfigValues";
export const LOAD_PRESET_VALUES = "loaPresetValues";

export const SET_VARIABLES = "setVariables";
export const SET_CUSTOM_VARIABLES = "setCustomVariables";
export const REMOVE_CUSTOM_VARIABLES = "removeCustomVariables";

function normalizeVariables(payload) {
  // Get data from payload
  const { json, text, value, variables } = payload;
  // Get text key and value key with fallback values
  let textKey = text ?? "text",
    valueKey = value ?? "value";
  // Create to array to store normalized variables
  let variablesNormalized = [];
  // If variables are in json format
  if (json) {
    // Loop through first level keys
    Object.keys(variables).forEach(key => {
      let text = key;
      let value = {};
      // If text includes dot notation, split up
      if (String(text).includes(".")) {
        let path = String(text).split(".");
        // Use first part of split key as text
        text = path.shift();
        _.set(value, path, variables[key]);
      } else {
        value = variables[key];
      }
      // Set variable with key as text and value as value
      variablesNormalized.push({
        text: text,
        value: value,
        id: null
      });
    });
  } else {
    // Else if variables are an array of object
    // Loop through variables
    variables?.forEach(variable => {
      let text = variable[textKey];
      let value = {};
      // If text includes dot notation, split up
      if (String(text).includes(".")) {
        let path = String(text).split(".");
        // Use first part of split key as text
        text = path.shift();
        let existingParent = variablesNormalized.find(el => el.text === text);

        if (existingParent) {
          _.set(existingParent.value, path, variable[valueKey]);
          return;
        }

        _.set(value, path, variable[valueKey]);
      }
      // Else if there are additional keys inside the nested attribute we want to add them as nested variables
      else if (variable?.nested?.length) {
        for (let key of variable.nested) {
          _.set(value, key, variable[valueKey]);
        }
      } else {
        value = variable[valueKey];
      }
      // Set normalized variable with text and value from given keys
      variablesNormalized.push({
        text: text,
        value: value,
        id: variable.id ?? null
      });
    });
  }
  variablesNormalized.forEach(variable => {
    // Unwind nested dot notations
    variable.value = unwindDotNotations(variable.value);
  });
  // Return normalized variables
  return variablesNormalized;
}

function unwindDotNotations(value) {
  // If value is array or not of type object, return value
  if (value === null || typeof value !== "object" || Array.isArray(value)) {
    return value;
  }
  let newValue = {};
  Object.keys(value).forEach(key => {
    let text = key,
      subValue = {};
    if (String(text).includes(".")) {
      let path = String(text).split(".");
      // Use first part of split key as text
      text = path.shift();
      // Set unwind value by path
      _.set(subValue, path, unwindDotNotations(value[key]));
    } else {
      // Set unwind value by path
      subValue = unwindDotNotations(value[key]);
    }
    // Set value with updated key in new value object
    newValue[text] = subValue;
  });
  return newValue;
}

// Merge variables instead of replacing them
function mergeVariables(newVariables, stateVariables, isJson) {
  // Filter variables with same id (or text if isJson is true)
  let variables = stateVariables.filter(variable => {
    return isJson
      ? !newVariables.find(n => n.text === variable.text)
      : !newVariables.find(n => (n.id ?? null) === variable.id);
  });
  // Loop through new variables
  newVariables.forEach(variable => {
    // Add variable to array
    variables.push(variable);
  });
  // Return merged variables
  return variables;
}

// IMPORTANT: Variables have to be normalized first
function flattenVariables(variables) {
  let flatten = [];
  // Loop through root level variables
  variables.forEach(variable => {
    // Add text
    flatten.push(variable.text);
    // If value is of type object, get value's texts
    if (typeof variable.value === "object" && variable.value !== null) {
      let childrenTexts = getChildrenTexts(variable.value);
      // Prefix texts with variable's text
      childrenTexts.forEach(text => {
        flatten.push(variable.text + "." + text);
      });
    }
  });
  // Return texts
  return flatten;
}

function getChildrenTexts(children) {
  // New array to store texts
  let texts = [];
  // Loop through children's keys
  Object.keys(children).forEach(key => {
    // Add key to texts
    texts.push(key);
    // If child also has an object as value, repeat
    if (typeof children[key] === "object" && children[key] !== null) {
      let childrenTexts = getChildrenTexts(children[key]);
      childrenTexts.forEach(text => {
        texts.push(key + "." + text);
      });
    }
  });
  // Return texts
  return texts;
}

export default {
  namespaced: true,
  state: {
    configValues: {
      variables: [],
      variablesNormalized: [],
      variablesFlat: []
    },
    presetValues: {
      variables: [],
      variablesNormalized: [],
      variablesFlat: []
    },
    custom: {}
  },
  getters: {
    configValues: state => {
      return state.configValues.variables;
    },
    configValuesNormalized: state => {
      return state.configValues.variablesNormalized;
    },
    configValuesFlat: state => {
      return state.configValues.variablesFlat;
    },
    presetValues: state => {
      return state.presetValues.variables;
    },
    presetValuesNormalized: state => {
      return state.presetValues.variablesNormalized;
    },
    presetValuesFlat: state => {
      return state.presetValues.variablesFlat;
    },
    configValueById: state => id => {
      return state.configValues.variables.find(cv => cv.id === id);
    },
    configValueByName: state => name => {
      return state.configValues.variables.find(cv => cv.name === name);
    },
    customVariablesSet: state => name => {
      return state.custom[name] ?? {};
    }
  },
  actions: {
    async [LOAD_CONFIG_VALUES](context) {
      return await ApiService.get(url, "config_values?noPagination=true").then(
        response => {
          context.commit(LOAD_CONFIG_VALUES, response.data);
        }
      );
    },
    async [LOAD_PRESET_VALUES](context) {
      return await Presets.getAll({ noPagination: true }).then(response => {
        context.commit(LOAD_PRESET_VALUES, response.data.data);
      });
    },
    /**
     * Sets a set of variables by key
     * @param context
     * @param payload
     */
    [SET_VARIABLES](context, payload) {
      context.commit(SET_VARIABLES, payload);
    },
    /**
     * Sets a set of custom variables by key
     * @param context
     * @param {Object} payload
     * @param {string} payload.name - Unique identifier for custom variables
     * @param {any} payload.variables - Your custom variables
     */
    [SET_CUSTOM_VARIABLES](context, payload) {
      context.commit(SET_CUSTOM_VARIABLES, payload);
    },
    /**
     * Remove a set of custom variables
     * @param context
     * @param {string} payload - Name of custom variables set
     */
    [REMOVE_CUSTOM_VARIABLES](context, payload) {
      context.commit(REMOVE_CUSTOM_VARIABLES, payload);
    }
  },
  mutations: {
    [LOAD_CONFIG_VALUES](state, payload) {
      const normalizePayload = {
        text: "name",
        variables: payload
      };
      let normalized = normalizeVariables(normalizePayload);
      let flat = flattenVariables(normalized);

      state.configValues = {
        variables: payload,
        variablesNormalized: normalized,
        variablesFlat: flat
      };
    },
    [LOAD_PRESET_VALUES](state, payload) {
      const normalizePayload = {
        text: "name",
        variables: payload
      };
      let normalized = normalizeVariables(normalizePayload);
      let flat = flattenVariables(normalized);

      state.presetValues = {
        variables: payload,
        variablesNormalized: normalized,
        variablesFlat: flat
      };
    },
    [SET_VARIABLES](state, payload) {
      // Get normalized variables
      // Set data, with variables flatten
      const data = {
        name: payload.name,
        prefix: payload.prefix ?? "",
        variables: payload.variables,
        showValue: payload.showValue ?? false
      };
      Vue.set(state.custom, payload.name, data);
    },
    [SET_CUSTOM_VARIABLES](state, payload) {
      // Get normalized variables
      let variables = normalizeVariables(payload);
      // Filter variables with no text
      variables = variables.filter(v => v.text !== "");
      // Merge variables if necessary
      if (payload.merge) {
        variables = mergeVariables(
          variables,
          state.custom[payload.name].variables,
          payload.json
        );
      }

      // Set data, with variables flatten
      const data = {
        name: payload.name,
        prefix: payload.prefix ?? "",
        variables: variables,
        variablesFlat: flattenVariables(variables),
        showValue: payload.showValue ?? false,
        merge: payload.merge ?? false
      };
      Vue.set(state.custom, payload.name, data);
    },
    [REMOVE_CUSTOM_VARIABLES](state, payload) {
      Vue.delete(state, payload);
    }
  }
};
