import { ref, watchEffect } from "vue";

export const useApplyFilter = filters => {
  const filter = ref({});

  if (!filters?.value?.length) {
    return { filter };
  }

  watchEffect(() => {
    filter.value = {};
    for (const [index, singleFilter] of Object.entries(filters.value || [])) {
      const filterKey = "filter[" + index + "]";

      filter.value[filterKey + "[key]"] = singleFilter.key ?? "";
      filter.value[filterKey + "[op]"] = singleFilter.op ?? "";

      if (Array.isArray(singleFilter.value)) {
        for (const [valueIndex, value] of Object.entries(singleFilter.value)) {
          filter.value[filterKey + "[value][" + valueIndex + "]"] = value ?? "";
        }
      } else {
        filter.value[filterKey + "[value]"] = singleFilter.value ?? "";
      }
    }
  });

  return { filter };
};
