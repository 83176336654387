export const dynamicModels = {
  acceptedFileTypes: "Accepted file types",
  acceptedFormats: "Accepted formats",
  addDynamicField: "Add dynamic field",
  analyze: "Analyze",
  analyzeSettings: "Analyze settings",
  cancelCreate: "Cancel model creation",
  className: "Class name",
  commaSeparated: "Seperate multiple by comma.",
  confirmType: "Confirm type",
  createModel: "Create model",
  defaultTen: "Default 10",
  deleteModelText:
    'Do you really want to delete model "{name}"? This process is irreversible.',
  deleteModelTitle: "Delete model",
  delimiter: "Delimiter",
  details: "Details",
  detailsBasic: "Basic information",
  detailsBasicConfiguration: "Basic configuration",
  detailsTable: "Table configuration",
  detectAutomatically: "Detect automatically",
  dotNotation: "Select by dot notation.",
  dropPlaceholder: "Drop file here",
  dynamicFieldGlue: "Glue",
  dynamicFieldName: "Field name",
  dynamicFieldPreview: "Preview structure",
  dynamicFieldValues: "Field selection",
  dynamicFields: "Dynamic fields",
  dynamicModels: "Dynamic models",
  emptyForAutoDetection: "Leave empty for auto detection.",
  enclosure: "Special characters enclosure",
  encoding: "Encoding",
  entryQuantity: "Number of entries to analyze",
  entryQuantityHelp: "This is the maximum of entries to analyze.",
  file: "File",
  filePlaceholder: "Choose or drop file...",
  fileSelection: "File selection",
  fileType: "File type",
  forceArrayOn: "Force array on",
  forceEncoding: "Force encoding",
  showForceEncodingHelp:
    "Check encoding when file should be imported to DataExplorer.",
  forceArrayOnAdd: "Add field",
  forceArrayOnHelp:
    "Always handle child elements of this tag as array. Only tag name, no dot notation.",
  goToEditor: "Go to editor",
  mandatoryField: "Mandatory field.",
  modelDeletedSuccess: "Model deleted successfully",
  modelName: "Model name",
  modelStoreErrorText:
    "There was an error while creating the model:<br><code>{msg}</code>",
  modelStoreErrorTitle: "Model creation canceled",
  modelStoredText:
    'Model "{name}" was created successfully and is ready to use.',
  modelStoredTitle: "Model created",
  modelType: "Model type",
  modelUpdated: "Model was update successfully",
  next: "Next step",
  noType:
    "It seems that no model type was saved during creation of the model. Please select the correct model type in the following field and confirm the selection so the correct configuration fields can be shown. The model type is not editable after saving.",
  placeholderName: "Field name",
  placeholderNoType: "Select model type...",
  placeholderSelect: "Select...",
  previewAndConfiguration: "Preview & configuration",
  preview: "Preview (Line 1-{rows})",
  previewLines: "Number of lines for preview",
  previous: "Previous step",
  primaryKey: "Primary key",
  primaryXmlHelp:
    "Selection of primary key by dot notation or click on a node in the preview.",
  reanalyzeFile: "Reanalyze file",
  resetsTableConfig: "Resets table configuration",
  saveModel: "Save model",
  selectByNodeClick: "Select by click on node.",
  selectedConfiguration: "Selected configuration",
  selector: "Target XML tag",
  selectorHelp: "Selection of target by dot notation.",
  selectPrimaryKey: "Please select primary key.",
  showPreview: "Show preview",
  showPreviewHelp:
    "When using large files it's recommended to disable the preview in terms of high performance.",
  stepAnalyze: "Analyze file",
  stepBasicConfig: "Basic configuration",
  stepSave: "Store model",
  stepTableConfig: "Table configuration",
  tableName: "Table name",
  tablePreview: "Table preview (Line 1-{rows})",
  textInput: "Text input",
  typeInvalid: "Invalid {type}",
  maxDepth: "Max depth",
  maxDepthHelp: "This is the maximum of array level to analyze.",
  iterationMaxDepth: "Iteration max depth",
  type: "Type",
  xml: "XML",
  csv: "CSV",
  json: "JSON"
};
