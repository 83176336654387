import { ref, set } from "vue";

export const TOGGLE_COLLAPSE_PROCESS_TABLE_ENTRY =
  "toggleCollapseProcessTableEntry";

export const ADD_COLLAPSE_PROCESS_TABLE_ENTRY = "addCollapseProcessTableEntry";

export const REMOVE_COLLAPSE_PROCESS_TABLE_ENTRY =
  "removeCollapseProcessTableEntry";

const state = ref({
  processTableEntriesConfig: {}
});

const getters = {
  processTableEntriesConfig: state => {
    return state.processTableEntriesConfig;
  }
};

const mutations = {
  [TOGGLE_COLLAPSE_PROCESS_TABLE_ENTRY](state, payload) {
    if (state.processTableEntriesConfig[payload] === undefined) {
      set(state.processTableEntriesConfig, payload, { collapsed: true });
    } else {
      state.processTableEntriesConfig[payload].collapsed =
        !state.processTableEntriesConfig[payload].collapsed;
    }
  },
  [ADD_COLLAPSE_PROCESS_TABLE_ENTRY](state, payload) {
    if (state.processTableEntriesConfig[payload] === undefined) {
      set(state.processTableEntriesConfig, payload, { collapsed: false });
    }
  },
  [REMOVE_COLLAPSE_PROCESS_TABLE_ENTRY](state, payload) {
    if (state.processTableEntriesConfig[payload] !== undefined) {
      delete state.processTableEntriesConfig[payload];
    }
  }
};

const actions = {
  [TOGGLE_COLLAPSE_PROCESS_TABLE_ENTRY](context, payload) {
    context.commit(TOGGLE_COLLAPSE_PROCESS_TABLE_ENTRY, payload);
  },
  [ADD_COLLAPSE_PROCESS_TABLE_ENTRY](context, payload) {
    context.commit(ADD_COLLAPSE_PROCESS_TABLE_ENTRY, payload);
  },
  [REMOVE_COLLAPSE_PROCESS_TABLE_ENTRY](context, payload) {
    context.commit(REMOVE_COLLAPSE_PROCESS_TABLE_ENTRY, payload);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
