export const links = {
  migration: {
    video: "https://www.youtube.com/watch?v=OZZU4x1YoOc",
    handbook:
      "https://help.xentral.com/hc/en-us/articles/15467552891676-Migration-einer-klassischen-Shop-Anbindung-in-eine-Xentral-Connect-Schnittstelle",
    faq: "https://help.xentral.com/hc/en-us/articles/16510627451804-Connect-FAQs"
  },
  workflows: {
    product: {
      video: "https://www.youtube.com/watch?v=kkERBt43DyU"
    },
    category: {
      video: "https://www.youtube.com/watch?v=HmybmymPe1c"
    },
    stock: {
      video: "https://www.youtube.com/watch?v=1KGVPl9LUNI"
    },
    prices: {
      video: "https://www.youtube.com/watch?v=F02E2DyiQAs"
    },
    salesOrder: {
      video: "https://www.youtube.com/watch?v=qbA2AZ7RYYw"
    },
    tracking: {
      video: "https://www.youtube.com/watch?v=q4L0-441Wyc"
    }
  },
  journal: {
    journal: {
      video: "https://www.youtube.com/watch?v=lzba4jSedlM"
    }
  },
  general: {
    productSelection: {
      video: "https://www.youtube.com/watch?v=ZkcDRC4gFSQ"
    },
    migration: {
      video: "https://www.youtube.com/watch?v=tC2KRHftv-w"
    }
  },
  shopify: {
    workflows: {
      product: {
        handbook:
          "https://help.xentral.com/hc/en-us/articles/13235060220444-Shopify-Connector-Workflows-konfigurieren#UUID-55e1cf94-dd5f-eb68-4337-cabda5e7ee49_UUID-3c09e1cf-a065-4a39-eb0d-7952d5102a14"
      },
      category: {
        handbook:
          "https://help.xentral.com/hc/en-us/articles/13235060220444-Shopify-Connector-Workflows-konfigurieren#UUID-55e1cf94-dd5f-eb68-4337-cabda5e7ee49_UUID-3359f179-4a81-65da-ff2e-7662fa3e6fd8"
      },
      stock: {
        handbook:
          "https://help.xentral.com/hc/en-us/articles/13235060220444-Shopify-Connector-Workflows-konfigurieren#UUID-55e1cf94-dd5f-eb68-4337-cabda5e7ee49_UUID-c9a9ded3-5150-9c59-b811-ffe3749f660a"
      },
      prices: {
        handbook:
          "https://help.xentral.com/hc/en-us/articles/13235060220444-Shopify-Connector-Workflows-konfigurieren#UUID-55e1cf94-dd5f-eb68-4337-cabda5e7ee49_UUID-b097c0b8-1427-6493-2255-adbb4179b598"
      },
      salesOrder: {
        handbook:
          "https://help.xentral.com/hc/en-us/articles/13235060220444-Shopify-Connector-Workflows-konfigurieren#UUID-55e1cf94-dd5f-eb68-4337-cabda5e7ee49_UUID-a462635b-d913-f072-969b-9a1337dc398e"
      },
      tracking: {
        handbook:
          "https://help.xentral.com/hc/en-us/articles/13235060220444-Shopify-Connector-Workflows-konfigurieren#UUID-55e1cf94-dd5f-eb68-4337-cabda5e7ee49_UUID-25e4f670-0abd-cd1c-e554-c27f9b6d6f6d"
      },
      settings: {
        handbook:
          "https://help.xentral.com/hc/en-us/articles/13234991774620-Shopify-Connector-Einstellungen-anpassen"
      }
    },
    wizard: {
      handbook:
        "https://help.xentral.com/hc/en-us/articles/13235060103068-Shopify-Connector-Neue-Anbindung-anlegen"
    },
    journal: {
      journal: {
        handbook:
          "https://help.xentral.com/hc/en-us/articles/13921857699612-Shopify-Connector-Journal#UUID-d8ed9992-afac-447b-5f28-2e5f96fd98ac_UUID-20134aca-081b-c4ba-ba3d-32cb55588df2"
      },
      salesOrder: {
        handbook:
          "https://help.xentral.com/hc/en-us/articles/13921857699612-Shopify-Connector-Journal#UUID-d8ed9992-afac-447b-5f28-2e5f96fd98ac_UUID-dfbde649-c443-b752-32d8-00a561c088bb"
      },
      tracking: {
        handbook:
          "https://help.xentral.com/hc/en-us/articles/13921857699612-Shopify-Connector-Journal#UUID-d8ed9992-afac-447b-5f28-2e5f96fd98ac_UUID-df4515d4-73c7-2304-17ad-37c9013796f1"
      }
    },
    general: {
      productSelection: {
        handbook:
          "https://help.xentral.com/hc/en-us/articles/13235060173596-Shopify-Connector-Artikel-zuordnen"
      },
      migration: {
        handbook:
          "https://help.xentral.com/hc/en-us/articles/13235023216156-Shopify-Connector-Daten-%C3%BCbernehmen"
      }
    },
    listing: {
      handbook:
        "https://help.xentral.com/hc/en-us/articles/13235060016796-Shopify-Connector-%C3%9Cberblick"
    }
  },
  woocommerce: {
    workflows: {
      product: {
        handbook:
          "https://help.xentral.com/hc/en-us/articles/13234992002460-WooCommerce-Connector-Workflows-konfigurieren#UUID-2da1fca7-0bf6-4ed1-5caa-064075c28cd0_UUID-3c09e1cf-a065-4a39-eb0d-7952d5102a14"
      },
      category: {
        handbook:
          "https://help.xentral.com/hc/en-us/articles/13234992002460-WooCommerce-Connector-Workflows-konfigurieren#UUID-2da1fca7-0bf6-4ed1-5caa-064075c28cd0_UUID-3359f179-4a81-65da-ff2e-7662fa3e6fd8"
      },
      stock: {
        handbook:
          "https://help.xentral.com/hc/en-us/articles/13234992002460-WooCommerce-Connector-Workflows-konfigurieren#UUID-2da1fca7-0bf6-4ed1-5caa-064075c28cd0_UUID-c9a9ded3-5150-9c59-b811-ffe3749f660a"
      },
      prices: {
        handbook:
          "https://help.xentral.com/hc/en-us/articles/13234992002460-WooCommerce-Connector-Workflows-konfigurieren#UUID-2da1fca7-0bf6-4ed1-5caa-064075c28cd0_UUID-b097c0b8-1427-6493-2255-adbb4179b598"
      },
      salesOrder: {
        handbook:
          "https://help.xentral.com/hc/en-us/articles/13234992002460-WooCommerce-Connector-Workflows-konfigurieren#UUID-2da1fca7-0bf6-4ed1-5caa-064075c28cd0_UUID-a462635b-d913-f072-969b-9a1337dc398e"
      },
      tracking: {
        handbook:
          "https://help.xentral.com/hc/en-us/articles/13234992002460-WooCommerce-Connector-Workflows-konfigurieren#UUID-2da1fca7-0bf6-4ed1-5caa-064075c28cd0_UUID-25e4f670-0abd-cd1c-e554-c27f9b6d6f6d"
      },
      settings: {
        handbook:
          "https://help.xentral.com/hc/en-us/articles/13235023642012-WooCommerce-Connector-Einstellungen-anpassen"
      }
    },
    wizard: {
      handbook:
        "https://help.xentral.com/hc/en-us/articles/13235039683100-WooCommerce-Connector-Neue-Anbindung-anlegen"
    },
    general: {
      productSelection: {
        handbook:
          "https://help.xentral.com/hc/en-us/articles/13235023557532-WooCommerce-Connector-Artikel-zuordnen"
      },
      migration: {
        handbook:
          "https://help.xentral.com/hc/en-us/articles/13235023523228-WooCommerce-Connector-Daten-%C3%BCbernehmen"
      }
    },
    journal: {
      journal: {
        handbook:
          "https://help.xentral.com/hc/en-us/articles/13921840987036-WooCommerce-Connector-Journal#UUID-24f64971-99da-b968-5ba9-ff6a97dfabb5_UUID-20134aca-081b-c4ba-ba3d-32cb55588df2"
      },
      salesOrder: {
        handbook:
          "https://help.xentral.com/hc/en-us/articles/13921840987036-WooCommerce-Connector-Journal#UUID-24f64971-99da-b968-5ba9-ff6a97dfabb5_UUID-dfbde649-c443-b752-32d8-00a561c088bb"
      },
      tracking: {
        handbook:
          "https://help.xentral.com/hc/en-us/articles/13921840987036-WooCommerce-Connector-Journal#UUID-24f64971-99da-b968-5ba9-ff6a97dfabb5_UUID-df4515d4-73c7-2304-17ad-37c9013796f1"
      }
    },
    listing: {
      handbook:
        "https://help.xentral.com/hc/en-us/articles/13235023397404-WooCommerce-Connector-%C3%9Cberblick"
    }
  },
  shopware5: {
    workflows: {
      settings: {
        handbook:
          "https://help.xentral.com/hc/en-us/articles/14528044126364-Shopware5-Connector-Zugangsdaten-anpassen"
      }
    },
    wizard: {
      handbook:
        "https://help.xentral.com/hc/en-us/articles/14528063566236-Shopware5-Connector-Neue-Anbindung-anlegen"
    },
    general: {
      migration: {
        handbook:
          "https://help.xentral.com/hc/en-us/articles/14528034519324-Shopware5-Connector-Daten-importieren"
      }
    },
    listing: {
      handbook:
        "https://help.xentral.com/hc/en-us/articles/14528034364444-Shopware5-Connector-%C3%9Cberblick"
    }
  },
  shopware6: {
    workflows: {
      product: {
        handbook:
          "https://help.xentral.com/hc/en-us/articles/14528034840732-Shopware6-Connector-Workflows-konfigurieren#UUID-b0386169-a59f-1d8d-1375-62b9ba1825d5_UUID-3c09e1cf-a065-4a39-eb0d-7952d5102a14"
      },
      category: {
        handbook:
          "https://help.xentral.com/hc/en-us/articles/14528034840732-Shopware6-Connector-Workflows-konfigurieren#UUID-b0386169-a59f-1d8d-1375-62b9ba1825d5_UUID-3359f179-4a81-65da-ff2e-7662fa3e6fd8"
      },
      stock: {
        handbook:
          "https://help.xentral.com/hc/en-us/articles/14528034840732-Shopware6-Connector-Workflows-konfigurieren#UUID-b0386169-a59f-1d8d-1375-62b9ba1825d5_UUID-c9a9ded3-5150-9c59-b811-ffe3749f660a"
      },
      prices: {
        handbook:
          "https://help.xentral.com/hc/en-us/articles/14528034840732-Shopware6-Connector-Workflows-konfigurieren#UUID-b0386169-a59f-1d8d-1375-62b9ba1825d5_UUID-b097c0b8-1427-6493-2255-adbb4179b598"
      },
      salesOrder: {
        handbook:
          "https://help.xentral.com/hc/en-us/articles/14528034840732-Shopware6-Connector-Workflows-konfigurieren#UUID-b0386169-a59f-1d8d-1375-62b9ba1825d5_UUID-a462635b-d913-f072-969b-9a1337dc398e"
      },
      tracking: {
        handbook:
          "https://help.xentral.com/hc/en-us/articles/14528034840732-Shopware6-Connector-Workflows-konfigurieren#UUID-b0386169-a59f-1d8d-1375-62b9ba1825d5_UUID-25e4f670-0abd-cd1c-e554-c27f9b6d6f6d"
      },
      settings: {
        handbook:
          "https://help.xentral.com/hc/en-us/articles/14528064061596-Shopware6-Connector-Einstellungen-anpassen"
      }
    },
    wizard: {
      handbook:
        "https://help.xentral.com/hc/en-us/articles/14528034737436-Shopware6-Connector-Neue-Anbindung-anlegen"
    },
    general: {
      productSelection: {
        handbook:
          "https://help.xentral.com/hc/en-us/articles/14528034786588-Shopware6-Connector-Artikel-zuordnen"
      }
    },
    journal: {
      journal: {
        handbook:
          "https://help.xentral.com/hc/en-us/articles/14528034952476-Shopware6-Connector-Journal#UUID-0748de4e-37e4-85a3-21a3-6a2c51fcb42b_UUID-20134aca-081b-c4ba-ba3d-32cb55588df2"
      },
      salesOrder: {
        handbook:
          "https://help.xentral.com/hc/en-us/articles/14528034952476-Shopware6-Connector-Journal#UUID-0748de4e-37e4-85a3-21a3-6a2c51fcb42b_UUID-dfbde649-c443-b752-32d8-00a561c088bb"
      },
      tracking: {
        handbook:
          "https://help.xentral.com/hc/en-us/articles/14528034952476-Shopware6-Connector-Journal#UUID-0748de4e-37e4-85a3-21a3-6a2c51fcb42b_UUID-df4515d4-73c7-2304-17ad-37c9013796f1"
      }
    },
    listing: {
      handbook:
        "https://help.xentral.com/hc/en-us/articles/14528018649244-Shopware6-Connector-%C3%9Cberblick"
    }
  },
  otto: {
    workflows: {
      stock: {
        handbook:
          "https://help.xentral.com/hc/en-us/articles/16510599518876-OTTO-Connector-Workflows-konfigurieren#UUID-91f7b739-70c3-735c-3269-c7b31a73b85a_UUID-c9a9ded3-5150-9c59-b811-ffe3749f660a"
      },
      salesOrder: {
        handbook:
          "https://help.xentral.com/hc/en-us/articles/16510599518876-OTTO-Connector-Workflows-konfigurieren#UUID-91f7b739-70c3-735c-3269-c7b31a73b85a_UUID-6a922c8a-2817-c65a-5a55-69f718a83811"
      },
      tracking: {
        handbook:
          "https://help.xentral.com/hc/en-us/articles/16510599518876-OTTO-Connector-Workflows-konfigurieren#UUID-91f7b739-70c3-735c-3269-c7b31a73b85a_UUID-25e4f670-0abd-cd1c-e554-c27f9b6d6f6d"
      },
      settings: {
        handbook:
          "https://help.xentral.com/hc/en-us/articles/16510637433628-OTTO-Connector-Einstellungen-anpassen"
      }
    },
    wizard: {
      handbook:
        "https://help.xentral.com/hc/en-us/articles/16510622697116-OTTO-Connector-Neue-Anbindung-anlegen"
    },
    general: {
      productSelection: {
        handbook:
          "https://help.xentral.com/hc/en-us/articles/16510627621532-OTTO-Connector-Artikel-zuordnen"
      }
    },
    journal: {
      journal: {
        handbook:
          "https://help.xentral.com/hc/en-us/articles/16510622825756-OTTO-Connector-Journal#UUID-cc81f147-fc32-992b-080c-a5e78d6107b5_UUID-20134aca-081b-c4ba-ba3d-32cb55588df2"
      },
      salesOrder: {
        handbook:
          "https://help.xentral.com/hc/en-us/articles/16510622825756-OTTO-Connector-Journal#UUID-cc81f147-fc32-992b-080c-a5e78d6107b5_UUID-dfbde649-c443-b752-32d8-00a561c088bb"
      },
      tracking: {
        handbook:
          "https://help.xentral.com/hc/en-us/articles/16510622825756-OTTO-Connector-Journal#UUID-cc81f147-fc32-992b-080c-a5e78d6107b5_UUID-df4515d4-73c7-2304-17ad-37c9013796f1"
      }
    },
    listing: {
      handbook:
        "https://help.xentral.com/hc/en-us/articles/16510637266844-Otto-Connector-%C3%9Cberblick",
      video: "https://www.youtube.com/watch?v=oXH5eLDXPJU"
    }
  },
  mirakl: {
    workflows: {
      product: {
        video: "https://www.youtube.com/watch?v=9wylkXHz2_Y"
      }
    },
    listing: {
      video: "https://www.youtube.com/watch?v=6od5uK7Ei3M"
    }
  }
};
