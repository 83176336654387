export const textTemplates = {
  textTemplates: "Text Templates",
  type: "Type",
  textTemplateCreate: "Create text template",
  textTemplateDeleted: "Text template was deleted",
  deleteTextTemplateTitle: 'Delete text template "{title}"?',
  deleteTextTemplateText:
    "Do you want to delete the text template? This action cannot be undone.",
  textTemplateSaved: "Text template saved",
  textTemplateUpdated: "Text template updated",
  textTemplateLabel: "Label",
  textTemplateSave: "Save",
  variables: "Variables",
  variablesHelp:
    "Parameters can be used in text templates to show dynamic values.",
  addVariable: "Add parameter",
  detailsBasicConfiguration: "Basic configuration",
  name: "Name",
  dataStructure: "DataStructure",
  projects: "Projects",
  otherFileType: "Other file type",
  edit: "Edit",
  download: "Download",
  valid: "File valid",
  invalid: "File invalids",
  test: "Test",
  label: "Label",
  parameters: "Parameters",
  parametersAdd: "Add parameter",
  noParameters: "No parameters available.",
  selectDebugData: "Select debug data",
  output: "Output",
  types: {
    csv: "CSV",
    xml: "XML",
    html: "HTML",
    edifact: "EDIFACT",
    datanorm: "Datanorm",
    json: "JSON",
    other: "Other"
  },
  variablesTypes: {
    string: "Text",
    dataStructure: "DataStructure",
    dataStructureList: "DataStructure list"
  },
  helpers: {
    twig: {
      twig: "Twig",
      helperFunctions: "Helper functions",
      condition: "Condition",
      loop: "Loop",
      variable: "Variable",
      output: "Output",
      textOperators: "Text operators",
      replaceText: "Replace text",
      maskText: "Mask text",
      toLowerCase: "Text to lower case",
      toUpperCase: "Text to upper case",
      trim: "Trim",
      stripHtmlTags: "Strip HTML tags",
      encodeUrl: "Encode URL",
      formatDate: "Format date",
      numberOperators: "Number operators",
      numberFormat: "Format number",
      numberRound: "Round number",
      absolute: "Absolute value",
      listOperators: "List operators",
      firstElement: "First element",
      lastElement: "Last element",
      listLength: "Length of list",
      joinText: "Join text",
      mergeLists: "Merge lists",
      sliceList: "Slice list",
      otherOperators: "Other operators",
      countryName: "Country name",
      currencyName: "Currency name",
      currencySymbol: "Currency symbol",
      defaultValue: "Default value",
      jsonEncode: "Encode JSON"
    },
    xml: {
      xml: "XML",
      helperFunctions: "Helper functions",
      xmlHeader: "XML-Header",
      element: "Element"
    },
    edifact: {
      edifact: "EDIFACT"
    },
    html: {
      html: "HTML",
      helperFunctions: "Helper functions",
      htmlFramework: "HTML framework"
    }
  }
};
