import { computed } from "vue";
import JwtService from "@/core/services/jwt.service";
import router from "@/router";
import store from "@/core/services/store";
import { SalesChannelManagementAuth } from "@/middlewares/salesChannelManagement.auth";
import { AppAuth } from "@/middlewares/app.auth";
import { WatchDogAuth } from "@/middlewares/watchDog.auth";
import { ReportExportsAuth } from "@/middlewares/reportExports.auth";

/**
 * Function to extract subdomain from a given URL
 */
export const getSubdomain = url => {
  let domain = url;
  let licenseId = store.getters.licenseId;
  if (url.includes("://")) {
    domain = url.split("://")[1];
  }
  return licenseId ? licenseId : domain.split(".")[0];
};

/**
 * Function to generate dynamic URL based on subdomain
 */
const dynamicUrl = () => {
  let tenantSub = getSubdomain(window.location.origin);
  return (
    window.location.protocol +
    "//" +
    tenantSub +
    "." +
    process.env.VUE_APP_SUB_DOMAIN_PREFIX +
    "." +
    process.env.VUE_APP_CORE_DOMAIN
  );
};

// Computed property for resource URL
let resourceUrl = computed(() => {
  return process.env.VUE_APP_DYNAMIC_CORE_URL === "true"
    ? dynamicUrl()
    : process.env.VUE_APP_API_ADMIN;
});

// Function to reset the loadingQueue globally
function resetLoadingQueue() {
  store.commit("loadingQueue/reset");
}

// Function to add a slug to a URL
function addSlug(url, newUrl) {
  const parsedSlug = new URL(url).pathname;

  return newUrl + parsedSlug;
}

// Service for making HTTP requests
const ApiService = {
  init() {
    this.setHeader();

    // Set timeout
    this.timeout =
      (process.env.VUE_APP_AXIOS_TIMEOUT_LIMIT_IN_SECONDS || 20) * 1000;
  },

  // Set the default HTTP request headers
  setHeader() {
    this.headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };

    const projectId = store.getters.selectedProject?.id;
    if (projectId) {
      this.headers["x-project-id"] = projectId;
    }

    const presetVersion = store.getters.selectedPresetVersion;
    if (presetVersion && !presetVersion.is_dev_version) {
      this.headers["x-preset-version"] = presetVersion.id;
    }

    if (JwtService.getToken()) {
      this.headers["Authorization"] = `Bearer ${JwtService.getToken()}`;
    }
  },

  /**
   * Set the HTTP request header for a given key
   * @param key
   * @param value
   */
  setHeaderByKey(key, value) {
    this.headers[key] = value;
  },

  // Remove HTTP request header
  removeHeaderByKey(key = null) {
    delete this.headers[key];
  },

  // Remove all HTTP request header
  removeAllHeaders() {
    this.headers = {};
  },

  query(resource, params) {
    const url = new URL(resourceUrl.value);
    if (params) {
      Object.keys(params).forEach(key =>
        url.searchParams.append(key, params[key])
      );
    }

    return this.fetch(url.href, { method: "GET" });
  },

  // Function to send a GET request
  get(resource, slug = "", optionalHeaders = {}, disableAbort = false) {
    let url = addSlug(resource, resourceUrl.value);
    if (slug && typeof slug === "string" && !slug.startsWith("?")) {
      url += slug;
    }

    return this.fetch(
      url,
      {
        method: "GET",
        headers: {
          ...this.headers,
          ...optionalHeaders
        }
      },
      disableAbort
    );
  },

  // Function to send a POST request
  post(
    resource,
    params,
    optionalHeaders = {},
    plain = false,
    disableAbort = false
  ) {
    let url = addSlug(resource, resourceUrl.value);
    return this.fetch(
      url,
      {
        method: "POST",
        headers: {
          ...this.headers,
          ...optionalHeaders
        },
        body: plain ? params : JSON.stringify(params)
      },
      disableAbort
    );
  },

  // Function to send an UPDATE request
  update(resource, slug, params, optionalHeaders = {}, plain = true) {
    resource = addSlug(resource, resourceUrl.value);
    return this.fetch(`${resource}/${slug}`, {
      method: "PUT",
      headers: {
        ...this.headers,
        ...optionalHeaders
      },
      body: plain ? JSON.stringify(params) : params
    });
  },

  // Function to send a PUT request
  put(
    resource,
    params,
    optionalHeaders = {},
    plain = true,
    disableAbort = false
  ) {
    let url = addSlug(resource, resourceUrl.value);
    return this.fetch(
      url,
      {
        method: "PUT",
        headers: {
          ...this.headers,
          ...optionalHeaders
        },
        body: plain ? JSON.stringify(params) : params
      },
      disableAbort
    );
  },

  // Function to send a DELETE request
  delete(resource, optionalHeaders = {}) {
    resource = addSlug(resource, resourceUrl.value);
    return this.fetch(resource, {
      method: "DELETE",
      headers: {
        ...this.headers,
        ...optionalHeaders
      }
    });
  },

  // Store ongoing requests and associated AbortControllers
  ongoingRequests: new Map(),
  isAborted: false,

  async fetch(url, options, disableAbort = false) {
    try {
      // Check if there is an ongoing request with the same URL and options
      if (this.ongoingRequests.has(url) && !disableAbort) {
        this.isAborted = true;
        const existingController = this.ongoingRequests.get(url);
        existingController.abort();
      }

      const controller = new AbortController();
      this.ongoingRequests.set(url, controller);

      const timeout = setTimeout(() => {
        controller.abort();
        this.ongoingRequests.delete(url);
      }, this.timeout);

      // Sends requests
      const response = await fetch(url, {
        ...options,
        signal: controller.signal
      }).finally(() => {
        clearTimeout(timeout);
        // Prevent false headers by resetting them
        this.setHeader();
      });

      // Check response
      if (!response.ok) {
        // Check authentication
        const route = router.history.current.name;
        if (
          route.startsWith("salesChannelManagement") &&
          route !== "salesChannelManagementAuth"
        ) {
          SalesChannelManagementAuth(response);
        } else if (route.startsWith("watchDog") && route !== "watchDogAuth") {
          WatchDogAuth(response);
        } else if (route.startsWith("transferApp") && !route.includes("Auth")) {
          const routeName = route.includes("Ftp")
            ? "transferAppFtpAuth"
            : "transferAppReportExportsAuth";

          ReportExportsAuth(response, routeName);
        } else if (
          route !== "login" &&
          !route.startsWith("salesChannelManagement")
        ) {
          AppAuth(response);
        }
        // Reset loading queue to stop animations
        resetLoadingQueue();
        // Return Promise as readable text
        return Promise.reject(await response.text());
      }

      this.ongoingRequests.delete(url);

      // Turn response into blob to handle images
      if (options.headers?.responseType === "blob") {
        return await response.blob();
      }

      // Turn response into text
      const text = await response.text();
      // Return requests without response into object
      return text.length ? JSON.parse(text) : {};
    } catch (error) {
      if (this.isAborted) {
        return Promise.reject("abort");
      }
      this.ongoingRequests.delete(url);
      // Stop animations
      resetLoadingQueue();
      return Promise.reject(error);
    }
  }
};

export default ApiService;
