import Project from "@/components/Settings/Projects/project";
import { reactive, ref } from "vue";
import Assignments from "@/components/Assignments/assignments";
import Presets from "@/components/Settings/Presets/presets";
import Forms from "@/components/ExternalApps/classes/Forms";
import _ from "lodash";
import { fieldDefaults } from "@/components/Tools/FormHelper/Helper/constants";
import AuthValues from "@/components/Settings/Auth/authValues";
import { wooCommerceApp } from "@/components/ExternalApps/SalesChannelManagementApp/config/apps/wooCommerceApp";
import { bigCommerceApp } from "@/components/ExternalApps/SalesChannelManagementApp/config/apps/bigCommerceApp";
import { shopifyApp } from "@/components/ExternalApps/SalesChannelManagementApp/config/apps/shopifyApp";
import { shopware6App } from "@/components/ExternalApps/SalesChannelManagementApp/config/apps/shopware6App";
import { magentoApp } from "@/components/ExternalApps/SalesChannelManagementApp/config/apps/magentoApp";
import { amazonApp } from "@/components/ExternalApps/SalesChannelManagementApp/config/apps/amazonApp";
import { shopware5App } from "@/components/ExternalApps/SalesChannelManagementApp/config/apps/shopware5App";
import { ottoApp } from "@/components/ExternalApps/SalesChannelManagementApp/config/apps/ottoApp";
import { miraklApps } from "@/components/ExternalApps/SalesChannelManagementApp/config/apps/miraklApps";
import { oxidApp } from "@/components/ExternalApps/SalesChannelManagementApp/config/apps/oxidApp";
import { tradebyteApp } from "@/components/ExternalApps/SalesChannelManagementApp/config/apps/tradebyteApp";
import { galaxusApp } from "@/components/ExternalApps/SalesChannelManagementApp/config/apps/galaxusApp";

const DEFAULT_STORE_TYPE = "onlineShop";

const FORMS_DEFAULT = {
  shopify: {},
  woocommerce: {},
  shopware5: {},
  shopware6: {},
  otto: {},
  bigcommerce: {},
  amazon: {},
  mirakl: {},
  oxid: {},
  tradebyte: {},
  galaxus: {}
};

const state = ref({
  apps: [
    shopifyApp,
    shopware6App,
    amazonApp,
    wooCommerceApp,
    magentoApp,
    shopware5App,
    bigCommerceApp,
    ottoApp,
    ...miraklApps,
    oxidApp,
    tradebyteApp,
    galaxusApp
  ],
  credentialIds: {
    presetValue: undefined,
    authValue: undefined
  },
  stateMachineDatasets: {
    product: {
      list: process.env.VUE_APP_SCM_APP_PRODUCT_STATE_MACHINE_DATASET,
      detail: process.env.VUE_APP_SCM_APP_PRODUCT_STATE_MACHINE_DETAIL_DATASET
    },
    salesOrder: {
      list: process.env.VUE_APP_SCM_APP_SALES_ORDER_STATE_MACHINE_DATASET,
      detail:
        process.env.VUE_APP_SCM_APP_SALES_ORDER_STATE_MACHINE_DETAIL_DATASET
    },
    tracking: {
      list: process.env.VUE_APP_SCM_APP_TRACKING_STATE_MACHINE_DATASET,
      detail: process.env.VUE_APP_SCM_APP_TRACKING_STATE_MACHINE_DETAIL_DATASET
    },
    price: {
      list: process.env.VUE_APP_SCM_APP_PRICE_STATE_MACHINE_DATASET,
      detail: process.env.VUE_APP_SCM_APP_PRICE_STATE_MACHINE_DETAIL_DATASET
    },
    category: {
      list: process.env.VUE_APP_SCM_APP_CATEGORY_STATE_MACHINE_DATASET,
      detail: process.env.VUE_APP_SCM_APP_CATEGORY_STATE_MACHINE_DETAIL_DATASET
    },
    stock: {
      list: process.env.VUE_APP_SCM_APP_STOCK_STATE_MACHINE_DATASET,
      detail: process.env.VUE_APP_SCM_APP_STOCK_STATE_MACHINE_DETAIL_DATASET
    }
  },
  migrationDatasetId:
    process.env.VUE_APP_SCM_APP_MIGRATION_STATE_MACHINE_DATASET,
  selectedApp: null,
  forms: FORMS_DEFAULT,
  projects: [],
  xentralConfig: {},
  selectedIntegration: {},
  assignments: [],
  valuesToSave: {},
  deactivatedFeatures: [],
  syncBatchWorkflowId:
    process.env.VUE_APP_SCM_APP_REPORTING_SYNC_BATCH_WORKFLOW_ID,
  loadChannelsWorkflowId: process.env.VUE_APP_SCM_APP_LOAD_CHANNELS_WORKFLOW_ID,
  shopImporterLicenseIds: {
    shopware6: process.env.VUE_APP_SCM_APP_SHOPIMPORTER_LICENSEIDS_SHOPWARE6,
    shopify: process.env.VUE_APP_SCM_APP_SHOPIMPORTER_LICENSEIDS_SHOPIFY
  },
  shopImporterShopsGA: process.env.VUE_APP_SCM_APP_SHOPIMPORTER_SHOPS_GA,
  isLocal: process.env.VUE_APP_SCMA_ENVIRONMENT === "local",
  isDev: false,
  createSalesChannelWorkflowId:
    process.env.VUE_APP_SCM_APP_SALES_CHANNEL_CREATE_WORKFLOW_ID
});

const getters = {
  apps: state => {
    return state.apps;
  },
  app: state => name => {
    return state.apps.find(app => app.name === name);
  },
  credentialIds: state => {
    return state.credentialIds;
  },
  valuesToSave: state => {
    return state.valuesToSave;
  },
  appType: (state, getters) =>
    getters.selectedApp?.parentName ?? getters.selectedApp?.name,
  storeType: state => state?.storeType ?? DEFAULT_STORE_TYPE,
  form:
    (state, getters) =>
      (path = "") => {
        return path
          ? path.split(".").reduce(function (prev, curr) {
            return prev ? prev[curr] : null;
          }, state.forms[getters.appType])
          : state.forms[getters.appType];
      },
  forms: state => {
    return state.forms;
  },
  integrations: (state, getters) => {
    return state.projects.filter(
      project =>
        project?.preset_id === getters.presetId &&
        project.name.startsWith(getters.selectedApp.name + " ")
    );
  },
  integrationProject: (state, getters) => id => {
    return getters.integrations.find(project => project.id === id);
  },
  assignment: state => id => {
    return Object.values(state.assignments || []).find(
      assignment => assignment.id === id
    );
  },
  selectedApp: state => {
    return state.apps.find(app => app.id === state.selectedApp);
  },
  disableJournalSingleExport: (state, getters) => {
    return getters.selectedApp?.disableJournalSingleExport ?? false;
  },
  journalBatchExportBlacklist: (state, getters) => {
    return getters.selectedApp?.journalBatchExportBlacklist ?? [];
  },
  selectedIntegration: state => {
    return state.selectedIntegration;
  },
  xentralConfig: state => {
    return state.xentralConfig;
  },
  licenseId: state => {
    return state.xentralConfig?.data?.idToken
      ? state.xentralConfig?.data?.idToken["https://xentral.com/license-id"]
      : "";
  },
  environmentVariables: (state, getters) => {
    return (
      getters.selectedApp?.environmentVariables ||
      getters.parentApp?.environmentVariables ||
      {}
    );
  },
  oauthVariable: (state, getters) => key => {
    return getters.environmentVariables?.oauth?.[key] ?? "";
  },
  oAuthClientId: (state, getters) => {
    if (getters.appType === "otto") {
      const { VUE_APP_OTTO_APP_CLIENT_ID_PRIVATE, VUE_APP_OTTO_APP_CLIENT_ID } =
        process.env;

      const ottoPrivateAppCustomers =
        process.env.VUE_APP_OTTO_PRIVATE_APP_INSTANCES?.split(",") || [];

      const isOttoPrivateAppCustomer = ottoPrivateAppCustomers.includes(
        getters.licenseId
      );

      return isOttoPrivateAppCustomer
        ? VUE_APP_OTTO_APP_CLIENT_ID_PRIVATE
        : VUE_APP_OTTO_APP_CLIENT_ID;
    }
    return getters.environmentVariables?.oauth?.client_id;
  },
  parentApp: (state, getters) =>
    getters.apps?.find(app => app.name === getters.selectedApp?.parentName),
  appConfig: (state, getters) => getters.parentApp || getters.selectedApp,
  presetId: (state, getters) => {
    return getters.environmentVariables?.presetId ?? "";
  },
  xentralPresetProjectId: state => {
    return (
      state.projects.find(project => project?.name === "Xentral")?.id ?? ""
    );
  },
  appsWithoutMigration: state => {
    return state.apps.filter(el => el?.areaBlacklist?.includes("migration"));
  },
  migrationWorkflowId:
    (state, getters) =>
      (selectedArea, type = "all") => {
        return (
        getters.environmentVariables?.migrationWorkflows?.[selectedArea]?.[
          type
        ] ?? ""
        );
      },
  testWorkflowId: (state, getters) => selectedFeature => {
    return getters.environmentVariables?.testWorkflows?.[selectedFeature] ?? "";
  },
  testBatchWorkflowId: (state, getters) => selectedFeature => {
    return (
      getters.environmentVariables?.testBatchWorkflows?.[selectedFeature] ?? ""
    );
  },
  stateMachineDataSet:
    state =>
      (feature, type = "list") =>
      state.stateMachineDatasets?.[feature]?.[type],
  migrationDatasetId: state =>
    _.memoize(function () {
      return state.migrationDatasetId ?? "";
    }),
  areaBlacklist: (state, getters) => {
    return getters.appConfig?.areaBlacklist;
  },
  syncBatchWorkflowId: state => {
    return state.syncBatchWorkflowId;
  },
  deactivatedFeatures: state => {
    return state.deactivatedFeatures;
  },
  loadChannelsWorkflowId: state => {
    return state.loadChannelsWorkflowId;
  },
  shopImporterLicenseIds: (state, getters) => {
    return state.shopImporterLicenseIds?.[getters.appType] ?? "";
  },
  shopImporterShopsGA: state => {
    return state.shopImporterShopsGA;
  },
  initialStartWorkflowId: (state, getters) => selectedFeature => {
    return (
      getters.environmentVariables[selectedFeature]?.initialStartWorkflowId ??
      null
    );
  },
  initialCheckWorkflowId: (state, getters) => selectedFeature => {
    return (
      getters.environmentVariables[selectedFeature]?.initialCheckWorkflowId ??
      null
    );
  },
  createSalesChannelWorkflowId: state => state?.createSalesChannelWorkflowId,
  isDev: state => state?.isDev ?? false
};

const mutations = {
  loadSelectedIntegration(state, integration) {
    state.selectedIntegration = integration;
  },
  loadProjects(state, data) {
    state.projects = data;
  },
  loadForms(state, data) {
    state.forms[data.appName] = {
      ...(state.forms[data.appName] || {}),
      ...data.form
    };
  },
  setSelectedApp(state, payload) {
    state.selectedApp = this.getters.app(payload).id;
  },
  loadAssignments(state, data) {
    state.assignments = data;
  },
  setXentralConfig(state, payload) {
    state.xentralConfig = payload;
  },
  setValuesToSave(state, payload) {
    state.valuesToSave = payload;
  },
  setCredentialIds(state, payload) {
    state.credentialIds[payload.key] = payload.id;
  },
  updateOauthEnvironment(state, payload) {
    let app = state.apps.find(app => app.name === payload.appName);
    app.environmentVariables.oauth.client_id = payload.newClientId;
    app.environmentVariables.oauth.redirect_uri = payload.redirectUri;
  },
  resetValuesToSave(state) {
    state.valuesToSave = {};
  },
  addDeactivatedFeature(state, payload) {
    state.deactivatedFeatures.push(payload);
  },
  resetDeactivatedFeatures(state) {
    state.deactivatedFeatures = [];
  },
  resetSelectedIntegrationFeatures(state) {
    state.selectedIntegration.features = {};
  },
  resetCredentialIds(state) {
    state.credentialIds = Object.assign(
      {},
      {
        presetValue: undefined,
        authValue: undefined
      }
    );
  },
  resetScmaProjects(state) {
    state.projects = [];
  },
  resetSelectedApp(state) {
    state.selectedApp = null;
  },
  resetSelectedIntegration(state) {
    state.selectedIntegration = Object.assign({}, {});
  },
  resetAssignments(state) {
    state.assignments = [];
  },
  resetForms(state) {
    state.forms = Object.assign({}, FORMS_DEFAULT);
  },
  resetIsDev(state) {
    state.isDev = false;
  },
  setIsDev(state, payload) {
    state.isDev = payload;
  }
};

/**
 * Function to save only changed values
 * @param payload
 * @param getters
 * @returns {any}
 */
function setChangedValues(payload, getters) {
  const { feature, name, value, area, features } = payload;
  const data = getters.valuesToSave;
  const integration = getters.selectedIntegration;

  let metadata, dataToSave;

  if (feature === "migration") {
    metadata = data[feature] ?? integration[feature];
    dataToSave = { [feature]: metadata };
  } else if (
    (Object.keys(features).length > 0 && features.includes(feature)) ||
    (feature === "productSelection" && area === "selection") ||
    (feature === "productSelection" && area === "selectionV2")
  ) {
    dataToSave = setChangedAreaValues(feature, area, data, integration);
  } else {
    if (area) {
      dataToSave = setChangedAreaValues(
        feature,
        area,
        data,
        integration,
        name,
        value
      );
    } else {
      metadata = integration[feature];
      metadata.value[name] = value;

      dataToSave = { [feature]: metadata };
    }
  }

  Object.keys(dataToSave).forEach(featureToSave => {
    data[featureToSave] = {
      ...(data[featureToSave] || {}),
      ...dataToSave[featureToSave]
    };
  });

  return data;
}

function setChangedAreaValues(
  feature,
  area,
  data,
  integration,
  name = undefined,
  value = undefined
) {
  const areaKey = Object.keys(data[feature] || {}).includes(area)
    ? data[feature][area]
    : integration[feature][area];

  if (value !== undefined && name && areaKey?.value?.[name]) {
    areaKey.value[name] = value;
  }

  return { [feature]: { [area]: areaKey } };
}

async function setIntegrationData(
  integration,
  presetValues,
  authValues,
  getters
) {
  integration.features = presetValues.value.find(
    preset => preset.name === "features"
  );

  if (integration.features) {
    for (const featureKey of Object.keys(integration.features?.value || {})) {
      let areas = getters.form("app." + featureKey);

      if (!areas) {
        continue;
      }

      const areasKeys = Object.keys(areas);
      const featureValue = integration.features.value[featureKey];

      areasKeys.forEach(area => {
        const presetName = featureKey + _.upperFirst(area);
        const currentValue = presetValues.value.find(
          preset => preset.name === presetName
        );

        if (featureValue || (!featureValue && currentValue)) {
          if (!integration[featureKey]) integration[featureKey] = {};

          integration[featureKey][area] =
            currentValue ||
            reactive({
              name: presetName,
              label: presetName,
              project_id: integration.id,
              type: "json",
              // We need to get the default values of every field/field type
              value: getDefaultValuesForArea(areas[area])
            });
        }
      });
    }
  }

  integration.baseData = presetValues.value.find(
    preset => preset.name === "baseData"
  );
  integration.sftpData = presetValues.value.find(
    preset => preset.name === "sftpData"
  );
  integration.tbOneChannelSelection = presetValues.value.find(
    preset => preset.name === "tbOneChannelSelection"
  );
  integration.credentials =
    authValues.value?.find(auth => auth.name === "credentials") ??
    presetValues.value.find(preset => preset.name === "credentials");

  const settingAreas = getters.form("settings");
  integration.settings = {};

  for (const settingKey of Object.keys(settingAreas || {})) {
    const presetName = "settings" + _.upperFirst(settingKey);

    integration.settings[settingKey] =
      presetValues.value.find(preset => preset.name === presetName) ||
      reactive({
        name: presetName,
        label: presetName,
        project_id: integration.id,
        type: "json",
        // We need to get the default values of every field/field type
        value: getDefaultValuesForArea(settingAreas[settingKey])
      });
  }

  const productSelectionAreas = getters.form("productSelection");

  if (Object.keys(productSelectionAreas || {}).length) {
    // Preset: productSelectionSelection
    integration.productSelection = {};
    const presetName = "productSelectionSelection";

    integration.productSelection["selection"] =
      presetValues.value.find(preset => preset.name === presetName) ||
      reactive({
        name: presetName,
        label: presetName,
        project_id: integration.id,
        type: "json",
        // We need to get the default values of every field/field type
        value: getDefaultValuesForArea(productSelectionAreas["selection"])
      });

    integration.productsSelected = presetValues?.value?.find(
      preset => preset.name === "productsSelected"
    );

    // Preset: productSelectionSelectionV2
    const presetNameV2 = "productSelectionSelectionV2";
    integration.productSelection["selectionV2"] =
      presetValues.value.find(preset => preset.name === presetNameV2) ||
      reactive({
        name: presetNameV2,
        label: presetNameV2,
        project_id: integration.id,
        type: "json",
        value: getDefaultValuesForArea(productSelectionAreas["selection"])
      });
  }

  integration.migration = presetValues.value.find(
    preset => preset.name === "migration"
  );

  return integration;
}

function getDefaultValuesForArea(area) {
  let values = {};

  for (const field of area) {
    values[field.name] = field.default ?? fieldDefaults[field.type];
  }

  return values;
}

const actions = {
  async loadSelectedIntegration(context, integration) {
    this.dispatch("loadingQueue/addEventToLoadingQueue", {
      key: "salesChannelManagementAppLoadSelectedIntegration"
    });

    const filter = [
      {
        key: "project_id",
        op: "equals",
        value: integration.id
      }
    ];

    const params = {
      noPagination: true
    };
    const presetValues = ref();
    const authValues = ref();

    const getPresetValues = Presets.getAll(params, filter).then(response => {
      presetValues.value = response.data;
    });

    const getAuthValues = AuthValues.getAll(params, filter).then(response => {
      authValues.value = response.data;
    });

    const getAssignments = Assignments.getAll(params, filter).then(response => {
      context.commit("loadAssignments", response.data);
    });

    await Promise.all([getPresetValues, getAuthValues, getAssignments]);

    const credentialPreset = presetValues.value.find(
      preset => preset.name === "credentials"
    );
    const authValuePreset = authValues.value.find(
      auth => auth.name === "credentials"
    );

    context.state.credentialIds.presetValue = credentialPreset?.id;
    context.state.credentialIds.authValue = authValuePreset?.id;

    // Temporary solution while we wait for the new load/save logic
    setIntegrationData(
      integration,
      presetValues,
      authValues,
      this.getters
    ).then(response => {
      context.commit("loadSelectedIntegration", response);

      this.dispatch("loadingQueue/removeEventFromLoadingQueue", {
        key: "salesChannelManagementAppLoadSelectedIntegration"
      });
    });
  },

  async loadProjects(context) {
    this.dispatch("loadingQueue/addEventToLoadingQueue", {
      key: "salesChannelManagementAppGetProjects"
    });
    const params = {
      noPagination: true
    };

    await Project.getAll(params)
      .then(response => {
        context.commit("loadProjects", response.data);
      })
      .finally(() =>
        this.dispatch("loadingQueue/removeEventFromLoadingQueue", {
          key: "salesChannelManagementAppGetProjects"
        })
      );
  },

  async loadForms(context, payload) {
    this.dispatch("loadingQueue/addEventToLoadingQueue", {
      key: "salesChannelManagementAppGetForms"
    });

    await Forms.getScmaForm(context.getters.appType, payload)
      .then(response => {
        context.commit("loadForms", {
          form: ref(response.data).value,
          appName: context.getters.appType
        });
      })
      .finally(() =>
        this.dispatch("loadingQueue/removeEventFromLoadingQueue", {
          key: "salesChannelManagementAppGetForms"
        })
      );
  },

  loadAssignments(context) {
    context.commit("loadAssignments");
  },

  setSelectedApp(context, payload) {
    context.commit("setSelectedApp", payload);
  },

  setXentralConfig(context, payload) {
    context.commit("setXentralConfig", payload);
  },

  setValuesToSave({ commit, getters }, payload) {
    payload = setChangedValues(payload, getters);
    commit("setValuesToSave", payload);
  },

  setCredentialIds({ commit }, payload) {
    commit("setCredentialIds", payload);
  },

  updateOauthEnvironment(context, payload) {
    context.commit("updateOauthEnvironment", payload);
  },

  resetValuesToSave(context) {
    context.commit("resetValuesToSave");
  },

  addDeactivatedFeature(context, payload) {
    context.commit("addDeactivatedFeature", payload);
  },

  resetDeactivatedFeatures(context, payload) {
    context.commit("resetDeactivatedFeatures", payload);
  },

  resetSelectedIntegrationFeatures(context, payload) {
    context.commit("resetSelectedIntegrationFeatures", payload);
  },

  resetSalesChannelManagementAppStore(context) {
    context.commit("resetValuesToSave");
    context.commit("resetDeactivatedFeatures");
    context.commit("resetCredentialIds");
    context.commit("resetScmaProjects");
    context.commit("resetSelectedApp");
    context.commit("resetSelectedIntegration");
    context.commit("resetAssignments");
    context.commit("resetForms");
  },

  resetIsDev(context) {
    context.commit("resetIsDev");
  },

  setIsDev(context, payload) {
    context.commit("setIsDev", payload);
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
