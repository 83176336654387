export const tradebyteApp = {
  id: 11,
  name: "tradebyte",
  label: "Tradebyte",
  logo: process.env.BASE_URL + "media/shopLogos/tradebyte.png",
  additional_params: {
    requestHeaders: {
      "Content-Type": "application/json"
    },
    endpoint: "/{{dealerNumber}}/messages/",
    authType: "basic"
  },
  isMarketplace: true,
  disableJournalSingleExport: true,
  journalBatchExportBlacklist: ["prices"],
  environmentVariables: {
    presetId: process.env?.VUE_APP_SCM_APP_TRADEBYTE_PRESET_ID,
    testWorkflows: {
      product: process.env?.VUE_APP_SCM_APP_TEST_PRODUCT_WORKFLOW_ID,
      salesOrder: process.env?.VUE_APP_SCM_APP_TRADEBYTE_TEST_ORDER_WORKFLOW_ID,
      stock: process.env?.VUE_APP_SCM_APP_TEST_STOCK_WORKFLOW_ID,
      prices: process.env?.VUE_APP_SCM_APP_TEST_PRICES_WORKFLOW_ID,
      tracking: process.env?.VUE_APP_SCM_APP_TEST_TRACKING_WORKFLOW_ID,
      category: process.env?.VUE_APP_SCM_APP_TEST_CATEGORY_WORKFLOW_ID
    },
    testBatchWorkflows: {
      salesOrder:
        process.env?.VUE_APP_SCM_APP_TRADEBYTE_TEST_BATCH_ORDER_WORKFLOW_ID,
      tracking: process.env?.VUE_APP_SCM_APP_TEST_BATCH_TRACKING_WORKFLOW_ID
    },
    migrationWorkflows: {
      export: {
        all: process.env
          ?.VUE_APP_SCM_APP_TRADEBYTE_MIGRATION_EXPORT_WORKFLOW_ID,
        single:
          process.env
            ?.VUE_APP_SCM_APP_TRADEBYTE_MIGRATION_SINGLE_EXPORT_WORKFLOW_ID
      },
      import: {
        all: process.env?.VUE_APP_SCM_APP_MIGRATION_IMPORT_WORKFLOW_ID,
        single: process.env?.VUE_APP_SCM_APP_MIGRATION_SINGLE_IMPORT_WORKFLOW_ID
      }
    },
    xentralWorkflowId:
      process.env.VUE_APP_SCM_APP_TRADEBYTE_REPORTING_XENTRAL_WORKFLOW_ID,
    CFLWorkflowId:
      process.env.VUE_APP_SCM_APP_TRADEBYTE_REPORTING_CFL_WORKFLOW_ID,
    CFLDataSetId:
      process.env.VUE_APP_SCM_APP_TRADEBYTE_REPORTING_CFL_DATASET_ID,
    syncBatchWorkflowId:
      process.env.VUE_APP_SCM_APP_TRADEBYTE_REPORTING_SYNC_BATCH_WORKFLOW_ID,
    shopImporterWorkflowId:
      process.env.VUE_APP_SCM_APP_TRADEBYTE_MIGRATION_SHOPIMPORTER_WORKFLOW_ID
  }
};
