import { processStatus } from "@/i18n/de-de/components/processStatus";
import { reporting } from "@/i18n/de-de/components/salesChannelManagementApp/reporting";

export const tableHelper = {
  active: "Aktiv",
  addFilter: "Filter hinzufügen",
  applyFilter: "Filter anwenden",
  content_identifier: "Content Identifier",
  created_at: "Erstelldatum",
  equals: "ist",
  filter: "Filter",
  filterReset: "Filter zurücksetzen",
  finished_at: "Enddatum",
  greaterThan: "größer als",
  greaterThanOrEqual: "größer als/gleich",
  id: "ID",
  in: "enthält",
  lesserThan: "kleiner als",
  lesserThanOrEqual: "kleiner als/gleich",
  name: "Name",
  notEquals: "ist nicht",
  notIn: "enthält nicht",
  process: processStatus,
  recordsPerPage: "Ergebnisse pro Seite",
  removeFilter: "Filter entfernen",
  search: "Suche",
  searchTerm: "Suchbegriff",
  started_at: "Startdatum",
  status: "Status",
  type: "Typ",
  updated_at: "Aktualisierungsdatum",
  standardDs: "Ist Standard",
  deleteSelectedRows: "Ausgewählte Einträge löschen",
  confirmDeletion: "Wollen Sie die ausgewählten Einträge wirklich löschen?",
  state: "Letzter Status",
  hasError: "Nicht abgeschlossener Import/Export",
  states: reporting.states,
  export: {
    title: "CSV exportieren",
    selectColumns:
      "Bitte wählen Sie die Spalten aus, die Sie exportieren möchten.",
    columnName: "Spaltenname",
    selectedColumns: "Ausgewählte Spalten",
    noColumnsSelected: "Keine Spalten ausgewählt",
    export: "Exportieren",
    onExport:
      "Export gestartet. Dies kann einen Moment dauern. Sie finden die exportierte Datei im File Explorer (/exports).",
    onExportSuccess:
      "Export erfolgreich. Sie finden die exportierte Datei in Ihrem Download-Ordner.",
    selectFilters: "Bitte wählen Sie Filter für die Daten aus."
  },
  rows: {
    allSelected:
      "Alle {count} Datensätze ausgewählt, die dieser Suchanfrage entsprechen.",
    pageSelected: "{count} Datensätze auf dieser Seite ausgewählt.",
    selectAll: "Alle Datensätze auswählen, die dieser Suchanfrage entsprechen."
  },
  withSoftDeleted: "Nicht mehr im Filter enthaltene Artikel anzeigen",
  productError: "Fehler beim Produktimport",
  stockError: "Fehler beim Bestandsimport",
  priceError: "Fehler beim Preisimport",
  productLastImported: "Zeitpunkt letzter Produktimport",
  stockLastImported: "Zeitpunkt letzter Bestandsimport",
  priceLastImported: "Zeitpunkt letzter Preisimport"
};
