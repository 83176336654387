export const mailTemplates = {
  mailTemplates: "E-Mail Vorlagen",
  name: "Name",
  className: "Klassenname",
  deleteConfirm: 'Vorlage "{template}" löschen ?',
  deleteConfirmText:
    'Möchten Sie die Vorlage "{template}" wirklich löschen? Sie können dies nach dem Löschen nicht wiederherstellen.',
  deleteSucessText: "Vorlage {template} wurde gelöscht",
  variables: "Variablen",
  editTemplate: "Template bearbeiten",
  preview: "Vorschau",
  updated: "Template aktualisiert",
  backToOverview: "Zurück zur Übersicht"
};
