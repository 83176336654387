export const watchDog = {
  active: "Aktiv",
  authentication: "Authentifizierung",
  configuration: "Konfiguration",
  configurations: {
    noImportOrders: "Nicht importierte Aufträge",
    uncompletedOrders: "Unerledigte Aufträge"
  },
  defaultNotification:
    "Keine Vorschau verfügbar, weitere Informationen finden Sie in der Prozessiteration",
  errorPercentage: {
    hint: "Beispiel: 10% der im angegebenen Zeitraum eingegangenen Aufträge wurden nicht zu Xentral importiert: Fehler",
    info: "Prozentsatz der nicht in Xentral importierten Aufträge, die einen Fehler verursachen",
    label: "Prozentualer Anteil für die Erzeugung eines Fehlers"
  },
  form: {
    active: "Aktiv"
  },
  interval: {
    label: "Zu prüfender Rhythmus",
    options: {
      everyHour: "Jede Stunde",
      oncePerDay: "Einmal am Tag",
      twicePerDay: "Zweimal am Tag"
    }
  },
  loadProjects: "Projekte aktualisieren",
  noAuthAvailable: "Keine Authentifizierungsdaten verfügbar.",
  noNotifications: "Keine Benachrichtigungen verfügbar.",
  noXentralProjectId:
    "Oops, etwas ist schief gelaufen! Bitte wende dich an den zuständigen Support.",
  shopLinked: "Aktualisierung erfolgreich",
  shopLinkedError: "Aktualisierung fehlgeschlagen",
  titles: {
    salesOrders: "Kundenaufträge"
  },
  watchdog: "Watchdog-App",
  warningPercentage: {
    hint: "Beispiel: 5% der im angegebenen Zeitraum eingegangenen Aufträge wurden nicht zu Xentral importiert: Warnung",
    info: "Prozentsatz der nicht in Xentral importierten Aufträge, die eine Warnung auslösen",
    label: "Prozentualer Anteil für die Erzeugung einer Warnung"
  },
  amazon: {
    sellerId: "Seller ID",
    awsAccessKeyId: "AWS Access Key ID",
    awsSecretKey: "AWS Secret Key",
    sellingPartnerApiRefreshToken: "Selling partner API refresh token",
    sellingPartnerApiClientId: "Selling partner API client ID",
    sellingPartnerApiClientSecret: "Selling partner API client secret",
    sellingPartnerApiAuthUrl: "Selling partner API authentication URL",
    sellingPartnerApiEndpointUrl: "Selling partner API endpoint URL"
  },
  shopware: {
    apiKey: "API-Schlüssel",
    apiUser: "API-Nutzer",
    apiUrl: "API-Url"
  },
  shopware6: {
    shopwareUrl: "Url",
    shopwareUserName: "Nutzername",
    shopwarePassword: "Passwort"
  },
  shopify: {
    shopifyUrl: "Url",
    shopifyAPIKey: "API-Schlüssel",
    shopifyPassword: "Passwort",
    shopifyToken: "Token"
  },
  woocommerce: {
    apiKey: "API-Schlüssel",
    apiUser: "API-Nutzer",
    apiUrl: "API-Url"
  }
};
