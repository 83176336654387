export const dataSets = {
  addAggregation: "Add Transformers",
  addTransformer: "+ add Transformers",
  addAndCondition: "+ and condition",
  addConditionGroup: "+ and",
  addField: "+ add field",
  addFilter: "Add filter",
  addJoin: "Add join",
  addOrCondition: "+ or condition",
  alias: "Alias",
  clearAggregationHelp: "Empty Transformer",
  clearConditionHelp: "Empty condition",
  configure: "Configure DataSet",
  configuration: "Configuration",
  configValue: "Configuration value",
  condition: "Condition",
  create: "Create",
  createDataSet: "Create DataSet",
  customLimit: "Limit",
  dataSets: "Data Sets",
  dataStructureAlias: "DataStructure-Alias",
  deleteText:
    'Are you sure you want to delete the dataset "{name}"? This process is irreversible.',
  deleteStageText: 'Are you sure you want to delete the stage "{name}"?',
  deleteTitle: "Delete DataSet",
  deletedSuccess: "DataSet was successfully deleted",
  deletedError:
    "Data set could not be deleted because it is used in other resources",
  description: "Description",
  editDataSet: "Edit DataSet",
  dataSetEditor: "DataSet Editor",
  editor: "Editor",
  export: "Export DataSet",
  fieldData: "Data",
  fieldPlaceholder: "Search field",
  fields: "Fields",
  filter: "Filter",
  foreignField: "Foreign Field",
  importDataSet: "Import DataSet",
  dataSetImportSuccess: "DataSet was successfully imported.",
  invalidDataStructure: "Please select a DataStructure",
  invalidField: "Please add at least one field.",
  limit: "Limit",
  localField: "Local Field",
  name: "Name",
  noDataAvailable: "No data available",
  noDataStructureSelected: "No DataStructure selected",
  noFieldsSelected: "At least one field must be added",
  nullText: "[NULL]",
  on: "On",
  openEditor: "Open editor",
  preview: "Preview",
  removeAggregationHelp: "Remove Transformer",
  removeConditionHelp: "Remove condition",
  removeFieldHelp: "Remove field",
  save: "Save",
  savedSuccess: "DataSet was saved successfully.",
  schema: "Schema",
  selectAggregation: "Transformer",
  selectAllFields: "Select all source fields (1st level)",
  selectDataStructure: "Select DataStructure",
  selectField: "Select field",
  selectOperator: "Select operator",
  selectValue: "Enter value",
  testStage: "Test stage",
  testStageForPreview: "Test stage for preview data",
  label: "Label",
  parameter: "Parameter",
  parameters: "Parameters",
  config: "Config",
  parameterCreate: "Create parameter",
  parameterCreated: "Parameter created",
  parameterUpdated: "Parameter updated",
  addParameter: "Add parameter",
  deleteParameter: 'Are you sure you want to delete the "{name}" parameter?',
  active: "Active",
  selectStage: "Select stage for configuration",
  enableSubStages: "Activate Sub-Stages",
  disableSubStages: "Deactivate Sub-Stages",
  disableSubStagesText:
    "If you deactivate the sub-stages for this stage, all configured sub-stages will be irretrievably deleted.",
  addStage: "Add stage",
  addSubStage: "Add Sub-Stage",
  configurationHasErrors: "Configuration error",
  warningLargeData:
    "Warning! With large amounts of data, a limit stage should be installed for the performance!",
  value: "Value",
  type: "Type",
  actions: "Actions",
  parameterDeleteTitle: "Delete parameter",
  parameterDeleteText:
    "Do you really want to delete the parameter? This process is irreversible.",
  projects: "Projects",
  transformers: {
    abs: "Absolute value",
    add: "Add",
    subtract: "Subtract",
    divide: "Divide",
    multiply: "Multiply",
    log: "Logarithm",
    log10: "Logarithm (Base 10)",
    exp: "Exponential",
    sqrt: "Square root",
    round: "Round",
    substr: "Substring",
    concat: "Concat text",
    toLower: "Lowercase",
    toUpper: "Uppercase",
    split: "Split text",
    dayOfMonth: "Day of the month",
    isoDayOfWeek: "Day of the week",
    isoWeek: "Calendar week",
    dayOfYear: "Day of year",
    month: "Month",
    year: "Year",
    hour: "Hour",
    minute: "Minutes",
    second: "Seconds",
    millisecond: "Milliseconds",
    dateDiff: "Difference date",
    dateSubtract: "Subtract date",
    dateAdd: "Add date",
    type: "Type",
    toString: "To string",
    toBool: "To bool",
    toDate: "To date",
    toInt: "To integer",
    toDouble: "To double",
    toDecimal: "To decimal",
    toLong: "To long",
    dateToString: "Date to string"
  },
  transformerGroups: {
    cast: "Cast",
    date: "Date",
    datetime: "Datetime",
    math: "Math",
    string: "String"
  },
  aggregations: {
    avg: "Average",
    bottom: "Bottom element in group",
    bottomN: "Bottom n elements in group",
    count: "Count",
    first: "Value of first element in group",
    firstN: "Values of first n elements in group",
    last: "Value of last element in group",
    lastN: "Values of last n elements in group",
    max: "Highest value",
    maxN: "Highest n values",
    min: "Lowest value",
    push: "Combine fields in collection",
    sum: "Sum",
    top: "Top element in group",
    topN: "Top n elements in group"
  },
  unwind: {
    fieldHelp: "The collection field to be resolved during the unwind process.",
    includeArrayIndexHelp:
      "Name eines neuen Feldes, in dem der Index des Collection-Elements gespeichert werden kann.",
    preserveNullAndEmptyArraysHelp:
      "If active, empty entries are preserved in the collection. Otherwise, empty entries are removed from the result set."
  },
  parametersModal: {
    name: "Name",
    type: "Type",
    value: "Value",
    typeText: "Text",
    typeNumber: "Number",
    typeJson: "JSON",
    typeCondition: "Condition",
    duplicateName: "The name is already taken"
  },
  accumulatorGroups: {
    math: "Arithmetic operations",
    selectOneFromGroup: "Select elements from group",
    summarizeFields: "Summarize fields"
  }
};
