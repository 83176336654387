export const dataStructures = {
  add: "Hinzufügen",
  addField: "Feld hinzufügen",
  backToOverview: "Zurück zur Übersicht",
  baseData: "Basisinformationen",
  cancelCreate: "Anlage abbrechen",
  checkBaseData: "Stammdaten überprüfen",
  checkConfiguration: "Tabellenkonfiguration überprüfen",
  checkStructure: "Strukturprüfung aktivieren",
  checkStructureTooltip:
    "Aktivieren Sie diese Option, wenn die Dateistruktur beim Import mit der Datenstruktur verglichen werden soll. ",
  className: "Klassenname",
  classNameHint:
    "Keine Leerzeichen, Verwendung von Pascal Case (z.B. TestKlasse123)",
  configuration: "Felder konfigurieren",
  configure: "Datenstruktur konfigurieren",
  contentIdentifier: "Content Identifier",
  create: "Datenstruktur anlegen",
  dataFields: "Datenfelder",
  dataStructureBasis: "Datenstruktur Grundlage",
  dataStructureBasisExisting: "Vorhandene Datenstruktur",
  dataStructureBasisFile: "Datei (XML, CSV, JSON)",
  dataStructureBasisNew: "Neue Datenstruktur",
  dataStructureBasisSelect: "Grundlage wählen",
  dataStructureBasisSelectOriginal: "Vorlage auswählen",
  dataStructureBasisTemplate: "Aus Vorlage",
  dataStructureEdit: "Datenstruktur bearbeiten",
  dataStructureImportSuccess: "Datenstruktur wurde erfolgreich importiert",
  dataStructures: "Datenstrukturen",
  dataVersioning: "Inhaltlich versionieren",
  dataVersioningTooltip:
    "Sollen die Datensätze, die in dieser Datenstruktur gespeichert werden, automatisch versioniert werden?",
  default: "Standardwert",
  deleteText:
    'Möchten Sie die Datenstruktur "{name}" wirklich löschen? Dieser Vorgang ist unwiderruflich.',
  deleteEntryText:
    'Möchten Sie die den Eintrag "{name}" wirklich aus der Datenstruktur löschen? Dieser Vorgang ist unwiderruflich.',
  deleteTitle: "Datenstruktur löschen",
  deleteEntryTitle: "Eintrag aus Datenstruktur löschen",
  deletedSuccess: "Datenstruktur wurde erfolgreich gelöscht",
  deletedEntrySuccess: "Eintrag aus Datenstruktur wurde erfolgreich gelöscht",
  delimiter: "Trennzeichen",
  description: "Beschreibung",
  edit: "Datenstruktur bearbeiten",
  export: "Datenstruktur exportieren",
  emptyText: "Es sind noch keine Felder angelegt.",
  fieldName: "Feld Name",
  fields: "Felder",
  fieldsConfiguration: "Feldkonfiguration",
  fieldsRequired: "Bitte legen Sie mindestens ein Feld an.",
  globalTemplate: "globale Vorlage",
  glue: "Trennzeichen",
  importDataStructure: "Datenstuktur importeren",
  index: "Index",
  invalidInvalidFields: "Invalid fields",
  invalidNoFields: "Keine Felder",
  invalidNoPrimary: "Kein Primärschlüssel gesetzt",
  isLocked: "Sperren",
  isLockedHint:
    "Achtung: Für diese Datenstruktur wurde die Sperre aktiviert. Es kann keine Bearbeitung vorgenommen werden, bis die Sperre vom Benutzer aufgehoben wurde.",
  isLockedTooltip: "Soll diese Datenstruktur nicht veränderbar sein?",
  label: "Bezeichnung",
  lock: "Datenstruktur gesperrt",
  name: "Technischer Name",
  max_entries: "max. Einträge",
  min_entries: "min. Einträge",
  maxValue: "max. Einträge",
  minValue: "min. Einträge",
  nullable: "Null",
  openEditor: "Editor öffnen",
  overview: "Übersicht",
  placeholderSelect: "Auswählen",
  primary: "Primärschlüssel",
  primaryInvalid: "Der angegebene Primärschlüssel ist nicht gültig.",
  projects: "Projekte",
  properties: "Eigenschaften",
  published: "Veröffentlicht",
  publishedTooltip:
    "Soll diese Datenstruktur für Partner und Mandanten sichtbar sein?",
  related_data_structure: "Datenstruktur",
  reloadColumnsText:
    "Soll die Tabllenkonfiguration neu geladen und die ausgewählte Datenstruktur als Vorlage implementiert werden? Dabei gehen alle vorherigen Konfigurationen verloren.",
  reloadColumnsTitle: "Tabellenkonfiguration neu laden?",
  required: "Pflichtfeld",
  saveDataStructure: "Datenstruktur speichern",
  setContentIdentifier: "Content Identifier",
  setPrimary: "Primärschlüssel",
  startWizard: "Wizard starten",
  stepBasics: "Basisinformationen",
  stepBasicsNotReady:
    "Bitte füllen Sie erst alle markierten Felder korrekt aus",
  stepConfig: "Konfiguration",
  stepConfiguration: "Datenstruktur konfigurieren",
  stepConfigurationNotReady: "Bitte füllen Sie erst alle Felder korrekt aus",
  stepSave: "Überprüfen & Hochladen",
  storeErrorText:
    "Die Datenstruktur konnte aus folgendem Grund nicht gespeichert werden:<br><br><code>{msg}</code>",
  storeErrorTitle: "Ups... Etwas ist schief gelaufen!",
  storeSuccessText:
    'Die Datenstruktur "{name}" wurde erfolgreich gespeichert. Sie werden nun zur Übersicht weitergeleitet.',
  storeSuccessTitle: "Datenstruktur erfolgreich hochgeladen!",
  structureFields: "Strukturfelder",
  structuralVersioning: "Strukturell versionieren",
  structuralVersioningTooltip: "",
  showInView: "Im DataExplorer als Spalte anzeigen",
  skipOnDirtyCheck: "Bei Delta-Prüfung ignorieren",
  tableName: "Tabellenname",
  tableNameHint:
    "Keine Leerzeichen, Verwendung von Snake Case (z.B. table_name_123)",
  title: "Datenstruktur",
  toolbox: "Toolbox",
  type: "Typ",
  unique: "Einzigartig",
  unsigned: "Unsigned",
  updateFieldsConfirm: "Überschreiben",
  updateFieldsText:
    "Wenn Sie die das Hochladen der Änderungen bestätigen, wird die akutelle Feldkonfiguration unwiderruflich überschrieben, was zu Störungen oder Ausfall von Abläufen im Live-Betrieb führen kann. Sind Sie sicher, dass Sie diese Änderungen hochladen wollen?",
  updateFieldsTitle: "Feldkonfiguration überschreiben?",
  values: "Auswahloptionen",
  value: "Wert",
  version: "Version",
  standardSQLQueries: "Standard-SQL-Abfragen",
  standardQuery: "Standard Query",
  queryDeleted: "Die Standard-SQL-Abfrage wurde gelöscht",
  addQuery: "Abfrage hinzufügen",
  emptyTextQuery: "Es sind noch keine SQL Abfragen angelegt.",
  sqlQuery: "SQL-Abfrage",
  onEmptyData: "Handhabung leerer Importdateien",
  onEmptyDataTooltip:
    "Was soll passieren, wenn versucht wird eine leere Datei in die Datenstruktur zu importieren?",
  clone: "Klonen",
  copy: "Kopieren",
  paste: "Einfügen",
  originalName: "Feldbezeichnung aus Datei",
  originalNameHelpText:
    "Dies ist die originale Bezeichnung des Feldes aus der Datei, mit der diese Datenstruktur erstellt wurde. Der hier eingetragene Wert ist für die Überprüfung der Datenstruktur beim Import von Daten relevant. ",
  collapse: "Einklappen",
  expand: "Ausklappen",
  show: "Datenstruktur anzeigen",
  dynamicFieldPreview: "Vorschau",
  relation_type: "Relationsart",
  relation_type_single: "Einfache Relation",
  relation_type_multiple: "Mehrfachrelation",
  errorHandling: {
    warning: "Warnung",
    error: "Error"
  },
  onInvalidData: "Behandlung von ungültigen Importdaten",
  regex: "Regulärer Ausdruck",
  validationNotice: "Anmerkung zur Validierung",
  validationNoticeHelp:
    "Dieses Feld wird benötigt, um eine aussagekräftige Fehlermeldung anzuzeigen, wenn der Wert nicht mit dem Regex-Muster übereinstimmt."
};
