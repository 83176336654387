import config from "@/core/config/config.json";
import { ref } from "vue";

const state = ref({
  icons: config.icons
});

const getters = {
  icons: state => {
    return state.icons;
  }
};

export default {
  namespaced: true,
  state,
  getters
};
