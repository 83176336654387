export const reporting = {
  process: "Process",
  reporting: "Reporting",
  lastIteration: "Last iteration",
  lastIterationStatus: "Status",
  iterationsCount: "Jobs last cycle",
  iterationsCountError: "Therefrom incorrect",
  generalInformation: "General information",
  seconds: "{value} seconds",
  lastCycleDuration: "Last cycle duration",
  lastCycleDurationWithChilds: "Last cycle duration with children",
  averageCycleDuration: "Average duration",
  averageCycleDurationWithChilds: "Average duration with children",
  averageCycleDurationWithChildsLast7Days:
    "Average duration with children (last 7 Days)",
  lastJobError: "Last job error",
  lastIterations: "Jobs",
  selectAll: "Select all",
  status: "Status",
  duration: "Duration",
  durationApproximately: "≈ 00:01",
  childJobs: "Child jobs",
  lastIterationCount: "Iterations count last cycle",
  lastIterationAverageDuration: "Average duration last iteration",
  statusCount: "Status count last cycle",
  jobs: "Jobs {process}",
  childJobsFrom: "Child jobs from {process}",
  cycle: "Cycle",
  copyToClipboard: "Copy to clipboard",
  noChildJobs: "No child jobs",
  selectionOther: "(+{count} others)",
  averageDays: "Days for calculation",
  noIterations: "No jobs available.",
  jobDetails: "Jobdetails",
  caseIdentifier: "Case identifier",
  contentIdentifier: "Content identifier",
  startedBy: "Started by",
  startedAt: "Started at",
  finishedAt: "Finished at",
  queue: "Queue",
  parameter: "Parameter",
  details: "Details",
  logs: "Logs",
  showDetails: "Show details",
  systemEmails: "Emails sent by the system",
  recipient: "Recipient",
  returnValues: "Return value",
  elementInfo: "Element information",
  toDesigner: "To designer",
  overview: "Overview",
  jobEvaluation: "Complete status evaluation",
  count: "Count",
  totalCycleDuration: "Total cycle duration",
  averageCycleDurationLast7Days: "Average duration over the last 7 days",
  estimatedEnd: "Estimated end",
  nextCycle: "Next cycle",
  total: "Total",
  start: "Start",
  stop: "Stop",
  completeTimeEvaluation: "Complete time evaluation",
  noChildJobsWereTriggered: "No child jobs were triggered",
  noSchedulerPlanned: "No scheduler planner",
  payloadTitle: "Payload",
  time: "Period",
  chooseTimeFrom: "Period from",
  chooseTimeTo: "Period to",
  openPayload: "Open payload",
  processTypeStopped: "Stopped",
  processTypeError: "Error",
  processTypePending: "Pending",
  processTypeWorking: "Working",
  processTypeAborted: "Aborted",
  processTypeWarning: "Warning"
};
