import Vue from "vue";
import _ from "lodash";
import { validate } from "@/components/Tools/FormHelper/validation";

export const SET_STAGES = "setStages";

export const SET_DATA_SET = "setDataSet";

export const ADD_DATA_SET_STAGE = "addDataSetStage";
export const REMOVE_DATA_SET_STAGE = "removeDataSetStage";
export const SELECT_STAGE = "selectStage";
export const UPDATE_STAGE = "updateStage";
export const UPDATE_SELECTED_STAGE = "updateSelectedStage";
export const UPDATE_ORDER_INDEX = "updateOrderIndex";

export const SET_DATA_STRUCTURES = "setDataStructures";
export const SET_SELECTED_DATA_STRUCTURE_ID = "setSelectedDataStructureId";

export const SET_TEST_STAGES = "setTestStages";
export const SET_DEBUG = "setDebug";
export const SET_PARAMETERS = "setParameters";

export const VALIDATE_STAGES = "validateStages";

export const INCREMENT_IS_BUSY = "incrementIsBusy";
export const DECREMENT_IS_BUSY = "decrementIsBusy";
export const CLEAR_ALL = "clearAll";

const getDefaultState = () => {
  return {
    isBusy: 0,
    stages: [],
    dataSet: {},
    selectedStageOrderIndex: null,
    selectedSubStageOrderIndex: null,
    stageIcons: {
      SourceStage: "fal fa-arrow-right-to-bracket",
      CountStage: "fal fa-hashtag",
      FilterStage: "fal fa-filter",
      GroupStage: "fal fa-user-group",
      LimitStage: "fal fa-arrow-right-long-to-line",
      LookupStage: "fal fa-object-intersect",
      OffsetStage: "fal fa-arrow-right-from-line",
      SelectStage: "fal fa-list-check",
      SimpleLookupStage: "fal fa-object-intersect",
      SortStage: "fal fa-arrow-up-arrow-down",
      SwitchStage: "fal fa-objects-column",
      TransformStage: "fal fa-gear",
      UnwindStage: "fal fa-list-tree"
    },
    dataStructures: [],
    selectedDataStructureId: null,
    debug: {
      data: [],
      fieldNames: [],
      pipeline: [],
      fieldTypes: [],
      meta: {}
    },
    testStages: []
  };
};

function validateStage(context, stage) {
  let errors = [],
    values = Object.assign({}, stage.config),
    baseStage = context.getters.stages[stage.stage_type];
  // Validate stage with FormHelper validation function
  errors.push(...validate(values, baseStage.config));
  // Return errors
  return errors;
}

export default {
  namespaced: true,
  state: getDefaultState,
  getters: {
    stages: state => {
      return state.stages;
    },
    isBusy: state => {
      return state.isBusy > 0;
    },
    dataSet: state => {
      return state.dataSet;
    },
    parameters: state => {
      return state.dataSet?.parameters ?? [];
    },
    selectedStage: state => {
      return state.selectedStageOrderIndex !== null
        ? state.dataSet.dataSetStages.find(
            stage => stage.order_index === state.selectedStageOrderIndex
          )
        : null;
    },
    selectedSubStage: (state, getters) => {
      return getters.selectedStage
        ? getters.selectedStage.subStages?.[state.selectedSubStageOrderIndex]
        : null;
    },
    selectedStageOrderIndex: state => {
      return state.selectedStageOrderIndex;
    },
    selectedSubStageOrderIndex: state => {
      return state.selectedSubStageOrderIndex;
    },
    stageIcons: state => {
      return state.stageIcons;
    },
    dataStructures: state => {
      return state.dataStructures;
    },
    selectedDataStructure: state => {
      return state.selectedDataStructureId !== null
        ? state.dataStructures.find(
            ds => ds.id === state.selectedDataStructureId
          )
        : null;
    },
    debug: state => {
      return state.debug;
    },
    testStages: state => {
      return state.testStages;
    }
  },
  actions: {
    [SET_STAGES](context, payload) {
      context.commit(SET_STAGES, payload);
    },
    [INCREMENT_IS_BUSY](context) {
      context.commit(INCREMENT_IS_BUSY);
    },
    [DECREMENT_IS_BUSY](context) {
      context.commit(DECREMENT_IS_BUSY);
    },
    [SET_DATA_SET](context, payload) {
      context.commit(SET_DATA_SET, payload);
    },
    [ADD_DATA_SET_STAGE](context, payload) {
      context.commit(ADD_DATA_SET_STAGE, payload);
    },
    [REMOVE_DATA_SET_STAGE](context, payload) {
      context.commit(REMOVE_DATA_SET_STAGE, payload);
      context.commit(UPDATE_ORDER_INDEX);
    },
    [UPDATE_ORDER_INDEX](context, payload) {
      context.commit(UPDATE_ORDER_INDEX, payload);
    },
    [SELECT_STAGE](context, payload) {
      context.commit(SELECT_STAGE, payload);
    },
    [UPDATE_STAGE](context, payload) {
      context.commit(UPDATE_STAGE, payload);
    },
    [UPDATE_SELECTED_STAGE](context, payload) {
      context.commit(UPDATE_SELECTED_STAGE, payload);
    },
    [CLEAR_ALL](context) {
      context.commit(CLEAR_ALL);
    },
    [SET_DATA_STRUCTURES](context, payload) {
      context.commit(SET_DATA_STRUCTURES, payload);
    },
    [SET_SELECTED_DATA_STRUCTURE_ID](context, payload) {
      context.commit(SET_SELECTED_DATA_STRUCTURE_ID, payload);
    },
    [SET_DEBUG](context, payload) {
      context.commit(SET_DEBUG, payload);
    },
    [SET_TEST_STAGES](context, payload) {
      context.commit(SET_TEST_STAGES, payload);
    },
    [SET_PARAMETERS](context, payload) {
      context.commit(SET_PARAMETERS, payload);
    },
    [VALIDATE_STAGES](context) {
      // Set errors to empty array
      let errors = [],
        // Clone data set
        dataSet = _.cloneDeep(context.getters.dataSet);
      // Loop through data set stages
      dataSet.dataSetStages.forEach(stage => {
        if (stage.stage_type === "SourceStage") {
          return;
        }
        // Validate stage
        let stageErrors = validateStage(context, stage);
        // Add stage errors to error array
        errors.push(...stageErrors);
        // Set valid flag
        stage.valid = !stageErrors.length;
        // If stage has subStages
        if (stage.subStages?.length) {
          // Call this function for all subStages
          stage.subStages.forEach(subStage => {
            let subStageErrors = validateStage(context, subStage);
            // Set valid flag
            subStage.valid = !subStageErrors.length;
            errors.push(...subStageErrors);
          });
        }
      });
      // Update data set in store
      context.commit(SET_DATA_SET, dataSet);
      // Return errors
      return errors;
    }
  },
  mutations: {
    [SET_STAGES](state, payload) {
      state.stages = payload;
    },
    [INCREMENT_IS_BUSY](state) {
      state.isBusy++;
    },
    [DECREMENT_IS_BUSY](state) {
      state.isBusy--;
    },
    [SET_DATA_SET](state, payload) {
      state.dataSet = payload;
    },
    [ADD_DATA_SET_STAGE](state, payload) {
      let stages = [...state.dataSet.dataSetStages];
      stages.push(payload);
      Vue.set(state.dataSet, "dataSetStages", stages);
    },
    [REMOVE_DATA_SET_STAGE](state, payload) {
      let stages = [...state.dataSet.dataSetStages];
      let index = stages.map(s => s.order_index).indexOf(payload);
      stages.splice(index, 1);
      Vue.set(state.dataSet, "dataSetStages", stages);
    },
    [UPDATE_ORDER_INDEX](state, payload) {
      let stages = payload ?? [...state.dataSet.dataSetStages];
      let index = 0;
      stages.forEach(stage => {
        if (stage.stage_type === "SourceStage") {
          stage.order_index = -1;
        } else {
          stage.order_index = index;
          index++;
        }
      });
      Vue.set(state.dataSet, "dataSetStages", stages);
    },
    [SELECT_STAGE](state, payload) {
      state.selectedStageOrderIndex = payload.stageOrderIndex;
      state.selectedSubStageOrderIndex = payload.subStageOrderIndex;
    },
    [UPDATE_STAGE](state, payload) {
      let index = state.dataSet.dataSetStages
        .map(s => s.order_index)
        .indexOf(payload.orderIndex);
      Vue.set(state.dataSet.dataSetStages, index, payload.value);
    },
    [UPDATE_SELECTED_STAGE](state, payload) {
      let index = state.dataSet.dataSetStages
        .map(s => s.order_index)
        .indexOf(state.selectedStageOrderIndex);
      Vue.set(state.dataSet.dataSetStages, index, payload);
    },
    [CLEAR_ALL](state) {
      Object.assign(state, getDefaultState());
    },
    [SET_DATA_STRUCTURES](state, payload) {
      state.dataStructures = payload;
    },
    [SET_SELECTED_DATA_STRUCTURE_ID](state, payload) {
      state.selectedDataStructureId = payload;
    },
    [SET_DEBUG](state, payload) {
      state.debug = payload;
    },
    [SET_TEST_STAGES](state, payload) {
      state.testStages = payload;
    },
    [SET_PARAMETERS](state, payload) {
      state.dataSet.parameters = payload;
    }
  }
};
