export const internalTrigger = {
  input: {
    parameter: "Parameter",
    parameterValues: {
      key: "Name",
      value: "Value"
    }
  },
  configuration: {
    process: "Process",
    reloadProcesses: "Update processes",
    delayType: "Artificial Delay",
    delayTypeOptions: {
      delay: "Time",
      fixedTime: "Fixed time"
    },
    delay: "Delay",
    delayOptions: {
      0: "No delay",
      300: "5 Minutes",
      900: "15 Minutes",
      1800: "30 Minutes",
      3600: "1 Hour",
      7200: "2 Hours",
      14400: "4 Hours",
      28800: "4 Hours"
    },
    delayDate: "Fixed time"
  }
};
