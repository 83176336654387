export const users = {
  actions: "Actions",
  admin: "Admin",
  baseData: "Base data",
  cropProfilePicture: "Crop profile picture",
  dropFilesAnywhereToUpload: "Drop files anywhere to upload",
  dropFilesToUpload: "Drop files to upload",
  email: "E-Mail",
  enterEmail: "Please enter the user email address",
  enterFirstName: "Please enter the user first name",
  enterLastName: "Please enter the user last name",
  enterPassword: "Please enter the user password",
  enterPasswordConfirmation: "Please enter the same password again",
  enterSalutation: "Please enter the form of salute",
  enterTitle: "Please enter the title",
  firstName: "Firstname",
  imageUpload: "Image upload",
  language: "Language",
  lastName: "Lastname",
  myProfile: "My Profile",
  myProfileDesc: "Account settings and more",
  notAPicture: "Your choice is not a picture",
  password: "Password",
  passwordConfirmation: "Password confirmation",
  profile: "Profile",
  profilePicture: "Profile picture",
  removeProfilePicture: "Remove profile picture",
  required: "Required",
  reviewAndSubmit: "Review and submit",
  role: "Role",
  salutation: "Salutation",
  saveImageChanges: "Save image changes",
  selectLanguage: "Please select which language will be assigned to the user",
  selectRole: "Please select which role will be assigned to the user",
  sendWelcomeEmail: "Send welcome email",
  title: "Title",
  updateBaseDataAndProfilePicture: "Update base data and profile picture",
  updateProfile: "Update profile",
  uploadAndCropImage: "Upload and crop image",
  uploadProfilePicture: "Upload profile picture",
  user: "User",
  userCreate: "Create user",
  userCreated: "User created",
  userDeleted: "User deleted",
  userUpdated: "User updated",
  usersManagement: "User management",
  allUsers: "All users",
  accessData: "Access data",
  userDetails: "Edit user"
};
