import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/watchdog",
      redirect: "watchdog",
      component: () => import("@/components/ExternalApps/WatchDog/Index.vue"),
      children: [
        {
          path: "/watchdog",
          name: "watchDogProjects",
          component: () =>
            import("@/components/ExternalApps/WatchDog/views/Projects.vue")
        },
        {
          path: "/watchdog/:id/config",
          name: "watchDogProjectsForm",
          component: () =>
            import("@/components/ExternalApps/WatchDog/views/Configuration.vue")
        },
        {
          path: "/watchdog/:id/auth",
          name: "watchDogProjectsAuth",
          component: () =>
            import(
              "@/components/ExternalApps/WatchDog/views/Authentication.vue"
            )
        },
        {
          path: "auth",
          name: "watchDogAuth",
          component: () => import("@/components/ExternalApps/WatchDog/Auth.vue")
        }
      ]
    },
    {
      path: "/transferApp",
      component: () =>
        import("@/components/ExternalApps/TransferApp/Index.vue"),
      children: [
        {
          path: "/transferApp/ftp",
          name: "transferAppFtp",
          component: () =>
            import("@/components/ExternalApps/TransferApp/FTP/views/Form.vue")
        },
        {
          path: "/transferApp/ftp/auth",
          name: "transferAppFtpAuth",
          component: () =>
            import("@/components/ExternalApps/components/Auth.vue"),
          props: {
            destination: "transferAppFtp"
          }
        },
        {
          path: "/transferApp/reportExports",
          name: "transferAppReportExports",
          component: () =>
            import(
              "@/components/ExternalApps/TransferApp/ReportExports/views/BaseView.vue"
            )
        },
        {
          path: "/transferApp/reportExports/auth",
          name: "transferAppReportExportsAuth",
          component: () =>
            import("@/components/ExternalApps/components/Auth.vue"),
          props: {
            destination: "transferAppReportExports"
          }
        }
      ]
    },
    {
      path: "/salesChannelManagement",
      redirect: "/salesChannelManagementApps",
      component: () =>
        import("@/components/ExternalApps/SalesChannelManagementApp/Index.vue"),
      children: [
        {
          path: "apps",
          name: "salesChannelManagementApps",
          component: () =>
            import(
              "@/components/ExternalApps/SalesChannelManagementApp/Apps.vue"
            )
        },
        {
          path: "app/:name",
          name: "salesChannelManagementApp",
          component: () =>
            import(
              "@/components/ExternalApps/SalesChannelManagementApp/App.vue"
            )
        },
        {
          path: "app/:name/wizard",
          name: "salesChannelManagementAppWizard",
          component: () =>
            import(
              "@/components/ExternalApps/SalesChannelManagementApp/Wizard.vue"
            )
        },
        {
          path: "app/:name/journal",
          name: "salesChannelManagementAppJournal",
          component: () =>
            import(
              "@/components/ExternalApps/SalesChannelManagementApp/components/Journal/JournalView.vue"
            )
        },
        {
          path: "app/:name/:area",
          name: "salesChannelManagementAppDetail",
          component: () =>
            import(
              "@/components/ExternalApps/SalesChannelManagementApp/AppDetail.vue"
            )
        },
        {
          path: "login",
          name: "salesChannelManagementAppLogin",
          component: () =>
            import(
              "@/components/ExternalApps/SalesChannelManagementApp/Login.vue"
            )
        },
        {
          path: "auth",
          name: "salesChannelManagementAuth",
          component: () =>
            import(
              "@/components/ExternalApps/SalesChannelManagementApp/Auth.vue"
            )
        }
      ]
    },
    {
      path: "/platform",
      redirect: "platformLogin",
      component: () => import("@/components/Platform/Index.vue"),
      children: [
        {
          path: "/platform/login",
          name: "platformLogin",
          component: () => import("@/components/Platform/Login.vue")
        }
      ]
    },
    {
      path: "/",
      redirect: "/workflows",
      component: () => import("@/layout/Default.vue"),
      children: [
        {
          path: "workflows/:id/mode/planning/:workflow_element_hash?",
          name: "projectWorkflowsEditorModePlanning",
          component: () => import("@/components/Workflows/Designer/Index")
        },
        {
          path: "mappings/:id/mapper",
          name: "projectMappingsMappingMapper",
          component: () => import("@/components/Mappings/Index")
        },
        {
          path: "mappings/:id/debug",
          name: "projectMappingsMappingDebug",
          component: () => import("@/components/Mappings/Index")
        },
        {
          path: "data-structures/editor/:id?",
          name: "projectDataStructuresEditor",
          component: () => import("@/components/DataStructures/Index.vue")
        },
        {
          path: "text-templates/:id/editor",
          name: "projectTextTemplatesEditor",
          component: () => import("@/components/TextTemplates/Index")
        },
        {
          path: "workflows",
          name: "projectWorkflows",
          component: () => import("@/components/Workflows/Designer/Index")
        },
        {
          path: "workflows/create",
          name: "projectWorkflowsCreate",
          component: () => import("@/components/Workflows/Designer/Index")
        },
        {
          path: "workflows/:id",
          name: "projectWorkflowsEditor",
          component: () => import("@/components/Workflows/Designer/Index")
        },
        {
          path: "workflows/:id/basedata",
          name: "projectWorkflowsDetailBaseData",
          component: () => import("@/components/Workflows/Designer/Index")
        },
        {
          path: "workflows/:id/dependency",
          name: "projectWorkflowsDetailDependency",
          component: () => import("@/components/Workflows/Designer/Index")
        },
        {
          path: "workflows/:id/Queue",
          name: "projectWorkflowsDetailQueue",
          component: () => import("@/components/Workflows/Designer/Index")
        },
        {
          path: "workflows/:id/dangerzone",
          name: "projectWorkflowsDetailDangerZone",
          component: () => import("@/components/Workflows/Designer/Index")
        },
        {
          path: "workflows/:id/mode/:mode",
          name: "projectWorkflowsEditorMode",
          component: () => import("@/components/Workflows/Designer/Index")
        },
        {
          path: "workflows/reporting/:id",
          name: "projectWorkflowsReporting",
          component: () => import("@/components/Workflows/Designer/Index")
        },
        {
          path: "workflows/reporting/:id/details",
          name: "projectWorkflowsReportingDetails",
          component: () => import("@/components/Workflows/Designer/Index")
        },
        {
          path: "workflows/reporting/:id/jobs",
          name: "projectWorkflowsReportingDetailsJobs",
          component: () => import("@/components/Workflows/Designer/Index")
        },
        {
          path: "workflows/reporting/:id/childJobs",
          name: "projectWorkflowsReportingDetailsChildJobs",
          component: () => import("@/components/Workflows/Designer/Index")
        },
        {
          path: "workflows/reporting/:id/:jobId/details",
          name: "projectWorkflowsJobDetails",
          component: () => import("@/components/Workflows/Designer/Index")
        },
        {
          path: "mappings",
          name: "projectMappingsMappings",
          component: () => import("@/components/Mappings/Index")
        },
        {
          path: "mappings/create",
          name: "projectMappingsMappingsCreate",
          component: () => import("@/components/Mappings/Index")
        },
        {
          path: "mappings/:id/details",
          name: "projectMappingsMappingsDetails",
          component: () => import("@/components/Mappings/Index")
        },
        {
          path: "assignments",
          name: "projectMappingsAssignments",
          component: () => import("@/components/Assignments/Index")
        },
        {
          path: "assignments/create",
          name: "projectMappingsAssignmentsCreate",
          component: () => import("@/components/Assignments/Index")
        },
        {
          path: "assignments/:id/edit",
          name: "projectMappingsAssignmentsEditor",
          component: () => import("@/components/Assignments/Index")
        },
        {
          path: "data-structures",
          name: "projectDataStructures",
          component: () => import("@/components/DataStructures/Index.vue")
        },
        {
          path: "data-structures/create",
          name: "projectDataStructuresCreate",
          component: () => import("@/components/DataStructures/Index.vue")
        },
        {
          path: "data-structures/:id",
          name: "projectDataStructuresDetails",
          component: () => import("@/components/DataStructures/Index.vue")
        },
        {
          path: "data-structures/:id/base-data",
          name: "projectDataStructuresDetailsBaseData",
          component: () => import("@/components/DataStructures/Index.vue")
        },
        {
          path: "data-structures/:id/config",
          name: "projectDataStructuresDetailsConfiguration",
          component: () => import("@/components/DataStructures/Index.vue")
        },
        {
          path: "data-structures/:id/queries",
          name: "projectDataStructuresDetailsStandardSQLQueries",
          component: () => import("@/components/DataStructures/Index.vue")
        },
        {
          path: "data-explorer",
          name: "projectDataExplorer",
          component: () => import("@/components/DataExplorer/Index")
        },
        {
          path: "data-explorer/:id",
          name: "projectDataExplorerDetails",
          component: () => import("@/components/DataExplorer/Index")
        },
        {
          path: "text-templates",
          name: "projectTextTemplates",
          component: () => import("@/components/TextTemplates/Index")
        },
        {
          path: "text-templates/create",
          name: "projectTextTemplatesCreate",
          component: () => import("@/components/TextTemplates/Index")
        },
        {
          path: "text-templates/:id/details",
          name: "projectTextTemplatesDetails",
          component: () => import("@/components/TextTemplates/Index")
        },
        {
          path: "file-explorer",
          name: "projectFileExplorer",
          component: () => import("@/components/FileExplorer/Index")
        },
        {
          path: "data-sets",
          name: "projectDataSets",
          component: () => import("@/components/DataSets/Index")
        },
        {
          path: "data-sets/create",
          name: "projectDataSetsCreate",
          component: () => import("@/components/DataSets/Index")
        },
        {
          path: "data-sets/:id/edit",
          name: "projectDataSetsDetails",
          component: () => import("@/components/DataSets/Index")
        },
        {
          path: "data-sets/editor/:id?",
          name: "projectDataSetsEditor",
          component: () => import("@/components/DataSets/Index")
        },
        {
          path: "reporting",
          name: "projectReporting",
          component: () => import("@/components/Reporting/Index")
        },
        {
          path: "reporting/jobs",
          name: "projectReportingJobs",
          component: () => import("@/components/Reporting/Jobs/Table")
        },
        {
          path: "settings",
          redirect: "settings/projects",
          name: "projectSettings",
          component: () => import("@/components/Settings/Index")
        },
        {
          path: "settings/projects",
          name: "projectSettingsProjects",
          component: () => import("@/components/Settings/Index")
        },
        {
          path: "settings/projects/create",
          name: "projectSettingsProjectsCreate",
          component: () => import("@/components/Settings/Index")
        },
        {
          path: "settings/projects/:projectId",
          name: "projectSettingsProjectsDetails",
          component: () => import("@/components/Settings/Index")
        },
        {
          path: "settings/queues",
          name: "projectSettingsQueues",
          component: () => import("@/components/Settings/Index")
        },
        {
          path: "settings/queues/create",
          name: "projectSettingsQueuesCreate",
          component: () => import("@/components/Settings/Index")
        },
        {
          path: "settings/queues/:queueId",
          name: "projectSettingsQueuesEdit",
          component: () => import("@/components/Settings/Index")
        },
        {
          path: "settings/mails",
          name: "projectSettingsMails",
          component: () => import("@/components/Settings/Index")
        },
        /* Presets start */
        {
          path: "settings/presets",
          name: "projectSettingsPresets",
          component: () => import("@/components/Settings/Index")
        },
        {
          path: "settings/presets/create",
          name: "projectSettingsPresetsCreate",
          component: () => import("@/components/Settings/Index")
        },
        {
          path: "settings/presets/:presetId",
          name: "projectSettingsPresetsEdit",
          component: () => import("@/components/Settings/Index")
        },
        /* Presets end */
        /* Users Start */
        {
          path: "settings/users",
          name: "projectSettingsUsers",
          component: () => import("@/components/Settings/Index")
        },
        {
          path: "settings/users/:id/information",
          name: "projectSettingsUsersAccessInformation",
          component: () => import("@/components/Settings/Index")
        },
        {
          path: "settings/users/:id/access-data",
          name: "projectSettingsUsersAccessData",
          component: () => import("@/components/Settings/Users/Details")
        },
        {
          path: "settings/users/add",
          name: "projectSettingsUsersCreate",
          component: () => import("@/components/Settings/Index")
        },
        /* Users End */
        {
          path: "settings/email",
          name: "projectSettingsEmail",
          component: () => import("@/components/Settings/Index")
        },
        {
          path: "settings/email/:id",
          name: "projectSettingsEmailDetails",
          component: () => import("@/components/Settings/Index")
        },
        {
          path: "settings/loginSecurity",
          name: "projectSettingsLoginSecurity",
          component: () => import("@/components/Settings/Index")
        },
        {
          path: "settings/config",
          name: "projectSettingsConfig",
          component: () => import("@/components/Settings/Index")
        },
        {
          path: "settings/config/create",
          name: "projectSettingsConfigCreate",
          component: () => import("@/components/Settings/Index"),
          props: true
        },
        {
          path: "settings/config/:configId",
          name: "projectSettingsConfigDetails",
          component: () => import("@/components/Settings/Index")
        },
        {
          path: "settings/auth",
          name: "projectSettingsAuth",
          component: () => import("@/components/Settings/Index")
        },
        {
          path: "settings/auth/create",
          name: "projectSettingsAuthCreate",
          component: () => import("@/components/Settings/Index"),
          props: true
        },
        {
          path: "settings/auth/:authId",
          name: "projectSettingsAuthDetails",
          component: () => import("@/components/Settings/Index")
        },
        {
          path: "settings/workflowToken",
          name: "projectSettingsWorkflowToken",
          component: () => import("@/components/Settings/Index")
        },
        {
          path: "settings/workflowToken/create",
          name: "projectSettingsWorkflowTokenCreate",
          component: () => import("@/components/Settings/Index")
        },
        {
          path: "settings/workflowToken/:tokenId",
          name: "projectSettingsWorkflowTokenDetail",
          component: () => import("@/components/Settings/Index")
        },
        {
          path: "/form-helper",
          name: "formHelper",
          component: () => import("@/components/Tools/FormHelper/Index")
        },
        {
          path: "/form-helper-factory",
          name: "formHelperFactory",
          component: () => import("@/components/Tools/FormHelperFactory/Index")
        },
        {
          path: "/table-helper",
          name: "tableHelper",
          component: () => import("@/components/Tools/TableHelper/Index")
        }
      ]
    },
    {
      path: "/",
      component: () => import("@/components/General/Auth/Index"),
      children: [
        {
          name: "passwordReset",
          path: "/auth/password/reset",
          component: () => import("@/components/General/Auth/Index")
        },
        {
          name: "login",
          path: "/login",
          component: () => import("@/components/General/Auth/Index")
        }
      ]
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/layout/Error")
    }
  ]
});
