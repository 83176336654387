export const dataSets = {
  addAggregation: "Transformer hinzufügen",
  addTransformer: "+ Transformer hinzufügen",
  addAndCondition: "+ und Bedingung",
  addConditionGroup: "+ und",
  addField: "+ Feld hinzufügen",
  addFilter: "Filter hinzufügen",
  addJoin: "Join hinzufügen",
  addOrCondition: "+ oder Bedingung",
  alias: "Alias",
  clearAggregationHelp: "Transformer leeren",
  clearConditionHelp: "Bedingung leeren",
  configure: "Data-Set konfigurieren",
  configuration: "Konfiguration",
  configValue: "Konfigurationswert",
  condition: "Bedingung",
  create: "Anlegen",
  createDataSet: "Data-Set anlegen",
  customLimit: "Limit",
  dataSets: "Data-Sets",
  dataStructureAlias: "Datenstruktur-Alias",
  deleteText:
    'Möchten Sie das Data-Set "{name}" wirklich löschen? Dieser Vorgang ist unwiderruflich.',
  deleteStageText: 'Möchten Sie die Stage "{name}" wirklich löschen?',
  deleteTitle: "Data-Set löschen",
  deletedSuccess: "Data-Set wurde erfolgreich gelöscht",
  deletedError:
    "Data-Set konnte nicht gelöscht werden, da es in anderen Resourcen genutzt wird",
  description: "Beschreibung",
  editDataSet: "Data-Set bearbeiten",
  dataSetEditor: "Data-Set Editor",
  editor: "Editor",
  export: "Data-Set exportieren",
  fieldData: "Daten",
  fieldPlaceholder: "Feld suchen",
  fields: "Felder",
  filter: "Filter",
  foreignField: "Fremdfeld",
  importDataSet: "Data-Set importieren",
  dataSetImportSuccess: "DataSet wurde erfolgreich importiert.",
  invalidDataStructure: "Bitte wählen Sie eine Datenstruktur aus",
  invalidField: "Bitte fügen Sie mindestens ein Feld hinzu.",
  limit: "Limit",
  localField: "Local Field",
  name: "Name",
  noDataAvailable: "Keine Daten verfügbar",
  noDataStructureSelected: "Keine Datenstrukur ausgewählt",
  noFieldsSelected: "Es muss mindestens ein Feld hinzugefügt werden",
  nullText: "[NULL]",
  on: "On",
  openEditor: "Editor öffnen",
  preview: "Vorschau",
  removeAggregationHelp: "Transformer entfernen",
  removeConditionHelp: "Bedingung entfernen",
  removeFieldHelp: "Feld entfernen",
  save: "Speichern",
  savedSuccess: "Data-Set wurde erfolgreich gespeichert",
  schema: "Schema",
  selectAggregation: "Transformer",
  selectAllFields: "Alle Quellfelder auswählen (1. Ebene)",
  selectDataStructure: "Datenstruktur auswählen",
  selectField: "Feld auswählen",
  selectOperator: "Operator auswählen",
  selectValue: "Wert eingeben",
  testStage: "Stage testen",
  testStageForPreview: "Stage für Vorschaudaten testen",
  label: "Bezeichnung",
  parameter: "Parameter",
  parameters: "Parameter",
  config: "Config",
  parameterCreate: "Parameter erstellen",
  parameterCreated: "Parameter erstellt",
  parameterUpdated: "Parameter aktualisiert",
  addParameter: "Parameter hinzufügen",
  deleteParameter: 'Möchten Sie den Parameter "{name}" wirklich löschen?',
  active: "Aktiv",
  selectStage: "Stage zur Konfiguration auswählen",
  enableSubStages: "Sub-Stages aktivieren",
  disableSubStages: "Sub-Stages deaktivieren",
  disableSubStagesText:
    "Wenn Sie die Sub-Stages für diese Stage deaktivieren, werden alle konfigurierten Sub-Stages unwiderruflich gelöscht.",
  addStage: "Stage hinzufügen",
  addSubStage: "Sub-Stage hinzufügen",
  configurationHasErrors: "Fehler in der Konfiguration",
  warningLargeData:
    "Achtung! Bei großen Mengen an Daten sollte eine Limit Stage für die Performance eingebaut werden!",
  value: "Wert",
  type: "Typ",
  actions: "Aktionen",
  parameterDeleteTitle: "Parameter löschen",
  parameterDeleteText:
    "Möchten Sie den Parameter wirklich löschen? Dieser Vorgang ist unwiderruflich.",
  projects: "Projekte",
  transformers: {
    abs: "Betrag",
    add: "Addieren",
    subtract: "Subtrahieren",
    divide: "Dividieren",
    multiply: "Multiplizieren",
    log: "Logarithmieren",
    log10: "Logarithmieren (Basis 10)",
    exp: "Exponentiation",
    sqrt: "Quadratwurzel",
    round: "Runden",
    substr: "Teilzeichenkette",
    concat: "Text verknüpfen",
    toLower: "Kleinbuchstaben",
    toUpper: "Großbuchstaben",
    split: "Text teilen",
    dayOfMonth: "Tag im Monat",
    isoDayOfWeek: "Tag in der Woche",
    isoWeek: "Kalenderwoche",
    dayOfYear: "Tag im Jahr",
    month: "Monat",
    year: "Jahr",
    hour: "Stunde",
    minute: "Minute",
    second: "Sekunde",
    millisecond: "Millisekunde",
    dateDiff: "Differenz Datum",
    dateSubtract: "Subtrahieren Datum",
    dateAdd: "Addieren Datum",
    type: "Datentyp",
    toString: "zu String",
    toBool: "zu Bool",
    toDate: "zu Datum",
    toInt: "zu Integer",
    toDouble: "zu Double",
    toDecimal: "zu Dezimal",
    toLong: "zu Long",
    dateToString: "Datum zu String"
  },
  transformerGroups: {
    cast: "Typumwandlung",
    date: "Datum",
    datetime: "Zeitstempel",
    math: "Mathematisch",
    string: "Text"
  },
  aggregations: {
    avg: "Durchschnitt",
    bottom: "Unterstes Element in Gruppe",
    bottomN: "Untersten n Elemente in Gruppe",
    count: "Anzahl",
    first: "Wert von erstem Element in Gruppe",
    firstN: "Werte der ersten n Elemente in Gruppe",
    last: "Wert von letztem Element in Gruppe",
    lastN: "Werte der letzten n Elemente in Gruppe",
    max: "Höchster Wert",
    maxN: "Höchsten n Werte",
    min: "Niedrigster Wert",
    push: "Felder in Collection zusammenfassen",
    sum: "Summe",
    top: "Oberstes Element in Gruppe",
    topN: "Obersten n Elemente in Gruppe"
  },
  unwind: {
    fieldHelp:
      "Das Collection-Feld, das während des Unwind-Prozesses aufgelöst werden soll",
    includeArrayIndexHelp:
      "Name eines neuen Feldes, in dem der Index des Collection-Elements gespeichert werden kann",
    preserveNullAndEmptyArraysHelp:
      "Wenn aktiv, werden leere Einträge in der Collection erhalten. Andernfalls werden leere Einträge aus der Ergebnismenge entfernt."
  },
  parametersModal: {
    name: "Name",
    type: "Typ",
    value: "Wert",
    typeText: "Text",
    typeNumber: "Nummer",
    typeJson: "JSON",
    typeCondition: "Bedingung",
    duplicateName: "Der Name ist bereits vergeben"
  },
  accumulatorGroups: {
    math: "Rechenoperationen",
    selectOneFromGroup: "Einzelne Elemente aus Gruppe auswählen",
    summarizeFields: "Zusammenfassen von Feldern"
  }
};
