export const journal = {
  journal: "Journal",
  articleFound: "There were {stock} Articles found.",
  articleListExportInfo:
    "To add the articles to the list of articles to be exported, click on “Build article list”. It will probably take up to 5 minutes to create the article list",
  createArticleList: "Build article list",
  articleListBuilding: "The article list is currently being built.",
  articleImported: "Articles imported.",
  articleListBuildingInfo:
    "As soon as the article list has been completely built, you can view it in the journal.",
  imported: "Item list completely created.",
  statusSuccess: "Export successful",
  statusError: "Export failed. Click here for more information.",
  statusDefault: "Not exported yet.",
  errorDetail: "Import for item {id} failed.",
  onlySuccess: "Successfully exported",
  onlyError: "Export failed",
  onlyNotExported: "Not yet exported",
  orderNumberImportInfoText:
    "One or more order numbers (comma-separated) can be specified here.",
  orderNumberShopifyImportInfoText:
    "One or more order numbers (comma-separated) can be specified here.",
  orderIdTradebyteImportInfoText:
    "One or more four-digit order IDs from TB.One can be specified here.",
  orderIdTradebyteImportPlaceHolder: "1012, 1013, ...",
  orderNumberShopifyImportPlaceHolder: "#1012, #1013, ...",
  orderNumberImportPlaceHolder: "10025, 10001, ...",
  orderIdImportInfoText:
    "One or more order IDs (comma-separated) can be specified here.",
  orderIdImportPlaceHolder: "412, 594, ...",
  from: "Since",
  to: "To",
  typeNoVariants: "No variants",
  typeOnlyVariants: "Only variants",
  trackingNoTracking: "No tracking exported",
  trackingOnlyTracking: "Only tracking exported",
  states: {
    error: "Faulty",
    incomplete: "Import pending",
    imported: "Import successful"
  }
};
