import Vue from "vue";
import i18n from "@/core/plugins/vue-i18n";

export function generateHash(length = 20, prefix = "") {
  let chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  let result = "";
  for (let i = length - prefix.length; i > 0; --i)
    result += chars[Math.floor(Math.random() * chars.length)];
  return prefix + result;
}

export function isJson(item) {
  item = typeof item !== "string" ? JSON.stringify(item) : item;

  try {
    item = JSON.parse(item);
  } catch (e) {
    return false;
  }

  return typeof item === "object" && item !== null;
}

export function cloneDeep(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export function validateIPAddress(ipaddress) {
  return /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
    ipaddress
  );
}

export function copyToClipboard(variable, prefix = "", suffix = "") {
  navigator.clipboard.writeText(prefix + variable + suffix);
  Vue.prototype.$toast.fire({
    icon: "info",
    title: i18n.t("general.copied")
  });
}

export function millisecondsToTime(value) {
  // Pad to 2 or 3 digits, default is 2
  function pad(n, z) {
    z = z || 2;
    return ("00" + n).slice(-z);
  }

  let milliseconds = value % 1000;
  value = (value - milliseconds) / 1000;
  let seconds = value % 60;
  value = (value - seconds) / 60;
  let minutes = value % 60;
  let hours = (value - minutes) / 60;

  if (hours > 0) {
    return pad(hours) + ":" + pad(minutes) + ":" + pad(seconds);
  }
  return pad(minutes) + ":" + pad(seconds);
}

export function formatDate(
  date,
  options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit"
  }
) {
  if (!date) {
    return false;
  }
  const d = new Date(date);
  return new Intl.DateTimeFormat("de-DE", options).format(d);
}

export function calculateDuration(item) {
  if (!item.started_at || !item.finished_at) {
    return false;
  }

  let started = new Date(item.started_at);
  let finished = new Date(item.finished_at);

  let pausedForSeconds = item.paused_for_seconds ?? 0;

  // Calculate the total duration in milliseconds
  let duration = finished - started;

  // Subtract the pausedForSeconds (converted to milliseconds)
  return duration - pausedForSeconds * 1000;
}
