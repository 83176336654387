export const config = {
  name: "Technischer Name",
  variables: "Variablen",
  configCreate: "Konfiguration erstellen",
  copy: " - Kopie",
  nameEnter: "Namen eingeben",
  label: "Bezeichnung",
  type: "Typ",
  key: "Schlüssel",
  value: "Wert",
  configCreated: "Konfiguration erstellt",
  configUpdate: "Konfiguration bearbeiten",
  configUpdated: "Konfiguration aktualisiert",
  configDeleteMessage:
    'Möchten Sie die Konfiguration "{config}" wirklich löschen? Dieser Vorgang ist unwiderruflich.',
  configDeleteTitle: "Konfiguration löschen?",
  configDeleted: "Konfiguration erfolgreich gelöscht",
  configurations: "Konfigurationen",
  authentication: "Authentifizierung"
};
