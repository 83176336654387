export const orders = {
  aggregateLineItems: "Auftragspositionen pro Artikel aggregieren?",
  aggregateLineItemsHelp:
    "Von Otto werden mehrfach bestellte Artikel als einzelne Auftragspositionen mit Anzahl 1 zurückgemeldet. Hier kannst du auswählen, ob die Auftragspositionen beim Anlegen des Auftrags in Xentral pro Artikel aggregiert werden sollen.",
  applyDiscounts: "Gutschriften und Rabatte als Rechnungsposition",
  applyDiscountsHelp:
    "Sollen Gutschriften und Rabatte als eigene Rechnungsposition übernommen werden?",
  applyShippingCosts: "Versandkosten als Rechnungsposition",
  applyShippingCostsHelp:
    "Sollen Versandkosten als Rechnungsposition übernommen werden?",
  applySum: "Gesamtbetrag des Auftrags aus Verkaufskanal übernehmen?",
  applySumHelp:
    "Der im Verkaufskanal für den Auftrag berechnete Gesamtbetrag wird in Xentral übernommen. Andernfalls gilt der in Xentral berechnete Wert.",
  assignDefaultCustomer: "jeden Kunden auf Default-Kunden schreiben",
  assignExistingCustomer: "Kunde aus Auftrag auf bestehenden Kunden zuordnen",
  assignNewCustomer: "jeden Kunden neu anlegen",
  assignmentByProject:
    "Bestehende Kunden nur innerhalb des Projektes zuordnen?",
  assignmentByProjectHelp:
    'Wenn ein Kunde nicht innerhalb des Projektes gefunden wird, wird das oben ausgewählte Verfahren ("Wenn kein Kunde gefunden wird, dann") verwendet.',
  authorized: "garantiert",
  blockAutoShipping: "Autoversand bei Kommentar stoppen?",
  blockAutoShippingHelp:
    "Soll der Autoversand bei Aufträge, die einen Kommentar enthalten, deaktiviert werden?",
  bodyOutroduction: "in Freitext schreiben",
  comment: "in interne Bemerkung schreiben",
  correctState:
    "Bei Erreichen des richtigen Zahlungs-/ und/oder Verstandstatus",
  countryAssignment: "Länderzuordnung",
  countryAssignmentHelp:
    "Hier kannst du die jeweiligen Länder aus Xentral jeweils einem Land aus dem Verkaufskanal zuordnen. Dieses wird dann beim Import von Aufträgen zugewiesen.",
  createCustomer: "Kundenanlage",
  createCustomerGuestOrder: "Kundenanlage Gastauftrag",
  createCustomerGuestOrderHelp:
    "Wie soll ein Kunde aus einem Gastauftrag in Xentral angelegt werden? Hierbei geht es um Kunden, die kein Kundenkonto im Verkaufskanal haben.",
  createCustomerHelp:
    "Wie soll ein Kunde aus einem Auftrag in Xentral angelegt werden? Hierbei geht es um Kunden, die ein Kundenkonto im Verkaufskanal haben.",
  createNewCustomer: "Kunden neu anlegen",
  customerAssignment: "Kundenzuordnung",
  customerAssignmentHelp:
    "Anhand von welchen Merkmalen soll ein Kunde aus einem Auftrag zu einem Xentral Kunden zugeordnet werden?",
  customerNumber: "Kundennummer",
  defaultCustomer: "Default-Kunden auswählen",
  defaultCustomerCreate: "Default-Kunden auswählen",
  defaultCustomerGuestOrder: "Default-Kunden auswählen",
  defaultCustomerHelp:
    "Welcher Kunde soll als Default-Kunde für Verkaufskanal-Aufträge verwendet werden?",
  defaultCustomerNoCustomerFound: "Default-Kunden auswählen",
  defaultCustomerPos: "Default-Kunden (POS) auswählen",
  defaultCustomerPosHelp:
    "Welcher Kunde soll als Default-Kunde für POS Verkaufskanal-Aufträge verwendet werden?",
  defaultDiscountProduct: "Artikelnummer für Rabatte",
  defaultDiscountProductHelp:
    "Bitte wähle hier einen Rabatt-Artikel aus, auf den Gutschriften & Rabatte gebucht werden sollen. Dies betrifft nur Rabatte, die auf den gesamten Warenkorb gelten. Rabatte auf einzelne Artikel werden separat gespeichert. Rabatt-Artikel werden erstellt, indem in der Artikelanlage die Option “Artikel ist Rabatt” ausgewählt wird.",
  defaultPaymentMethod: "Standardzahlungsart",
  defaultPaymentMethodHelp:
    "Die Zahlungsart aus Xentral, die standardmäßig verwendet wird.",
  defaultProject: "Standardprojekt",
  defaultProjectHelp:
    "Das Projekt aus Xentral, welches standardmäßig verwendet wird.",
  defaultShippingMethod: "Standardversandart",
  defaultShippingMethodHelp:
    "Die Versandart aus Xentral, die standardmäßig verwendet wird.",
  defaultShippingProduct: "Artikelnummer für Versandkosten",
  defaultShippingProductHelp:
    "Bitte wähle hier einen Porto-Artikel aus, auf den die Versandkosten gebucht werden sollen. Porto-Artikel werden erstellt, indem in der Artikelanlage die Option “Artikel ist Porto” ausgewählt wird.",
  defaultSurchargeProduct: "Artikelnummer für Zuschläge",
  defaultSurchargeProductHelp:
    "Bitte wähle hier einen Artikel für Zuschläge aus, auf den die Zuschläge gebucht werden sollen. Sämtliche Zuschläge werden dabei aufsummiert.",
  deliveryCondition: "Lieferbedingungen",
  deliveryConditions:
    "Welche Lieferbedingungen sollen für den Auftragsimport für diesen Verkaufskanal gelten?",
  deliveryStateAll: "Alle",
  deliveryStateDelivered: "abgeschlossen",
  deliveryStateForOrders:
    "Welcher Versandstatus soll als Auslöser verwendet werden?",
  deliveryStateForOrdersHelp:
    "Welcher Versandstatus soll als Auslöser verwendet werden, um Aufträge abzurufen?",
  deliveryStateOpen: "Offen",
  deliveryStateProcessing: "In Bearbeitung",
  deliveryTerms:
    "Welche Lieferbedingungen sollen für den Auftragsimport für diesen Verkaufskanal gelten?",
  deliveryTermsHelp:
    "Hier kann die gewünschte Lieferbedingung per Suchfunktion ausgewählt werden. Falls noch keine Lieferbedingung vorhanden ist, muss zunächst eine in Xentral angelegt werden.",
  discounts: "Gutschriften & Rabatte",
  discountsNumber: "Gutschriften und Rabatte mit mehreren Steuersätzen",
  discountsNumberHelp:
    "Sollen weitere Steuersätze auf Gutschriften und Rabatte berücksichtigt werden?",
  discountsTaxesAssignment: "Steuersatz > Porto-Artikel Zuordnung",
  discountsTaxesAssignmentHelp:
    "Wenn abweichend zum normalen Steuersatz noch weitere Steuersätze auf Gutschriften und Rabatte berücksichtigt werden sollen,  können hier die entsprechenden Porto-Artikel für die verschiedenen Steuersätze angegeben werden.  Dazu muss für jeden zu berücksichtigenden Steuersatz ein Rabatt-Artikel erstellt und hier ausgewählt werden.",
  discountsTaxesAssignmentSelect: "Weitere Steuersätze",
  discountsTaxesAssignmentSelectHelp:
    "Sollen weitere Steuersätze auf Gutschriften und Rabatte berücksichtigt werden?",
  fastLaneMethodIds: "Fast-Lane Versandarten",
  fastLaneMethodIdsHelp:
    "Hier kannst du Xentral Versandarten auswählen, bei denen die Fast-Lane aktiviert werden soll.",
  granted: "Zahlung garantiert",
  mail: "Mail-Adresse",
  maxDifference: "Maximale Differenz zur in Xentral berechneten Warenkorbsumme",
  maxDifferenceHelp:
    "Wird diese Differenz überschritten, wird der in Xentral berechnete Gesamtbetrag des Auftrags genutzt.",
  nameAddress: "Name & Adresse",
  noCustomerFound: "Wenn kein Kunde gefunden wird, dann",
  noCustomerFoundHelp:
    "Wenn kein Kunde nach den angegebenen Suchmerkmalen gefunden wurde, wie soll dann weiter verfahren werden?",
  noImport: "Auftrag nicht importieren",
  noOrderRemark: "nicht beachten",
  orderInput: "Bei Auftragseingang",
  orderRemark: "Kommentar aus Auftrag verarbeiten?",
  orderRemarkHelp:
    "Soll der Kommentar aus dem Verkaufskanal-Auftrag in Xentral verarbeitet werden? Wenn das Feld leer gelassen wird, wird der Kommentar ignoriert.",
  paid: "bezahlt",
  partiallyPaid: "teilweise bezahlt",
  paymentMethodsAssignment: "Zahlungsarten Zuordnung",
  paymentMethodsAssignmentHelp:
    "Hier können Zahlungsarten aus Xentral den entsprechenden Zahlungsarten des Verkaufskanals zugeordnet werden",
  paymentMethodsAssignmentSelect: "Strategie Zahlungsarten Zuordnung",
  paymentMethodsAssignmentSelectHelp:
    "Wie soll die Zuordnung von Zahlungsarten aus dem Auftrag erfolgen?",
  paymentMethodsAssignmentXentral:
    "Zahlungsarten aus dem Verkaufskanal übernehmen",
  paymentOpen: "Zahlung offen",
  paymentStateForOrders: "Zahlungstatus um Aufträge abzurufen",
  paymentStateForOrdersHelp:
    "Welcher Zahlungsstatus soll als Auslöser verwendet werden, um Aufträge abzurufen?",
  pending: "offen",
  posCustomers: "Gibt es POS Kunden?",
  posCustomersHelp:
    "Wenn diese Integration POS (Point of Sale) Kunden enthält, werden diese Kunden immer auf einen Default POS-Kunden geschrieben. Dieser muss unten ausgewählt werden.",
  projectHelp:
    "Hier kannst du das Xentral Projekt auswählen, welches Aufträgen beim Import zugewiesen wird.",
  salesChannelAssignment: "Sales Channel <> Projekt Zuordnung",
  salesChannelAssignmentHelp:
    "Hier kannst du die jeweiligen Sales Channels aus {appName} jeweils einem Xentral Projekt zuordnen. Dieses wird dann beim Import von Aufträgen zugewiesen.",
  scopeProject: "Bestehende Kunden nur innerhalb des Projektes zuordnen?",
  scopeProjectHelp:
    'Wenn ein Kunde nicht innerhalb des Projektes gefunden wird, wird das in "Kundenanlage" definierte Verfahren verwendet',
  shippingCosts: "Versandkosten",
  shippingCostsNumber: "Versandkosten mit mehreren Steuersätzen",
  shippingCostsNumberHelp:
    "Sollen weitere Steuersätze auf Versandkosten berücksichtigt werden?",
  shippingCostsTaxesAssignment: "Steuersatz > Porto-Artikel Zuordnung",
  shippingCostsTaxesAssignmentHelp:
    "Wenn abweichend zum normalen Steuersatz noch weitere Steuersätze auf Versandkosten berücksichtigt werden sollen,  können hier die entsprechenden Porto-Artikel für die verschiedenen Steuersätze angegeben werden.  Dazu muss für jeden zu berücksichtigenden Steuersatz ein Porto-Artikel erstellt und hier ausgewählt werden.",
  shippingCostsTaxesAssignmentSelect: "Weitere Steuersätze",
  shippingCostsTaxesAssignmentSelectHelp:
    "Sollen weitere Steuersätze auf Versandkosten berücksichtigt werden?",
  shippingMethodsAssignment: "Versandarten Zuordnung",
  shippingMethodsAssignmentHelp:
    "Hier können Versandarten aus Xentral den entsprechenden Versandarten des Verkaufskanals zugeordnet werden",
  shippingMethodsAssignmentSelect: "Strategie Versandarten Zuordnung",
  shippingMethodsAssignmentSelectHelp:
    "Wie soll die Zuordnung von Versandarten aus dem Auftrag erfolgen?",
  shippingMethodsAssignmentXentral:
    "Versandarten aus dem Verkaufskanal übernehmen",
  tag: "Tag",
  tagAssignmentTableId: "Tag <> Projekt Zuordnung",
  tagAssignmentTableIdHelp:
    "Hier kannst du die jeweiligen Tags von Aufträgen aus Shopify jeweils einem Xentral Projekt zuordnen. Dieses wird dann beim Import von Aufträgen zugewiesen. Die Projektzuordnung anhand des Tags überschreibt die Projektzuordnung anhand des Sales Channels.",
  taxCalculationStrategy: "Wie sollen Steuern in Xentral berechnet werden? ",
  taxCalculationStrategyHelp:
    "Steuern werden pro Position berechnet und anschließend addiert. Andernfalls wird die Steuer auf die Warenkorbsumme berechnet.",
  taxEmptyToExemptedTax:
    "Befreiten Steuersatz bei fehlender Steuerinformation übertragen",
  taxEmptyToExemptedTaxHelp:
    "Hier kannst du auswählen, ob Positionen mit fehlender Steuerinformation von Shopify mit einem befreiten Steuersatz übertragen werden sollen.",
  taxes: "Steuern",
  updateStrategyPayment: "Zahlungsstatus für Auftragsimport",
  updateStrategyPaymentHelp:
    "Welche(r) Zahlungsstatus soll(en) als Auslöser verwendet werden, um Aufträge abzurufen?",
  updateStrategy: "Auftragsstatus für Auftragsimport",
  updateStrategyHelp:
    "Welche(r) Auftragsstatus soll(en) als Auslöser verwendet werden, um Aufträge abzurufen?"
};
