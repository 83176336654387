export const queuedJobs = {
  queue: "Warteschlange",
  queues: "Warteschlangen",
  queuedJobs: "Warteschlangen Jobs",
  jobClass: "Jobklasse",
  jobAbortFailed:
    "Jobs mit dem Status '{status}' können nicht abgebrochen werden",
  processID: "Prozess ID",
  processName: "Prozess name",
  deleteConfirm: "Job-Abbruch bestätigen",
  deleteConfirmText: 'Möchten Sie den Job "{queue}" wirklich abbrechen?',
  deleteSucessText: "Job {queue} wurde abgebrochen",
  processIterationId: "Prozessdurchlauf ID",
  tenantId: "Tenant ID",
  tenantName: "Tenant name",
  confirmBulkDelete: "Massenabbruch bestätigen",
  allSelectedRowsDelete: "Alle ausgewählten ({count}) Jobs werden abgebrochen",
  allFilteredRowsDelete: "Alle gefilterten ({count}) Jobs werden abgebrochen",
  selectedRowsDeleted: "{count} Jobs wurden abgebrochen",
  selectAll: "Alle auswählen",
  unselectAll: "Alle abwählen",
  deleteSelected: "Ausgewählte Jobs abbrechen",
  startedBy: "Gestartet von",
  queuesFilterSelect: "Warteschlangenfilter",
  deleteAllFilteredButton: "Alle gefilterten Jobs abbrechen",
  processFilterSelect: "Prozessfilter",
  others: "Andere",
  yesCancel: "Abbruch Bestätigen",
  noCancel: "Abbruch Stornieren",
  requeueSelectedSuccess:
    "Ausgewählte Jobs wurden erfolgreich auf die Queue gelegt",
  cancelSuccessText: "Der Job wurde erfolgreich abgebrochen"
};
