import { amazon } from "@/i18n/de-de/components/salesChannelManagementApp/amazon";
import { product } from "@/i18n/de-de/components/salesChannelManagementApp/product";
import { prices } from "@/i18n/de-de/components/salesChannelManagementApp/prices";
import { tracking } from "@/i18n/de-de/components/salesChannelManagementApp/tracking";
import { shopify } from "@/i18n/de-de/components/salesChannelManagementApp/shopify";
import { orders } from "@/i18n/de-de/components/salesChannelManagementApp/orders";
import { stock } from "@/i18n/de-de/components/salesChannelManagementApp/stock";
import { settings } from "@/i18n/de-de/components/salesChannelManagementApp/settings";
import { woocommerce } from "@/i18n/de-de/components/salesChannelManagementApp/woocommerce";
import { category } from "@/i18n/de-de/components/salesChannelManagementApp/category";
import { migration } from "@/i18n/de-de/components/salesChannelManagementApp/migration";
import { test } from "@/i18n/de-de/components/salesChannelManagementApp/test";
import { bigcommerce } from "@/i18n/de-de/components/salesChannelManagementApp/bigcommerce";
import { reporting } from "@/i18n/de-de/components/salesChannelManagementApp/reporting";
import { productSelection } from "@/i18n/de-de/components/salesChannelManagementApp/productSelection";
import { shopware6 } from "@/i18n/de-de/components/salesChannelManagementApp/shopware6";
import { otto } from "@/i18n/de-de/components/salesChannelManagementApp/otto";
import { journal } from "@/i18n/de-de/components/salesChannelManagementApp/journal";
import { offer } from "@/i18n/de-de/components/salesChannelManagementApp/offer";
import { links } from "@/i18n/de-de/components/salesChannelManagementApp/links";
import { helpArea } from "@/i18n/de-de/components/salesChannelManagementApp/helpArea";

export const salesChannelManagementApp = {
  active: "Aktiv",
  activeHelp: "Ist der Workflow aktiv?",
  addIntegration: "Neue Integration",
  advancedPricing: "Erweiterte Preise",
  all: "Alle",
  helpArea: helpArea,
  amazon: amazon,
  apiKey: "API Key",
  apiKeyHelp: "Authentifizierungsdaten 1",
  appSaved: "Die Integration {integrationName} wurde gespeichert.",
  articleCount: "Anzahl Artikel",
  assignment: "Kategoriezuordnung",
  assignmentSelectManual: "Manuelle Zuordnungstabelle",
  assignmentStrategyManual: "Manuelle Zuordnungstabelle",
  assignmentTableShop: "{appName}",
  assignmentTableXentral: "Xentral",
  authRetry: "Erneut authentifizieren",
  authorize: "Autorisieren",
  autoAcceptOrders: "Aufträge automatisch akzeptieren",
  autoAcceptOrdersHelp:
    "Hier kannst du festlegen, ob neue Aufträge im Marktplatz automatisch akzeptiert werden.",
  back: "Zurück",
  backToOverview: "Übersicht",
  baseData: "Stammdaten (exkl. Preise und Bestände)",
  baseSettings: "Basiseinstellungen",
  bigcommerce: bigcommerce,
  calculateStock: "Lagerbestand berechnen",
  cancellation: "Storno",
  carrier: "Versanddienstleister",
  carrierAssignment: "Versanddienstleister-Zuordnung",
  category: category,
  categoryAssignment: "Kategoriezuordnung",
  categoryLabel: "Kategorie",
  changeActiveStatusTextActive:
    "Achtung! Wenn du deine Integration aktiv schaltest, werden Daten automatisch mit deinem Verkaufskanal synchronisiert. Diese Änderung greift erst beim nächsten Speichern.",
  changeActiveStatusTextInactive:
    "Achtung! Wenn du deine Integration inaktiv schaltest, werden Daten nicht mehr automatisch mit deinem Verkaufskanal synchronisiert. Diese Änderung greift erst beim nächsten Speichern.",
  changeActiveStatusTitle: "Modus der Integration ändern?",
  clientId: "Client ID",
  clientSecret: "Client Secret",
  collection: "Collection",
  country: "Land",
  countryAssignment: "Länderzuordnung",
  credentials: "Zugangsdaten",
  currency: "Währung",
  currencyAssignment: "Währungszuordnung",
  customField: "Freifeld",
  customFieldAssignment: "Freifeldzuordnung",
  customFields: "Freifelder",
  customer: "Kundenzuordnung",
  customerGroup: "Kundengruppe",
  customers: "Kundenzuordnung",
  dataTypes: "Datentypen",
  dataTypesHelp: "Welche Datentypen sollen übertragen werden?",
  date: "Datum",
  deltaTransmission: "Deltaübetragung",
  deltaTransmissionHelp: "Welche Artikel sollen übertragen werden?",
  descriptions: {
    baseData: "Fügen Sie hier die Basisdaten für Ihre neue Integration ein.",
    credentials: "Verbinden der Systeme.",
    features: "Aktiviere deine benötigten Features.",
    tbOneChannelSelection: "Wähle deine TB.One Kanäle aus",
    sftpData: "Hinterlege deine SFTP Daten"
  },
  discountProduct: "Rabatte",
  discounts: "Gutschriften & Rabatte",
  exportActive:
    "Deine Integration wird vorbereitet. Bitte hab noch etwas Geduld. Sobald die Integration fertig angelegt ist kannst du diese hier konfigurieren.",
  exportRules: "Exportregeln",
  featureCategory: "Kategorien abgleichen (Xentral > Verkaufskanal)",
  featureOffer:
    "Angebote (inkl. Preise und Bestände) abgleichen (Xentral > Verkaufskanal)",
  featureOrders: "Aufträge abgleichen (Verkaufskanal > Xentral)",
  featurePrices: "Preise abgleichen (Xentral > Verkaufskanal)",
  featureProduct:
    "Artikel (exkl. Preise und Bestände) abgleichen (Xentral > Verkaufskanal)",
  featureRequired: "Bitte mindestens ein Feature auswählen",
  featureStock: "Bestände abgleichen (Xentral > Verkaufskanal)",
  featureTracking:
    "Auftragsstatus und Tracking abgleichen (Xentral > Verkaufskanal)",
  featureReceipt: "Belege (Xentral > Verkaufskanal)",
  features: "Features",
  group: "Warengruppe",
  help: "<div>Schwierigkeiten?</div><div>Schreibe uns an <a href='mailto:feedback@xentral.com'>feedback@xentral.com</a></div>",
  info: "Hinweis",
  initArticleImport: "Initialer Artikelimport",
  initialFile: {
    generateInitialFile: "Initiale Datei erzeugen",
    dialogTitle: "Initiale Datei",
    productDialogInfo:
      "Für das initiale Mapping deiner Artikeldaten in Mirakl wird eine Importdatei benötigt. Achte darauf dass diese ausreichend Artikel enthält, so dass alle vergebenen Artikelkategorien mindestens einmal enthalten sind.",
    offerDialogInfo:
      "Hier kannst du eine CSV-Datei mit Angeboten für den initialen Import erstellen.",
    building: "Die initiale Importdatei wird erzeugt",
    buildingInfo:
      "Sobald die initiale Importdatei erzeugt wurde, kannst du diese über den oben angezeigten Link herunterladen.",
    limit: "Limit",
    all: "Alle",
    failed:
      "Die letzte Statusmeldung ist älter als eine Stunde. Möglicherweise ist das erstellen der initialen Importdatei abgebrochen und kann neu gestartet werden.",
    started:
      "Das Erstellen der initialen Importdatei wurde gestartet. Es kann ein paar Minuten dauern, bis der Status verfügbar ist."
  },
  links: links,
  initialFileCreatedAt: "Zuletzt erzeugt am",
  initialFileName: "Link zur Datei",
  integration: {
    dev: "Die Integration befindet sich im Entwicklungsmodus.",
    live: "Die Integration befindet sich im Produktivmodus."
  },
  integrationActiveHelp: "Ist diese Integration aktiv?",
  journal: journal,
  key: "Consumer Key",
  keyword: "Keyword",
  language: "Sprache",
  languageAssignment: "Sprachenzuordnung",
  layout: "Ansicht der Artikelseite",
  lineItems: "Auftragspositionen",
  liveDevMode: "Integrationsmodus",
  livemode: "Produktiv",
  livemodeActive: "Produktivmodus aktivieren",
  livemodeError:
    "Aktivierung fehlgeschlagen. Fülle alle markierten Pflichtfelder aus.",
  loadIntegration:
    "Ihre Integration wird geladen. Dieser Vorgang kann etwas dauern.",
  mail: "Mailversand",
  manual: "Manuell",
  marketPlace: "Marktplatz",
  marketPlacePlural: "Marktplätze",
  media: "Medien",
  metaTexts: "Meta Texte",
  migration: migration,
  mode: "Modus",
  modeHelp: "Wird die Integration live verwendet?",
  name: "Name der Integration",
  nameHelp:
    "Wie soll die neue Integration benannt werden? Bitte beachte, dass der Integrationsname eindeutig sein muss.",
  next: "Weiter",
  no: "Nein",
  noConfigAvailable: "Keine Konfigurationen verfügbar",
  notification: "Benachrichtigungen",
  of: "von",
  offer: offer,
  onChange: "Bei Änderung",
  orders: orders,
  otto: otto,
  password: "Passwort / API-Schlüssel",
  passwordHelp: "Authentifizierungsdaten 2",
  payment: "Zahlungsarten",
  paymentAndShippingState: "Zahlungs- und Versandstatus",
  paymentMethod: "Zahlungsart",
  paymentMethods: "Zahlungsarten",
  paymentState: "Zahlungsstatus",
  pos: "POS Kunden",
  position: "Positionen",
  priceRule: "Preisregeln",
  priceSelection: "Preisauswahl",
  priceTitle: "Preise",
  prices: prices,
  product: product,
  productAssignment: "Artikelzuordnung",
  productBaseData: "Stammdaten",
  productSelection: productSelection,
  productSelectionTable: {
    id: "ID",
    name: "Name",
    productNumber: "Artikel Nr.",
    ean: "EAN Nr. / Barcode",
    isDisabled: "Sperre aktiv",
    projectId: "Projekt ID",
    merchandiseGroupId: "Artikelkategorie ID"
  },
  project: "Projekt",
  projectHelp: "Welchem Projekt in Xentral ist die Integration zugeordnet?",
  properties: "Eigenschaften",
  publication: "Sales Channel",
  remark: "Kommentare",
  reporting: reporting,
  returns: "Retouren",
  salesChannelAssignment: "Projektzuordnung",
  salesChannels: "Sales Channels",
  save: "Speichern",
  saveIntegration:
    "Ihre Integration wird erstellt. Dieser Vorgang kann einige Zeit dauern.",
  secret: "Consumer Secret",
  settings: settings,
  shipFromAddress: "Lageradresse",
  shipping: "Versandarten & Lieferbedingungen",
  shippingCosts: "Versandkosten",
  shippingCostsDiscountsTaxes: "Versandkosten, Rabatte und Steuern",
  shippingMethod: "Versandart",
  shippingMethods: "Versandarten",
  shippingProduct: "Versandkosten",
  shippingState: "Versandstatus",
  shop: "Onlineshop",
  shopPlural: "Onlineshops",
  shopify: shopify,
  shopware6: shopware6,
  stateOfTransfer: "Status für Übertragung",
  steps: "Schritt",
  stock: stock,
  stockTitle: "Bestand",
  surchargeProduct: "Zuschläge",
  taxes: "Steuern",
  test: test,
  timeBased: "Zeitbasiert",
  timeOfTransfer: "Zeitpunkt der Übertragung",
  timeOfTransferHelp: "Wie und wann wird die Übertragung ausgelöst?",
  title: {
    stock: "Bestand",
    price: "Preise",
    initialImportFile: "Initiale Importdatei",
    general: "Allgemein"
  },
  titles: {
    additionalSettings: "Weitere Einstellungen",
    baseData: "Basisdaten",
    baseSettings: "Grundeinstellungen",
    credentials: "Zugangsdaten",
    dashboard: "Dashboard",
    features: "Features",
    journal: "Artikel",
    prices: "Preise",
    product: "Artikel",
    category: "Kategorie",
    tbOneChannelSelection: "TB.One Kanal",
    productSelection: {
      selection: "Artikelzuordnung",
      configure: "Artikelfilter konfigurieren"
    },
    salesOrder: "Aufträge",
    salesOrder_payment: "Zahlungsarten",
    salesOrder_shipping: "Versandarten",
    settings: {
      title: "Einstellungen",
      wizard: "Grundeinstellungen",
      productAssignment: "Artikelzuordnung"
    },
    reporting: "Reporting",
    stock: "Bestand",
    tracking: "Auftragsstatus & Tracking",
    migration: {
      title: "Initialer Datenimport",
      strategy: "Migrationsstrategie",
      settings: "Einstellungen"
    },
    sftpData: "SFTP-Daten",
    offer: "Angebote",
    receipt: "Belege"
  },
  token: "Token",
  tracking: tracking,
  updateStrategy: "Update Strategie",
  updateStrategyHelp: "Wie und wann wird die Übertragung ausgelöst?",
  url: "URL",
  urlHelp: "URL des anzubindenden Systems",
  useFilter: "Filter anwenden",
  useFilterForAllProducts: "Filter für alle Artikel anwenden",
  useFilterForSelectedProducts: "Filter für ausgewählte Artikel anwenden",
  usedFilters: "Bisher angewandte Filter",
  username: "Benutzername",
  validateCredentials: "Verbindung testen",
  validateSftpData: "SFTP Verbindung testen",
  validateCredentialsError:
    "Bitte überprüfen Sie, ob korrekte Zugangsdaten angegeben wurden",
  validateCredentialsSuccess: "Die Verbindung konnte aufgebaut werden.",
  value: "Wert",
  warehouse: "Lager",
  wizardError: "Beim Anlegen der Integration gab es einen Fehler.",
  wizardSaving: "Ihre neue Shop-Integration wird erstellt...",
  wizardSuccess:
    "Die Integration {integrationName} wurde erfolgreich angelegt.",
  wizardSalesChannelNotCreated:
    "Die Integration {integrationName} wurde angelegt. Es konnte jedoch kein passender Verkaufskanal angelegt werden. Bitte kontaktiere den Support.",
  woocommerce: woocommerce,
  workflows: "Workflows",
  xentral: "Xentral",
  yes: "Ja",
  invoices: "Rechnungen"
};
