export const variables = {
  configValues: "Configuration Values",
  parameters: "Parameters",
  presetValues: "Preset Values",
  inputVariables: "Input Variables",
  outputVariables: "Output Variables",
  objectVariables: "Object Variables",
  sourceVariables: "Source Variables",
  demo: "Demo variables",
  dataSetFields: "DataSet fields",
  dataSetParameters: "DataSet parameters",
  outputValues: "Output Values",
  errorValues: "Error Values",
  scmaXentralFields: "Fields from Xentral"
};
