export const fileExplorer = {
  actions: {
    deleteFile: 'Delete "{file}"',
    downloadFile: 'Download "{file}"',
    renameFile: 'Rename "{file}"',
    moveFile: 'Move "{file}"',
    viewFile: "Dateiinhalt ansehen"
  },
  createFolderSuccess: 'Folder "{file}" created successfully',
  createFolder: "Create folder",
  downloadFile: "Download file",
  downloadFiles: "Download files",
  deleteSuccess: 'File "{file}" deleted successfully',
  delete: "Delete file",
  fileContent: "File content",
  fileExplorer: "File Explorer",
  fileName: "File name",
  folderName: "Folder name",
  fileSize: "File size",
  fileType: "File type",
  folder: "Folder",
  searchForFolder: "Search for folder..",
  fileNotAllowed: "This file type is not allowed",
  lastModified: "Last modified",
  moveTo: "Move to folder:",
  rename: "Rename",
  renameSuccess: 'File "{file}" renamed successfully',
  type: "Type",
  view: "View file content",
  selectedFiles: "selected file(s)"
};
