export const assignments = {
  assignments: "Zuordnungstabelle",
  assignmentsCreate: "Zuordnungstabelle erstellen",
  assignmentsEdit: "Zuordnungstabelle bearbeiten",
  assignmentsName: "Name",
  assignmentExportSuccess:
    "Zuordnungstabelle: '{name}' wurde erfolgreich exportiert",
  assignmentImportSuccess:
    "Die Zuordnungstabelle wurde erfolgreich importiert.",
  description: "Beschreibung",
  values: "Werte",
  key: "Schlüssel",
  value: "Wert",
  addValue: "Werte hinzufügen",
  assignmentsSave: "Speichern",
  assignmentsSaved: "Zuordnungstabelle wurde gespeichert",
  deleteAssignmentsTitle: 'Zuordnungstabelle "{title}" löschen?',
  deleteAssignmentsText:
    "Möchten Sie die Zuordnungstabelle wirklich endgültig löschen? Dieser Vorgang kann nicht rückgängig gemacht werden.",
  assignmentsDeleted: "Zuordnungstabelle wurde gelöscht",
  projects: "Projekte",
  export: "Export",
  import: "Import"
};
