import Vue from "vue";
import { validationMixin } from "vuelidate";
import {
  checkDependencies,
  getSnippet,
  getValidations as getFieldValidations
} from "@/components/Tools/FormHelper/Helper/functions";
import i18n from "@/core/plugins/vue-i18n";

export function validate(
  values,
  fields,
  enableVariables = true,
  prefix = "",
  compareValues = values
) {
  // Create new virtual vue instance to be able to use vuelidate
  let vm = new Vue({
    mixins: [validationMixin],
    data() {
      return {
        values: values
      };
    },
    // Set validations
    validations() {
      let validations = getValidations(
        values,
        fields,
        enableVariables,
        compareValues
      );
      // Return complete validations
      return { values: validations };
    }
  });
  // Touch form for actual validation
  vm.$v.$touch();
  // Return array of errors
  return getErrors(vm.$v.values, fields, prefix);
}

function getValidations(values, fields, enableVariables, compareValues) {
  let validations = {};
  // Loop through given values
  Object.keys(values).forEach(name => {
    // Get field of given fields
    const field = fields.find(f => f.name === name);
    if (!field) {
      return;
    }
    // Return if field is of type group or field doesn't have validations
    if (
      checkDependencies(field, compareValues) &&
      Object.keys(field.validations ?? {}).length
    ) {
      // Set validation[s] for value
      validations[name] = getFieldValidations(
        field.validations,
        field.enableVariables ?? enableVariables,
        field.type
      );
    }
    // If field type is group, add validation for children
    if (field.type === "group") {
      if (validations[name] === undefined) {
        validations[name] = {};
      }
      let valuesValue = values[name] ?? [];
      // Loop through group entries
      valuesValue.forEach((group, key) => {
        validations[name][key] = getValidations(
          group,
          field.fields,
          field.enableVariables ?? enableVariables
        );
      });
    }
  });
  return validations;
}

function getErrors(values, fields, prefix) {
  let errors = [];
  Object.keys(values)
    // Filter keys from vuelidate
    .filter(key => !key.startsWith("$"))
    .forEach(fieldName => {
      let field = values[fieldName];
      let baseField = fields.find(f => f.name === fieldName);
      // Loop through vuelidate properties of each field
      Object.keys(field).forEach(validator => {
        // Continue if property is no validator (starts with "$")
        // or validator is not invalid
        if (
          validator.startsWith("$") ||
          field[validator] === true ||
          (baseField.type === "group" && !isNaN(validator))
        ) {
          return;
        }
        // Get field label for identification
        let label = getSnippet(baseField.label ?? baseField.name, prefix);
        // Add validator's error message to array
        errors.push(
          i18n.t(
            "formHelper.errors." + validator,
            Object.assign({}, field.$params[validator], { label })
          )
        );
      });
      // If field is of type group and has children
      if (baseField.type === "group") {
        Object.keys(field)
          .filter(key => !key.startsWith("$") && !isNaN(key))
          .forEach(key => {
            errors.push(...getErrors(field[key], baseField.fields));
          });
      }
    });
  return errors;
}
