import { ref } from "vue";

export const SET_INSTANCE = "setInstance";

const state = ref({
  instance: {
    id: "",
    name: "",
    xentralUrl: "",
    isPresetInstance: false
  }
});

const getters = {
  instance: state => {
    return state.instance;
  }
};

const actions = {
  [SET_INSTANCE]: (context, payload) => {
    context.commit(SET_INSTANCE, payload);
  }
};

const mutations = {
  [SET_INSTANCE](state, payload) {
    state.instance = payload;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
