import { Toast } from "@/core/plugins/swal";
import { getSnippet } from "@/components/Tools/FormHelper/Helper/functions";
import { ref } from "vue";

const state = ref({
  queues: []
});

// Example: Calling mutation in components:
// store.dispatch("addEventToLoadingQueue", {
//   key: "key",
//   type: "error", - Icon of toast
//   prefix: "general" - Prefix of snippet
//   name: "cancel", - Name of snippet,
//   progressBarKey: "bar1",
//   group: "saving", - Group name of snippet
// });
// Note: "key" is the only mandatory field,
// however if a toast should be fired, "type", "prefix" and "name" are also mandatory

const getters = {
  showLoadingBar:
    state =>
    (key = "", group = null) => {
      let queues = state.queues.filter(
          queue =>
            queue.progressBarKey === undefined ||
          queue.progressBarKey === null ||
          queue.progressBarKey === "" ||
          queue.progressBarKey === key
      );

      if (group) {
        queues = queues.filter(queue => queue.group === group);
      }

      return queues.length > 0;
    }
};

const mutations = {
  addEventToLoadingQueue(state, payload) {
    if (!payload.key) {
      return;
    }
    // Add event to queues
    state.queues.push(payload);
    checkToast(payload);
  },
  removeEventFromLoadingQueue(state, payload) {
    if (!payload.key && !payload.group) {
      return;
    }
    // Remove event from queues
    if (payload.key)
      state.queues = state.queues.filter(queue => queue.key !== payload.key);
    if (payload.group)
      state.queues = state.queues.filter(
        queue => queue.group !== payload.group
      );
    checkToast(payload);
  },
  reset(state) {
    state.queues = [];
  }
};

const actions = {
  addEventToLoadingQueue(context, payload) {
    context.commit("addEventToLoadingQueue", payload);
  },
  removeEventFromLoadingQueue(context, payload) {
    context.commit("removeEventFromLoadingQueue", payload);
  },
  reset: context => context.commit("reset")
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};

function checkToast(payload) {
  if (!payload.prefix || !payload.name || !payload.type) {
    return;
  }

  // Fires toast with given payload
  Toast.fire({
    icon: payload.type,
    title: getSnippet(payload.name, payload.prefix, payload.data)
  });
}
