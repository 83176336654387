export const config = {
  name: "Technical name",
  variables: "Variables",
  configCreate: "Create configuration",
  copy: " - copy",
  nameEnter: "Enter name",
  label: "Label",
  type: "Type",
  key: "Key",
  value: "Value",
  configCreated: "Configuration created",
  configUpdate: "Update configuration",
  configUpdated: "Configuration updated",
  configDeleteMessage:
    'Do you really want to delete the config "{config}"? This process is irreversible.',
  configDeleteTitle: "Delete config?",
  configDeleted: "Configuration deleted successfully",
  configurations: "Configurations",
  authentication: "Authentication"
};
