export const mapping = {
  backToOverview: "Zurück zur Übersicht",
  connectionsToggle: "Verbindungslinien anzeigen",
  connectionDeleteTitle: "Verbindung löschen?",
  connectionDeleteText:
    "Wenn die Verbindung gelöscht wird, kann es sein, dass konfigurierte Transformatoren nicht mehr richtig funktionieren. Möchten Sie die Verbindung wirklich löschen?",
  dataStructureSearch: "{name} durchsuchen",
  undo: "Undo",
  redo: "Redo",
  modeMapping: "Data Mapper",
  modeCode: "Code",
  modeDebug: "Debug",
  mappingSaved: "Mapping wurde gespeichert",
  initialVariables: "Input-Variablen",
  transformersOutput: "Transformer-Output",
  transformersOutputEmpty: "Noch kein Output definiert.",
  selectDataSet: "Datensatz auswählen",
  selectDataSetFirst:
    "Im Debug Modus muss zuerst ein Datensatz ausgewählt werden.",
  noDataSets: "Keine Datensätze für diese Datenstruktur gefunden.",
  mappings: "Mappings",
  mappingCreate: "Mapping erstellen",
  mappingLabel: "Bezeichnung",
  mappingDescription: "Beschreibung",
  mappingActive: "Aktiv",
  mappingDataStructureSource: "Quell-Datenstruktur",
  mappingDataStructureTarget: "Ziel-Datenstruktur",
  mappingSave: "Mapping speichern",
  dataStructureSource: "Quell-Datenstruktur",
  dataStructureTarget: "Ziel-Datenstruktur",
  detailsBasicConfiguration: "Basis Konfiguration",
  maxInputValues:
    'Maximale Anzahl an Input-Variablen für Transformer "{label}": {maxValues}',
  configurationTitle: "Konfiguration von {label}",
  configurationNoType: "Keine Konfiguration verfügbar",
  configurationNoTransformer: "Transformer zur Bearbeitung wählen",
  availableVariables: "Verfügbare Variablen",
  noAvailableVariables: "Keine Input-Variablen verfügbar",
  mappingNotSavedTitle: "Änderungen verwerfen?",
  mappingNotSavedText:
    "Die Änderungen am Mapping wurden noch nicht gespeichert. Soll trotzdem fortgefahren werden? Die Änderungen gehen damit unwiderruflich verloren.",
  continueDespite: "Trotzdem fortfahren",
  startMapping: "Mapping starten",
  runningMapping: "Mapping läuft",
  notMapped: "Feld nicht gemappt",
  mappingFinished: "Mapping erfolgreich durchgeführt",
  transformers: "Transformer",
  transformer: "Transformer",
  transformerLabel: "Bezeichnung",
  transformerTransformer: "Ausgewählter Transformer",
  transformerInputVars: "Input Variablen",
  transformerOutput: "Output",
  runMappingFirst:
    "Um die Debug Infos darstellen zu können, muss erst ein Datensatz ausgewählt und ein Mapping durchgeführt werden.",
  dataStructureChangeTitle: "Datenstruktur bearbeiten?",
  dataStructureChangeText:
    "Wenn Sie die Datenstruktur bearbeiten, kann es zu Fehlern im aktuellen Mapping kommen. Außerdem werden nicht gespeicherte Änderungen am Mapping verworfen. Möchten Sie dennoch fortfahren?",
  noLabel: "Bezeichnung...",
  noInputVars: "Keine Input Variablen definiert.",
  noOutputVar: "Kein Output Variablenname definiert.",
  mappingConnectionDependenciesTitle: "Abhängigkeiten entdeckt!",
  mappingConnectionDependenciesText:
    "Die zum Löschen ausgewählte Verbindung hat von ihr abhängige Kind-Verbindungen. Um mit dem Löschen fortzufahren, müssen alle Kind-Verbindungen ebenfalls entfernt werden. Trotzdem fortfahren?",
  deleteChildDependencies: "Abhängige Verbindungen ebenfalls löschen",
  cancelDeletion: "Löschvorgang abbrechen",
  parentConnectionCreated:
    "Es wurde automatisch eine notwendige Verbindung zwischen den Eltern-Feldern hergestellt",
  transformersCount: "{count} Transformer",
  transformerConfigurationBasic: "Basis-Konfiguration",
  transformerConfigurationConditions: "Bedingungen",
  transformerConfigurationTransformer: "Transformer Konfiguration",
  transformerConfigurationUpdated: "Konfiguration übernommen",
  removeConnection: "Verbindung löschen",
  transformerEdit: "Transformer konfigurieren",
  transformerDelete: "Transformer löschen",
  transformerSave: "Konfiguration anwenden",
  noValue: "Kein Wert",
  mappedSourceFields: "Verbundene Quellfelder",
  collection: "Art der Zusammenführung",
  collectionLabel: "Collection",
  collectionEntry: "Collection Eintrag",
  collectionEntries: "Anzahl Einträge: {count}",
  useDifferentDataStructures:
    "Es darf nicht die selbe Datenstruktur als Quell- und Zielstruktur ausgewählt werden.",
  deleteMappingTitle: 'Mapping "{title}" löschen?',
  deleteMappingText:
    "Möchten Sie das Mapping wirklich endgültig löschen? Dieser Vorgang kann nicht rückgängig gemacht werden.",
  mappingDeleted: "Mapping wurde gelöscht",
  mappingUpdated: "Mapping aktualisiert",
  conditions: "Bedingungen",
  conditionsEmpty: "Keine Bedingungen gesetzt.",
  outputValueDefault:
    "Standardmäßig der Output des letzten ausgeführten Transformers.",
  collectionTransformerRequired: 'Transformer "Daten zusammenführen" fehlt',
  selectField: "Feld auswählen",
  selectOperator: "Operator auswählen",
  selectValue: "Wert eingeben",
  clearConditionHelp: "Bedingung leeren",
  removeConditionHelp: "Bedingung löschen",
  addConditionGroup: "+ und",
  selectFieldsSource: "Felder-Quelle auswählen",
  conditionSourceSource: "Input-Variablen",
  conditionSourceObject: "Quell-Datenstruktur",
  conditionSourceConfig: "Config Values",
  conditionSourceOutput: "Output-Variablen",
  conditionsNoFields: "Keine Variablen verfügbar",
  typeString: "Text",
  typeNumber: "Zahl",
  typeBoolean: "Bool",
  typeNull: "null",
  typeVariable: "Variable",
  addAndCondition: "+ und Bedingung",
  addOrCondition: "+ oder Bedingung",
  collections: "Collections",
  collectionsConfiguration: "Collections Konfiguration",
  collectionsConfigurationCollection: "Daten zusammenführen",
  collectionsConfigurationFilter: "Filter",
  noTransformersAvailable: "Keine Transformer verfügbar",
  transformersWizardPreFilter: "Rohdaten filtern",
  transformersWizardCollections: "Daten zusammenführen",
  transformersWizardPostFilter: "Daten filtern",
  filterMergedCollections: "Zusammengefügte Daten filtern",
  noFilters: "Keine Filter gesetzt.",
  fixedNumber: "Feste Anzahl",
  multiply: "Multiplikation",
  sum: "Summe",
  assign: "Zuordnung",
  uniqueValues: "Einzigartige Werte",
  valueCount: "Anzahl",
  assignmentType: "Art der Zusammenführung",
  configureCollectionTransformers: "Konfigurieren",
  transformerOutputInvalid:
    "Output Name ist reserviert oder wird bereits verwendet.",
  delete: "Mapping Löschen",
  edit: "Mapping öffnen",
  configuration: "Mapping bearbeiten",
  addTransformer: "Transformer hinzufügen",
  data: "Daten",
  noData: "Keine Daten verfügbar.",
  staticCollection: "Statische Collection",
  staticCollectionTitle: "Collection Transformer löschen",
  staticCollectionText:
    "Wenn Sie fortfahren werden die Collection Transformer inklusive der Konfiguration unwiderruflich gelöscht. Möchten Sie dennoch fortfahren?",
  noInputConnections: "Keine Eingangs-Verbindungen verfügbar.",
  resultElements: "Ergebniselemente",
  removeEmptyValues: "Leere Werte entfernen",
  elementIndex: "Index",
  onlyRequiredFields: "Nur Pflichtfelder",
  currentCollectionIndex: "Collection Index",
  projects: "Projekte",
  resolveRelation: "Relation auflösen",
  unresolveRelation: "Aufgelöste Felder entfernen",
  relationHasConnections:
    "Ein untergeordnetes Feld dieser Relation hat eine bestehende Verbindung."
};
