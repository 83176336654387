export const fileExplorer = {
  actions: {
    deleteFile: '"{file}" löschen',
    downloadFile: '"{file}" herunterladen',
    renameFile: '"{file}" umbenennen',
    moveFile: '"{file}" verschieben',
    viewFile: "Dateiinhalt ansehen"
  },
  createFolderSuccess: 'Die Ordner "{file}" wurde erfolgreich angelegt',
  createFolder: "Ordner erstellen",
  downloadFile: "Datei herunterladen",
  downloadFiles: "Dateien herunterladen",
  deleteSuccess: 'Die Datei "{file}" wurde erfolgreich gelöscht',
  delete: "Datei löschen",
  fileContent: "Dateiinhalt",
  fileExplorer: "Datei-Explorer",
  fileName: "Dateiname",
  folderName: "Ordername",
  fileSize: "Dateigröße",
  fileType: "Dateityp",
  folder: "Ordner",
  searchForFolder: "Nach Ordner suchen..",
  lastModified: "Zuletzt geändert",
  fileNotAllowed: "Dieser Dateityp ist nicht erlaubt",
  moveTo: "In Ordner verschieben:",
  rename: "Umbenennen",
  renameSuccess: 'Die Datei "{file}" wurde erfolgreich umbenannt',
  type: "Typ",
  view: "Dateiinhalt anzeigen",
  selectedFiles: "Dateien ausgewählt"
};
