import { useApplyFilter } from "@/composables/TableHelper/useApplyFilter";
import { ref } from "vue";
import { Error } from "@/core/plugins/swal";
import i18n from "@/core/plugins/vue-i18n";

class BaseService {
  getParams(filters, params = {}, fields = []) {
    const filtersToApply = ref(filters);
    if (params?.filter) {
      filtersToApply.value.push(...params.filter);
      delete params.filter;
    }

    const { filter } = useApplyFilter(filtersToApply);

    let searchParams = new URLSearchParams(filter.value);

    fields.forEach(field => {
      searchParams.append("_fields[]", field);
    });

    return "&" + searchParams.toString();
  }

  /**
   * Get the base64 cache key used by memoize functions
   * @param params
   * @returns {string}
   */
  getCacheKey(params) {
    if (!params) {
      Error(i18n.t("error.default"));
    }

    try {
      return btoa(JSON.stringify(params));
    } catch (e) {
      Error(e);
    }
  }
}
export { BaseService };
