import { visualization } from "./insights/visualization";

export const insights = {
  addGraph: "Add graph",
  chartTitle: "Titel",
  commonSettings: "General Settings",
  configuration: "Configuration",
  create: "Create visualization",
  customization: "Adjustments",
  customizeAxisX: "Adjust x-axis",
  customizeAxisY: "Adjust y-axis",
  customizeGeneral: "General adjustments",
  customizeGraphs: "Customize graph",
  customizeOptions: "Customization Settings",
  dataSets: "Data Sets",
  delete: "Delete visualization",
  deleteSuccess: "Visualization deleted",
  deleteText:
    'Are you sure you want to delete the "{label}" visualization? This process is irreversible.',
  deleteTitle: "Delete Insight visualization",
  edit: "Edit Visualization",
  finish: "Save and exit",
  graph: "Graph",
  graphAxisHint: "A data set must first be selected",
  graphAxisNoFields: "There are no fields available for this data set",
  graphAxisX: "Data field x-axis",
  graphAxisY: "Data field y-axis",
  graphDataSet: "Data Set",
  graphLabel: "Label",
  label: "Label",
  loadPreview: "Load preview",
  reloadPreview: "Update data",
  saveAndContinue: "Save and continue",
  selectType: "Select chart",
  selectTypeWarning: "Please select a chart first",
  show: "View visualization",
  showDataLabels: "Show data values ​​on graph",
  showMarkers: "Show data points",
  theme: "Theme",
  title: "Insights visualizations",
  type: "Diagram type",
  typeArea: "Area",
  typeBar: "Bar",
  typeColumn: "Column",
  typeCustom: "Custom",
  typeDonut: "Donut",
  typeLine: "Lines",
  typePie: "Pie",
  xAxisSettings: "x-axis settings",
  xAxisTitle: "Titel x-axis",
  xAxisUnit: "Choose fixed unit",
  xAxisUnitAuto: "Generate units automatically",
  xUnitDays: "Days (01., 02., ...)",
  xUnitHours12: "Hours (12h)",
  xUnitHours24: "Hours (24h)",
  xUnitMonthsNumbers: "Months (01, 02, ...)",
  xUnitMonthsText: "Months (Jan., Feb., ...)",
  xUnitWeekdays: "Weekdays (Mo., Di., ...)",
  xUnitYears: "Years",
  yAxisTitle: "Title y-axis",
  curveSmooth: "Rounded",
  curveStraight: "Straight",
  curveStepLine: "Steps",
  curve: "Line graph",
  customizeBars: "Bar graph",
  stacked: "Stack values",
  stackType: "Stack Split",
  stackTypeAbsolute: "Absolut",
  stackTypeRelative: "Relativ",
  pieCategory: "Category",
  pieValue: "Value",
  isDonut: "Donut graph",
  customizePie: "Customize Pie Chart",
  customizeDonut: "Customize donut chart",
  startAngle: "Starting angle",
  endAngle: "End angle",
  donutSize: "Donut size",
  dataPipelines: "DataPipelines",
  graphDataPipeline: "Source-DataPipeline",
  projects: "Projects",
  example: "Show example",
  visualization: visualization
};
