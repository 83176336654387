export const journal = {
  journal: "Journal",
  articleFound: "Es wurden {stock} Artikel gefunden.",
  articleListExportInfo:
    'Um die Artikel in die Liste der zu exportierenden Artikel zu übernehmen, klicke auf "Artikelliste aufbauen". Der Aufbau der Artikelliste wird voraussichtlich bis zu 5 Minuten dauern. Die aktuelle Artikelliste kann jederzeit über das Artikel Export Journal eingesehen werden.',
  createArticleList: "Artikelliste aufbauen",
  articleListBuilding: "Die Artikelliste wird aktuell aufgebaut.",
  articleImported: "Artikel importiert.",
  articleListBuildingInfo:
    "Sobald die Artikelliste vollständig aufgebaut wurde, kannst du diese im Artikel Export Journal einsehen.",
  imported: "Artikelliste vollständig aufgebaut.",
  statusSuccess: "Export erfolgreich",
  statusError: "Export fehlgeschlagen.",
  statusDefault: "Noch nicht exportiert.",
  errorDetail: "Import für Artikel {id} fehlgeschlagen.",
  onlySuccess: "Erfolgreich exportiert",
  onlyError: "Export fehlerhaft",
  onlyNotExported: "Noch nicht exportiert",
  orderNumberImportInfoText:
    "Hier können eine oder mehrere Auftragsnummern (kommasepariert) angegeben werden.",
  orderNumberShopifyImportInfoText:
    "Hier können eine oder mehrere Auftragsnummern (kommasepariert) angegeben werden.",
  orderIdTradebyteImportInfoText:
    "Hier können eine oder mehrere Auftrag-IDs aus TB.One angegeben werden.",
  orderIdTradebyteImportPlaceHolder: "1012, 1013, ...",
  orderNumberShopifyImportPlaceHolder: "#1012, #1013, ...",
  orderNumberImportPlaceHolder: "10025, 10001, ...",
  orderIdImportInfoText:
    "Hier können eine oder mehrere Auftrags-Ids (kommasepariert) angegeben werden.",
  orderIdImportPlaceHolder: "412, 594, ...",
  from: "Seit",
  to: "Bis",
  typeNoVariants: "Keine Varianten",
  typeOnlyVariants: "Nur Varianten",
  trackingNoTracking: "Kein Tracking exportiert",
  trackingOnlyTracking: "Tracking exportiert",
  states: {
    error: "Fehlerhaft",
    incomplete: "Import ausstehend",
    imported: "Erfolgreich importiert"
  }
};
