export const formHelperFactory = {
  factory: "FormHelper Factory",
  structure: "Structure",
  configuration: "Configuration",
  data: "Data View",
  library: "Library",
  dropZoneLabel: "Drop here",
  configurationBasic: "Basic configuration",
  configurationField: "Field configuration",
  configurationAdvanced: "Advanced configuration",
  configurationForm: "Form configuration",
  json: "JSON",
  yaml: "YAML",
  copyJson: "Copy JSON",
  exportJson: "Export JSON",
  copyYaml: "Copy YAML",
  exportYaml: "Export YAML",
  load: "Load",
  saveForm: "Save form",
  selectProject: "Select saved form",
  newProjectName: "New form name",
  newProject: "New form",
  loadProject: "Load form",
  newEmptyProject: "New empty form",
  projectLoaded: "Form loaded",
  projectSaved: "Form saved",
  invalidSave: "Invalid save",
  formJson: "Form JSON",
  formYaml: "Form YAML",
  formHelperConfig: "FormHelper Configuration",
  projectRemoved: "Form removed",
  fieldTypes: {
    action: "Action",
    alert: "Alert",
    array: "Array",
    checkbox: "Checkbox",
    code: "Code",
    condition: "Condition",
    date: "Date",
    datetime: "Date time",
    file: "File",
    filter: "Filter",
    hidden: "Hidden",
    image: "Image",
    json: "JSON",
    multiSelect: "Multi select",
    number: "Number",
    password: "Password",
    range: "Range",
    select: "Select",
    text: "Text",
    textArea: "Text area",
    textEditor: "Text editor",
    time: "Time"
  }
};
