export const settings = {
  barcode: "Barcode",
  customsTariffNumber: "customs tariff number",
  ean: "EAN no./barcode",
  name: "Name",
  productAssignmentLabel:
    "How should the items from Xentral be assigned to the items in the online store?",
  productAssignmentSelectXentral: "Adopt article numbers from Xentral",
  productAssignmentSource: "Field from Xentral",
  productAssignmentTarget: "Field from {appName}",
  productnumber: "Item number",
  sku: "SKU",
  title: "Title",
  productSelection: "Product selection",
  dangerZone: "Danger zone",
  dangerZoneInfo:
    "Here you can permanently delete your integration. This means all " +
    "connected data and settings will be removed and the integration cannot be restored. ",
  deletionText:
    'Do you really want to delete the integration "{integration}"? This action is irrevocable.',
  deletionSuccess: "Integration successfully deleted",
  deletionError: "Error when deleting the integration"
};
