import ApiService from "@/core/services/api.service";
import { BaseService } from "@/components/Tools/baseService";

let url = process.env.VUE_APP_API_ADMIN;

class Assignments extends BaseService {
  get(id) {
    return ApiService.get(url, "assignments/" + id);
  }

  getAll(params, filter = {}) {
    let filterParams = this.getParams(filter, params);
    let options = new URLSearchParams(params).toString();
    return ApiService.get(url, "assignments?" + options + filterParams);
  }

  async update(id, data) {
    return ApiService.put(url + "/assignments/" + id, data);
  }

  async store(data) {
    return ApiService.post(url + "/assignments", data);
  }

  delete(id) {
    return ApiService.delete(url + "/assignments/" + id);
  }

  export(id, type) {
    return ApiService.post(url + "/export/" + type + "/assignments/" + id);
  }

  import(data) {
    // FormData needs to be sent without a content type
    ApiService.removeHeaderByKey("Content-Type");
    return ApiService.post(url + "/import", data, {}, true);
  }

  exportCsv(params) {
    return ApiService.post(url + "/assignments/export_csv", params);
  }
}

export default new Assignments();
