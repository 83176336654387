export const category = {
  categoryAssignment: "Category <> Product taxonomy assignment",
  categoryAssignmentBigcommerceHelp:
    "Here you can assign your Xentral categories to the BigCommerce categories.",
  categoryAssignmentHelp:
    'Here you can assign your Xentral categories (article tree) to the Shopify standard categories. Note that the standard categories from the Shopify product taxonomy are filled at this point. You can fill collections by activating the transfer of tags in the "Item" tab',
  categoryAssignmentSelect:
    "How should the items from Xentral be assigned to the categories in the online store?",
  mainCategoryId: "Entry point for the article tree",
  mainCategoryIdHelp:
    "Here you can select which category should be used as the entry point for your article tree.",
  merchandiseGroupToProductType: "Transfer merchandise group to product type?",
  merchandiseGroupToProductTypeHelp:
    "Here you can specify whether you want to transfer the Xentral merchandise group (item category) to the Shopify product type.",
  placeholder: "Category"
};
