export const dataStructures = {
  add: "Add",
  addField: "Add field",
  backToOverview: "Back to overview",
  baseData: "Base data",
  cancelCreate: "Cancel creation",
  checkBaseData: "Check base data",
  checkConfiguration: "Check table configuration",
  checkStructure: "Activate structure check",
  checkStructureTooltip:
    "Activate this option if the file structure is to be compared with the Data Structure during import.",
  className: "Class name",
  classNameHint: "No white-spaces, use Pascal Case (e.g. TestClass123)",
  configuration: "Configure fields",
  configure: "Configure Data Structure",
  contentIdentifier: "Content Identifier",
  create: "Create Data Structure",
  dataFields: "Data fields",
  dataStructureBasis: "Data Structure basis",
  dataStructureBasisExisting: "Existing Data Structure",
  dataStructureBasisFile: "File (XML, CSV, JSON)",
  dataStructureBasisNew: "New Data Structure",
  dataStructureBasisSelect: "Select basis",
  dataStructureBasisSelectOriginal: "Select basis",
  dataStructureBasisTemplate: "From template",
  dataStructureEdit: "Edit Data Structure",
  dataStructureImportSuccess: "Data Structure was successfully imported",
  dataStructures: "Data Structures",
  dataVersioning: "Data versioning",
  dataVersioningTooltip: "",
  default: "Default value",
  deleteText:
    'Do you really want to delete Data Structure "{name}"? This process is irreversible.',
  deleteTitle: "Delete Data Structure",
  deletedSuccess: "Data Structure deleted",
  delimiter: "Delimiter",
  description: "Description",
  edit: "Edit Data Structure",
  export: "Export Data Structure",
  emptyText: "There are no fields configured yet.",
  fieldName: "Field name",
  fields: "Fields",
  fieldsConfiguration: "Fields configuration",
  fieldsRequired: "Please add one field at least.",
  globalTemplate: "global template",
  glue: "Glue",
  importDataStructure: "Import Data Structure",
  index: "Index",
  invalidInvalidFields: "Invalid fields",
  invalidNoFields: "No fields",
  invalidNoPrimary: "No primary key",
  isLocked: "Lock",
  isLockedHint:
    "Cautiom: This Data Structure is locked. It cannot be modified until it gets unlocked by a user.",
  isLockedTooltip:
    "A locked Data Structure cannot be modified. If the Data Structure is not in current use, it can be unlocked and modified afterwards.",
  label: "Label",
  lock: "Data Structure locked",
  name: "Technical name",
  max_entries: "max. entries",
  min_entries: "min. entries",
  maxValue: "max. Values",
  minValue: "min. Values",
  nullable: "Null",
  openEditor: "Open editor",
  overview: "Overview",
  placeholderSelect: "Select",
  primary: "Primary key",
  primaryInvalid: "Invalid primary key.",
  projects: "Projects",
  properties: "Properties",
  published: "Published",
  publishedTooltip: "",
  related_data_structure: "Related Data Structure",
  reloadColumnsText:
    "Shall the current table configuration be reset and the selected Data Structure be implemented as new basis? The current fields configuration will get lost.",
  reloadColumnsTitle: "Reload table configuration?",
  required: "Required",
  saveDataStructure: "Save Data Structure",
  setContentIdentifier: "Content Identifier",
  setPrimary: "Primary",
  startWizard: "Start wizard",
  stepBasics: "Base informatiom",
  stepBasicsNotReady: "Please fill all relevant fields first.",
  stepConfig: "Configuration",
  stepConfiguration: "Configure Data Structure",
  stepConfigurationNotReady: "Please fill all relevant fields first.",
  stepSave: "Check & Save",
  storeErrorText:
    "The Data Structure could not be uploaded correctly:<br><br><code>{msg}</code>",
  storeErrorTitle: "Oops... Something went wrong!",
  storeSuccessText:
    'Data Structure "{name}" saved successfully. You now get redirected to the overview table.',
  storeSuccessTitle: "Data Structure uploaded successfully!",
  structureFields: "StructureFields",
  structuralVersioning: "Structural versioning",
  structuralVersioningTooltip: "",
  showInView: "Display as a column in the DataExplorer",
  skipOnDirtyCheck: "Ignore on delta check",
  tableName: "Table name",
  tableNameHint: "No white-spaces, use Snake Case (e.g. table_name_123)",
  title: "Data Structure",
  toolbox: "Toolbox",
  type: "Type",
  unique: "Unique",
  unsigned: "Unsigned",
  updateFieldsConfirm: "Update",
  updateFieldsText:
    "If you confirm the upload, the current fields configuration will be overwritten irreversible, which can lead to dysfunctionality in live usage. Are you sure to upload the changes?",
  updateFieldsTitle: "Update fields configuration?",
  values: "Values",
  value: "Value",
  version: "Version",
  standardSQLQueries: "Standard SQL Queries",
  standardQuery: "Standard Query",
  queryDeleted: "The Standard SQL Query has benn deleted",
  addQuery: "Add query",
  emptyTextQuery: "No SQL queries have been created.",
  sqlQuery: "SQL query",
  onEmptyData: "Handling of empty import files",
  onEmptyDataTooltip:
    "What should happen if an attempt is made to import an empty file into the Data Structure?",
  clone: "Clone",
  copy: "Copy",
  paste: "Paste",
  originalName: "Field label from file",
  originalNameHelpText:
    "This is the original name of the field from the file used to create this Data Structure. The value entered here is relevant for checking the Data Structure when importing data.",
  collapse: "Collapse",
  expand: "Expand",
  deleteEntryText:
    'Are you sure you want to delete the entry "{name}" from the Data Structure? This process is irreversible.',
  deleteEntryTitle: "Delete entry from Data Structure",
  deletedEntrySuccess: "Entry from Data Structure was successfully deleted",
  show: "Show Data Structure",
  dynamicFieldPreview: "Preview structure",
  relation_type: "Relation type",
  relation_type_single: "Single Relation",
  relation_type_multiple: "Multiple Relations",
  errorHandling: {
    warning: "Warning",
    error: "Error"
  },
  onInvalidData: "Handling of invalid import data",
  regex: "Regular expression",
  validationNotice: "Note on validation",
  validationNoticeHelp:
    "This field is required to display a meaningful error message if the value does not match the regex pattern."
};
