export const partner = {
  accounting: "Accounting",
  accountingHasVat: "I got a VAT",
  accountingInvoicesEmail: "Email for invoices",
  accountingInvoicesSendEmail: "I want to receive invoices by mail",
  accountingNoVat: "I'm a small business",
  accountingVatNumber: "VAT",
  active: "Active",
  assignedPartnerLabel: "Responsible partner for this client",
  apiToken: "API Token",
  baseData: "Base data",
  billing: "Billing adress",
  billingAddress1: "Address line 1",
  billingAddress2: "Address line 2",
  billingAddress3: "Address line 3",
  billingCity: "City",
  billingCountry: "Country",
  billingStreet: "Street, Number",
  billingZipcode: "Zip code",
  cannotCreate: "Partner cannot be created",
  companyData: "Company data",
  cropProfilePicture: "Crop profile picture",
  connection: "Connection",
  contactData: "Contact data",
  contactEmail: "Email address",
  contactFax: "Fax",
  contactPerson: "Contact person",
  contactPersonYedi: "Responsible employee from YEDI",
  contactPersonPartner: "Responsible employee from partner",
  contactPhone: "Phone",
  contactWebsite: "Website",
  id: "ID",
  informationPartner: "Partner information",
  informationPartnerDescription: "Update partner information",
  initialized: "Initialized",
  language: "Language",
  logo: "Logo",
  name: "Name",
  nameEnter: "Enter name",
  nameShort: "Abbreviation",
  number: "Partner number",
  partnerCreate: "Create partner",
  partnerCreated: "Partner created successfully",
  partnerDeleteMessage:
    'Do you really want to delete the partner "{partner}"? This process is irreversible.',
  partnerDeleteTitle: "Delete partner",
  partnerDeleted: "Partner deleted successfully",
  partnerNotCreated: "Partner not created",
  partnerUpdate: "Update partner",
  partnerUpdated: "Partner has been updated successfully",
  partner: "Partner",
  partners: "Partners",
  password: "Password",
  passwordConfirmation: "Password confirmation",
  settings: "Settings",
  storage: "Storage",
  storageChange: "Update Storage",
  storageData: "Storage data",
  upload: "Upload",
  uuid: "UUID",
  variables: "Variables",
  cancelCreate: "Cancel partner creation",
  stepCreateDatabase: "Create database",
  stepAddPartnerEntry: "Add partner",
  stepInitializeMongo: "Initialize MongoDB",
  stepTenantStorage: "Create partner storage",
  stepTenantConnections: "Assign partner connections",
  stepFtpUser: "Add FTP user",
  stepInitializeTenant: "Initialize partner",
  stepActivateQueues: "Activate queues",
  stepServiceDaemons: "Restart service daemon",
  stepCreateDatabaseIntro:
    "At first a new MySQL database has to be created in Profihost ServerCon. Fill the input field with the generated login data and leave a comment like:<br><br><span class='bg-light'>Partner DB<br>PARTNER NAME</span>",
  stepCreateDatabaseLink: "Link to ServerCon:",
  stepCreateDatabaseLoginData: "Login data",
  databaseName: "Database name",
  databaseUsername: "Username",
  databasePassword: "Password",
  partnerName: "Partner name",
  stepAddPartnerEntryIntro:
    'At the second step a new partner entry has to be added to the master database. Please check the provided partner name and confirm the creation by clicking  "Create partner".',
  addPartnerFirst: "Please create a partner first",
  addPartner: "Create partner",
  addPartnerSuccessTitle: "Partner created successfully!",
  addPartnerSuccessMessage:
    'Partner "{name}" was created successfully. Proceed to the next step.',
  addPartnerErrorTitle: "Oops! Something went wrong...",
  addPartnerErrorMessage:
    'Partner "{name}" could not be created. Please try again later.',
  stepTenantStorageIntro:
    "At this step a new storage needs to be created. Connect to the server via SSH and execute following command:",
  stepTenantStorageCommand: "Command",
  stepTenantStorageStorageCreated: "Storage created",
  stepTenantConnectionsIntro:
    "Now the database connections get assigned to the partner. First, add the MySQL connection, then the MongoDB connection. Please check the login data and confirm the assignments.",
  stepTenantConnectionsMySql: "MySQL connection",
  stepTenantConnectionsMongo: "MongoDB connection",
  stepTenantConnectionsAssign: "Assign connection",
  stepTenantConnectionsSuccess: "Connection assigned successfully!",
  stepTenantConnectionsError:
    "Connection could not be assigned. Please try again later.",
  stepTenantConnectionsWarning: "Please assign the connections first",
  stepInitializeMongoDone: "MongoDB initialized",
  stepInitializeMongoIntro:
    "As third step a new Mongo user needs to be created. Connect to the server via SSH and execute following commands in order:",
  stepFtpUserIntro:
    "Next a new FTP user has to be added in Profihost ServerCon. Following path should be provided as root path:",
  stepFtpUserRoot: "Root path",
  stepFtpUserLink: "Link to ServerCon:",
  stepFtpUserDone: "FTP user added",
  stepInitializeTenantSuccess: "Partner initialized successfully!",
  stepInitializeTenantError:
    "Partner could not be initialized. Please try again later.",
  stepInitializeTenantIntro:
    "The partner now needs to be initialized. Initialization starts by clicking following button.",
  stepInitializeTenantConfirm: "Initialize partner",
  stepInitializeTenantWarning: "Please initialize partner first",
  stepActivateQueuesIntro:
    "Now the queues can be activated at will. After that, proceed to the next step.",
  queueInstance: "Instance",
  queueInstances: "Instances",
  activate: "Activate",
  deactivate: "Deactivate",
  stepServiceDaemonsIntro:
    "In the end the service daemon for the queue worker needs to be restarted. Please restart the daemon (name: YEDI Queue) in Profihost ServerCon. After that, the partner creation process can be finished.",
  stepServiceDaemonsDone: "Restarted daemon",
  stepServiceDaemonsLink: "Link to ServerCon:",
  wizardFinish: "Finish",
  stepServiceDaemonsWarning: "Please restart daemon first",
  databases: "Databases",
  databaseHost: "Host",
  databasePort: "Port",
  storageType: "Storage type",
  salutation: "Salutation",
  clientWorkspaceUpdateError: "Client workspace could not be updated",
  workspaceDomainExists: "Domain already exists",
  workspaceAdd: "Add domain",
  mail: "Email address",
  mailEnter: "Enter email address",
  user: "User",
  updateBaseDataAndProfilePicture: "Update base data and profile picture",
  profilePicture: "Profile picture",
  removeProfilePicture: "Remove profile picture",
  uploadProfilePicture: "Upload profile picture",
  enterSalutation: "Please enter the form of salute",
  enterTitle: "Please enter the title",
  title: "Title",
  firstName: "Firstname",
  enterFirstName: "Please enter the user first name",
  lastName: "Lastname",
  enterLastName: "Please enter the user last name",
  email: "E-Mail",
  enterEmail: "Please enter the user email address",
  passwordChangeDescription: "Change password for account",
  passwordChangeTitle: "Change password",
  passwordCurrentRequired: "Current password required",
  passwordNew: "New password",
  passwordNewConfirm: "Confirm new password",
  passwordNewConfirmRequired: "Confirmation of new password is required",
  passwordNewRequired: "New password required",
  passwordNotMatching: "Given passwords do not match.",
  dataProvideCorrectly: "Please provide correct data!",
  profile: "Profile",
  reviewAndSubmit: "Review and submit",
  loginSecurity: "Login Security"
};
