export const watchDog = {
  active: "Active",
  authentication: "Authentication",
  configuration: "Configuration",
  configurations: {
    noImportOrders: "Non-imported orders",
    uncompletedOrders: "Uncompleted orders"
  },
  defaultNotification:
    "No preview available, for more information check the process iteration.",
  errorPercentage: {
    hint: "Example: 10% of all incoming orders fail: Error",
    info: "Percentage of orders not imported to Xentral to generate an error",
    label: "Percentage for creating an error"
  },
  form: {
    active: "Active"
  },
  interval: {
    label: "Rhythm to check",
    options: {
      everyHour: "Every hour",
      oncePerDay: "Once a day",
      twicePerDay: "Twice a day"
    }
  },
  loadProjects: "Update projects",
  noAuthAvailable: "No authentication data available.",
  noNotifications: "No notifications available.",
  noXentralProjectId:
    "Oops, something went wrong! Please contact the responsible support.",
  shopLinked: "Update successfully",
  shopLinkedError: "Update failed",
  titles: {
    salesOrders: "Sales orders"
  },
  watchdog: "Watchdog-App",
  warningPercentage: {
    hint: "Example: 5% of all incoming orders fail: Warning",
    info: "Percentage of orders not imported to Xentral to generate a warning",
    label: "Percentage for creating a warning"
  },
  amazon: {
    sellerId: "Seller ID",
    awsAccessKeyId: "AWS Access Key ID",
    awsSecretKey: "AWS Secret Key",
    sellingPartnerApiRefreshToken: "Selling partner API refresh token",
    sellingPartnerApiClientId: "Selling partner API client ID",
    sellingPartnerApiClientSecret: "Selling partner API client secret",
    sellingPartnerApiAuthUrl: "Selling partner API authentication URL",
    sellingPartnerApiEndpointUrl: "Selling partner API endpoint URL"
  },
  shopware: {
    apiKey: "API key",
    apiUser: "API user",
    apiUrl: "API url"
  },
  shopware6: {
    shopwareUrl: "URL",
    shopwareUserName: "Username",
    shopwarePassword: "Password"
  },
  shopify: {
    shopifyUrl: "URL",
    shopifyAPIKey: "API key",
    shopifyPassword: "Password",
    shopifyToken: "Token"
  },
  woocommerce: {
    apiKey: "API key",
    apiUser: "API user",
    apiUrl: "API url"
  }
};
