import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import {
  UPDATE_LANGUAGE,
  UPDATE_PERSONAL_INFO,
  UPDATE_USER_DATA
} from "@/core/services/store/profile.module";
import { bus } from "@/main";

let url = process.env.VUE_APP_API_ADMIN;

class Auth {
  async login(authData, path = "/auth/login", resetUser = true) {
    if (resetUser) {
      this.resetUser();
      ApiService.setHeader();
    }
    let url = process.env.VUE_APP_API_ADMIN;

    let returnData = {
      success: false,
      data: ""
    };

    await ApiService.post(url + path, authData)
      .then(response => {
        returnData.success = true;
        returnData.data = response.data;
        returnData.meta = response.meta;
      })
      .catch(error => {
        returnData = {
          success: false,
          data: JSON.parse(error)
        };
      });

    if (returnData.success) {
      JwtService.saveToken(returnData.meta.token);
      ApiService.setHeader();
      await store.dispatch(UPDATE_LANGUAGE, returnData.data.language.short);
      await store.dispatch(UPDATE_USER_DATA, returnData.data);
      this.updateUserData(returnData.data);
    }
    return returnData;
  }

  async appLogin(authData, accessToken) {
    this.resetUser();
    ApiService.setHeader();
    ApiService.setHeaderByKey("Authorization", "Bearer " + accessToken);
    return this.login(authData, "/apps/login", false);
  }

  verify() {
    let returnData = {
      success: false,
      user: "",
      token: ""
    };
    if (JwtService.getToken()) {
      returnData.token = JwtService.getToken();
      returnData.success = true;
    } else {
      JwtService.destroyToken();
    }
    this.updateUserData();
    return returnData;
  }

  logout() {
    this.resetUser();
    JwtService.destroyToken();
  }

  resetUser() {
    // reset user data
    localStorage.removeItem("selectedProjectData");
    ApiService.removeAllHeaders();
  }

  updateUserData(userData) {
    if (typeof userData === "undefined") {
      return;
    }

    userData.name = userData.core_data?.firstname ?? userData.name;
    userData.surname = userData.core_data?.lastname ?? "";
    userData.profile_picture =
      userData.profile_picture ?? "/media/users/blank.png";

    store.dispatch(UPDATE_PERSONAL_INFO, userData);
    bus.$emit("loggedIn", userData);
  }

  async twoFaCheck(authData) {
    return this.login(authData);
  }

  sendPasswordResetLink(data) {
    return ApiService.post(url + "/auth/password/email", data);
  }

  resetPassword(data) {
    return ApiService.post(url + "/auth/password/reset", data);
  }
}

export default new Auth();
