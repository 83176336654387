export const presets = {
  presets: "Preset values",
  presetCreate: "Create preset value",
  presetEdit: "Edit preset value",
  name: "Technical name",
  label: "Label",
  type: "Type",
  key: "Key",
  value: "Value",
  presetCreated: "Preset value created",
  presetUpdate: "Update preset value",
  presetUpdated: "Preset value updated",
  presetDeleteMessage:
    'Do you really want to delete the preset value "{preset}"? This process is irreversible.',
  presetDeleteTitle: "Delete preset value?",
  presetDeleted: "Preset value deleted successfully"
};
