export const reporting = {
  categorySync: "Synchronise categories",
  categorySyncDescription:
    'By clicking on "Start category synchronisation", you trigger the transfer of all categories from Xentral to the sales channel once. The settings that you have configured in the "Workflows" area in the "Category" tab are used for this',
  categorySyncFinished: "Category synchronisation started",
  categorySyncStart: "Start category synchronisation",
  executeCategorySync: "Should category synchronisation be started?",
  comment: "Comment",
  destination: "Destination",
  detail: "Overview of processed article data",
  entities: {
    default: "Element",
    product: "Base data",
    salesOrder: "Order",
    stock: "Stock",
    prices: "Price",
    categories: "Categories",
    customFields: "Free fields",
    properties: "Properties",
    crossSeller: "Cross-selling articles",
    media: "Images",
    lastRun: "Last Run",
    orderDate: "Order date",
    tracking: "Tracking"
  },
  entity: "data type",
  error: "An error has occurred.",
  executed: {
    salesOrder: "Sales order is imported",
    tracking: "Order status & tracking is imported"
  },
  executeMigrationWorkflow: "Execute {action} with this item.",
  export: "Export from shop",
  finish: "Finish migration",
  firstIteration: "First run",
  id: "ID",
  id_dynamic: "Dynamic order ID",
  import: "Import to Xentral",
  lastIteration: "Last run",
  last_read: "Last prepared for export",
  last_write: "Last exported to the shop",
  legend: {
    process: {
      pending: "Pending",
      working: "In progress",
      stopped: "Successful",
      error: "Error"
    }
  },
  migrationExportNotFinished:
    "Item data still needs to be exported from the sales channel.",
  migrationImportNotFinished:
    "Item data still needs to be imported into Xentral.",
  migrationSingleStart: "Start for single product",
  migrationStart: "Start for all products",
  noStatusFound: {
    last_read: "This data type has not yet been prepared for export.",
    last_write: "This data type has not yet been exported to the sales channel."
  },
  open: "Open Reporting",
  orderNumber: "Order number",
  orderId: "Order id",
  orderDate: "Order date",
  pricesSync: "Synchronise prices",
  pricesSyncDescription:
    'Click on "Start price synchronisation" to trigger a test transfer of the price of the selected item once from Xentral to the sales channel. The settings that you have configured in the "Workflows" area in the "Prices" tab will be used',
  pricesSyncDescriptionBatch:
    'Click on "Start price synchronisation" to trigger a test transfer of the prices of the selected items once from Xentral to the sales channel. The settings that you have configured in the "Workflows" area in the "Prices" tab will be used',
  pricesSyncFinished: "Price synchronisation successfully completed",
  pricesSyncStart: "Start price synchronisation",
  process: {
    aborted: {
      last_read: "The preparation for the export was cancelled",
      last_write: "The export to the sales channel was cancelled."
    },
    error: {
      last_read: "An error occurred while preparing the export.",
      last_write: "An error occurred while exporting to the sales channel."
    },
    pending: {
      last_read:
        "The preparation for the export of this data type is imminent.",
      last_write:
        "The export to the sales channel for this data type is imminent."
    },
    started: {
      last_read: "The preparation for the export has been started.",
      last_write: "The export to the sales channel has been started."
    },
    stopped: {
      last_read: "The data type was successfully prepared for export.",
      last_write:
        "The data type was successfully exported to the sales channel."
    },
    waiting: {
      last_read: "This data type is currently being prepared for export.",
      last_write:
        "This data type is currently being exported to the sales channel."
    },
    warning: {
      last_read:
        "The data type was partially successfully prepared for export.",
      last_write:
        "The data type was partially successfully exported to the sales channel."
    },
    working: {
      last_read: "This data type is currently being prepared for export.",
      last_write:
        "This data type is currently being exported to the sales channel."
    }
  },
  product: "Article reporting",
  productId: "Article ID",
  productNumber: "Item number",
  productSync: "Synchronise article/variant",
  productSyncDescription:
    'By clicking on "Start item synchronisation", you trigger the transfer of the selected item once from Xentral to the sales channel on a test basis. The settings that you have configured in the "Workflows" area in the "Item" tab will be used',
  productSyncDescriptionBatch:
    'By clicking on "Start item synchronisation", you trigger the transfer of the selected items once from Xentral to the sales channel on a test basis. The settings that you have configured in the "Workflows" area in the "Item" tab will be used',
  productSyncFinished: "Item synchronisation started",
  productSyncStart: "Start article synchronisation",
  settings: "Settings",
  showDetail: "Show history",
  source: "Source",
  state: "Last status",
  states: {
    imported: "{entity} imported into Xentral",
    importStarted: "Import of {entity} pending",
    exportStarted: "{entity} recognised in the shop",
    exported: "{entity} imported to Connect",
    stateExportStarted: "Export of status started",
    statePartiallyExported: "Status partially exported",
    stateExported: "Export of the status was successful",
    stateImportStarted: "Status import started",
    statePartiallyImported: "Status partially imported",
    stateImported: "Import of the status was successful",
    noStateUpdate: "No status update has been carried out yet",
    importFailed: "The import of the {entity} has failed.",
    exportFailed: "The export of the {entity} to Connect has failed",
    salesOrder: {
      stateExportStarted: "Export of the status started",
      statePartiallyExported: "Status update pending",
      trackingExportCheck: "Status update pending",
      stateExportAborted: "Status update pending",
      stateExported: "Status update pending",
      stateImportStarted: "Status update pending",
      statePartiallyImported:
        "Order status 'partially shipped' exported to the shop",
      imported: "Job imported into Xentral",
      importStarted: "Import of the order pending",
      exportStarted: "Order recognised in the shop",
      exported: "Order imported to Connect",
      importFailed: "The import of the order has failed.",
      exportFailed: "The export of the order to Connect has failed",
      stateImported: "Job status imported into Xentral",
      stateImportFailed: "The import of the order status has failed",
      stateExportFailed: "The export of the order status to Connect has failed",
      noStateUpdate: "No status update has been performed yet"
    },
    product: {
      imported: "Product imported into Xentral",
      importStarted: "Import of products pending",
      exportStarted: "Product recognised in the shop",
      exported: "Product imported to Connect",
      importFailed: "The import of the products failed.",
      exportFailed: "The export of the products to Connect has failed"
    }
  },
  isVariant: "Is variant",
  trackingExported: "Tracking exported",
  requeueJob: "Execute again",
  statusTableTitle: "Status of the last",
  statusTableTitleRange: "Status from {firstDate} to {lastDate}",
  stockSync: "Synchronise stock",
  stockSyncDescription:
    'Click on "Start stock synchronisation" to trigger a test transfer of the stock of the selected item once from Xentral to the sales channel. The settings that you configured in the "Workflows" area in the "Stock" tab will be used',
  stockSyncDescriptionBatch:
    'Click on "Start stock synchronisation" to trigger a test transfer of the stocks of the selected items once from Xentral to the sales channel. The settings that you configured in the "Workflows" area in the "Stock" tab will be used',
  stockSyncFinished: "Stock synchronisation successfully completed",
  stockSyncStart: "Start stock synchronisation",
  syncPrices: "Synchronise prices",
  syncProducts: "Synchronise products",
  syncStocks: "Synchronise stocks",
  timeRange: {
    days: {
      1: "24 hours",
      2: "48 hours",
      7: "7 days",
      14: "14 days",
      0: "Own date range"
    }
  },
  title: "Reporting {featureName}",
  totalIterations: "Number of runs",
  updated_at: "Update date",
  processIterationDeletionNotice:
    "Please note that error messages expire after 14 days.",
  trackingAdded: "Tracking",
  offerSync: "Synchronise offers",
  syncOffers: "Synchronise offers",
  offerSyncDescriptionBatch:
    'Click on "Start offer synchronisation" to trigger a test transfer of the stocks and prices of the selected items once from Xentral to the sales channel. The settings that you configured in the "Workflows" area in the "Offer" tab will be used.',
  offerSyncStart: "Start offer synchronisation",
  offerSyncFinished: "Offer synchronisation successfully completed"
};
