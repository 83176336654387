import Vue from "vue";
import Swal from "sweetalert2";
import i18n from "@/core/plugins/vue-i18n";

Vue.prototype.$swal = Swal;

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  showCloseButton: true,
  didOpen: toast => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  }
});

const Error = function (error) {
  // Do not show error when request was aborted
  if (error === "abort") return;

  let text;

  // Handle object/string error
  try {
    error = JSON.parse(error);
    text = error?.message;
  } catch {
    text = error;
  }

  Swal.fire({
    icon: "error",
    title: i18n.t("general.caution"),
    text: text ?? i18n.t("error.default"),
    confirmButtonColor: "var(--secondary)"
  });
};

const Confirmation = function (params) {
  return Swal.fire({
    icon: params.icon ?? "warning",
    title: params.title,
    text: params.text,
    showCancelButton: true,
    reverseButtons: true,
    cancelButtonText: params.cancelButtonText ?? i18n.t("general.cancel"),
    confirmButtonText: params.confirmButtonText ?? i18n.t("general.confirm"),
    confirmButtonColor: "#5b64ee"
  });
};

Vue.prototype.$toast = Toast;
Vue.prototype.$error = Error;
Vue.prototype.$confirmation = Confirmation;

export { Toast, Error, Confirmation };
