export const presets = {
  presets: "Preset Konfigurationswerte",
  presetCreate: "Preset Konfigurationswert erstellen",
  presetEdit: "Preset Konfigurationswert bearbeiten",
  name: "Technischer Name",
  label: "Label",
  type: "Typ",
  key: "Key",
  value: "Value",
  presetCreated: "Preset Konfigurationswert erstellt",
  presetUpdate: "Preset Konfigurationswert aktualisieren",
  presetUpdated: "Preset Konfigurationswert aktualisiert",
  presetDeleteMessage:
    'Soll der Preset Konfigurationswert "{preset}" wirklich gelöscht werden? Dieser Vorgang ist irreversibel.',
  presetDeleteTitle: "Preset Konfigurationswert löschen?",
  presetDeleted: "Preset Konfigurationswert erfolgreich gelöscht"
};
