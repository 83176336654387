import ApiService from "@/core/services/api.service";
import { BaseService } from "@/components/Tools/baseService";
import _ from "lodash";
const url = process.env.VUE_APP_API_ADMIN;

class DataOptions extends BaseService {
  get = _.memoize(
    function (params, disableAbort = true) {
      return ApiService.post(
        url + "/data_options/",
        params,
        {},
        false,
        disableAbort
      );
    },
    function (params) {
      return this.getCacheKey(params);
    }
  );

  clearCache = (params = null) => {
    if (!params) {
      this.get.cache.clear();

      return;
    }

    const key = this.getCacheKey(params);
    this.get.cache.delete(key);
  };
}

export default new DataOptions();
