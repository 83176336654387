export const processManager = {
  processManager: "Prozessmanager",
  name: "Name",
  deleteConfirm: 'Prozess "{process}" löschen ?',
  deleteConfirmText:
    'Möchten Sie den Prozess "{process}" wirklich löschen? Sie können dies nach dem Löschen nicht wiederherstellen.',
  deleteSucessText: "Prozess {prozess} wurde gelöscht",
  editProcess: "Prozess bearbeiten",
  nameEnter: "Namen eingeben",
  className: "Klassenname",
  frequency: "Häufigkeit",
  leadTime: "Durchlaufzeit",
  externallyTriggerable: "Extern auslösbar",
  externalTrigger: "Externer Trigger",
  lastRunned: "Zuletzt gelaufen",
  lastExecutedOn: "Zuletzt ausgeführt am",
  clickToggleMoreDetails: "Klicken um weitere Details umzuschalten",
  baseClassPath: "Basisklassen Pfad",
  processUpdated: "Prozess aktualisiert",
  processProcUpdated: 'Prozess "{process}" wurde aktualisiert',
  temporaryClassName: "Temporary class name",
  done: "Erledigt",
  processCreate: "Prozess erstellen",
  processCreated: "Prozess erstellt",
  jsonAsParameter: "Hier kann JSON als Parameter mitgegeben werden",
  test: "Testen",
  processTestStarted: "Prozesstest gestartet",
  bulkDeleteProcessIdJobs:
    "Möchten Sie alle Jobs des Prozesses '{process}' die aktuell auf der Queue liegen abbrechen?",
  queue: "Warteschlange",
  deleteProcessTooltip: "Jobs dieses Prozesses abbrechen",
  runProcessTooltip: "Prozess starten",
  testProcessTooltip: "Prozess Testen",
  processStarted: "Prozess gestartet",
  yesCancel: "Abbruch Bestätigen",
  noCancel: "Abbruch Stornieren",
  requeue: "Job erneut auf Queue legen",
  resumeJob: "Fortsetzten",
  jobResumed: "Job wurde erfolgreich fortgesetzt!",
  jobCancel: "Job abbrechen",
  jobRequeued: "Job wurde erfolgreich zurück auf die Queue gelegt!",
  invalidJson: "Invalides JSON",
  testWorkflow: 'Teste Workflow "{name}"',
  startWorkflow: 'Starte Workflow "{name}"',
  testProcessNoName: "Teste Prozess",
  immediateProcess: "Sofortiger Prozess",
  copyAboveCodeToActualCode:
    "Kopieren Sie den obigen Code und fügen Sie ihn in den eigentlichen Code ein",
  openReporting: "Reporting öffnen",
  requeueSelected: "Ausgewählte Jobs erneut auf die Queue legen"
};
