import Vue from "vue";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
import _ from "lodash";
import { generateHash } from "@/components/Tools/helperFunctions";

let url = process.env.VUE_APP_API_ADMIN;

export const LOAD_CONFIG_VALUES = "loadConfigValues";
export const ADD_CUSTOM_SET = "addCustomSet";
export const UPDATE_CUSTOM_SET = "updateCustomSet";
export const REMOVE_CUSTOM_SET = "removeCustomSet";
export const ADD_CUSTOM_VARIABLES = "addCustomVariables";
export const REMOVE_CUSTOM_VARIABLES = "removeCustomVariables";
export const REMOVE_ALL_CUSTOM_VARIABLES = "removeAllCustomVariables";
export const UPDATE_CUSTOM_VARIABLE = "updateCustomVariable";

function hash() {
  return generateHash(10, "var-");
}

function normalizeVariables(payload) {
  // Get data from payload
  const { json, text, value, variables } = payload;
  // Get text key and value key with fallback values
  let textKey = text ?? "text",
    valueKey = value ?? "value";
  // Create object to store normalized variables
  let variablesNormalized = {};
  // If variables are in json format
  if (json) {
    // Loop through first level keys
    Object.keys(variables).forEach(key => {
      let text = String(key);
      let value = {};
      // Skip variable if no text is set
      if (text === "") {
        return;
      }
      // If text includes dot notation, split up
      if (text.includes(".")) {
        let path = text.split(".");
        // Use first part of split key as text
        text = path.shift();
        _.set(value, path, variables[key]);
      } else {
        value = variables[key];
      }
      // Set variable with id as key, json-key as text and json-value as value
      variablesNormalized[hash()] = {
        text: text,
        value: value
      };
    });
  } else {
    // Else if variables are an array of object
    // Loop through variables
    variables.forEach(variable => {
      let text = String(variable[textKey]);
      let value = {};
      // Skip variable if no text is set
      if (text === "") {
        return;
      }
      // If text includes dot notation, split up
      if (text.includes(".")) {
        let path = text.split(".");
        // Use first part of split key as text
        text = path.shift();
        _.set(value, path, variable[valueKey]);
      } else {
        value = variable[valueKey];
      }
      // Set normalized variable with text and value from given keys
      variablesNormalized[variable.id ?? hash()] = {
        text: text,
        value: value
      };
    });
  }
  Object.keys(variablesNormalized).forEach(id => {
    let variable = variablesNormalized[id];
    // Unwind nested dot notations
    variable.value = unwindDotNotations(variable.value);
  });
  // Return normalized variables
  return variablesNormalized;
}

function unwindDotNotations(value) {
  // If value is array or not of type object, return value
  if (value === null || typeof value !== "object" || Array.isArray(value)) {
    return value;
  }
  let newValue = {};
  Object.keys(value).forEach(key => {
    let text = key,
      subValue = {};
    if (String(text).includes(".")) {
      let path = String(text).split(".");
      // Use first part of split key as text
      text = path.shift();
      // Set unwind value by path
      _.set(subValue, path, unwindDotNotations(value[key]));
    } else {
      // Set unwind value by path
      subValue = unwindDotNotations(value[key]);
    }
    // Set value with updated key in new value object
    newValue[text] = subValue;
  });
  return newValue;
}

// IMPORTANT: Variables have to be normalized first
function flattenVariables(variables) {
  let flatten = [];
  // Loop through root level variables
  Object.keys(variables).forEach(id => {
    let variable = variables[id];
    // Add text
    flatten.push(variable.text);
    // If value is of type object, get value's texts
    if (typeof variable.value === "object" && variable.value !== null) {
      let childrenTexts = getChildrenTexts(variable.value);
      // Prefix texts with variable's text
      childrenTexts.forEach(text => {
        flatten.push(variable.text + "." + text);
      });
    }
  });
  // Return texts
  return flatten;
}

function getChildrenTexts(children) {
  // New array to store texts
  let texts = [];
  // Loop through children's keys
  Object.keys(children).forEach(key => {
    // Add key to texts
    texts.push(key);
    // If child also has an object as value, repeat
    if (typeof children[key] === "object" && children[key] !== null) {
      let childrenTexts = getChildrenTexts(children[key]);
      childrenTexts.forEach(text => {
        texts.push(key + "." + text);
      });
    }
  });
  // Return texts
  return texts;
}

export default {
  namespaced: true,
  state: {
    configValues: {
      variables: [],
      variablesNormalized: [],
      variablesFlat: []
    },
    custom: {}
  },
  getters: {
    configValues: state => {
      return state.configValues.variables;
    },
    configValuesNormalized: state => {
      return state.configValues.variablesNormalized;
    },
    configValuesFlat: state => {
      return state.configValues.variablesFlat;
    },
    configValueById: state => id => {
      return state.configValues.variables.find(cv => cv.id === id);
    },
    configValueByName: state => name => {
      return state.configValues.variables.find(cv => cv.name === name);
    },
    customVariablesSet: (state, getters) => name => {
      let set = JSON.parse(JSON.stringify(state.custom[name] ?? {}));
      set.variables = getters.customVariables(name);
      return set;
    },
    customVariables: state => setName => {
      // Transform variables object to array with id inside entry
      let variables = [],
        obj = state.custom[setName] ?? {};
      Object.keys(obj).forEach(id => {
        variables.push({
          text: obj[id].text,
          value: obj[id].value,
          id: id
        });
      });
      return variables;
    }
  },
  actions: {
    async [LOAD_CONFIG_VALUES](context) {
      return await ApiService.get(url, "config_values?noPagination=true", {
        headers: {
          "x-api-key": store.getters.apiToken
        }
      }).then(response => {
        context.commit(LOAD_CONFIG_VALUES, response.data);
      });
    },
    /**
     * Add a new set of custom variables
     * @param context
     * @param {Object} payload
     * @param {String} payload.name Technical name of set
     * @param {String} payload.prefix Prefix to be set in front of selected variable
     * @param {Boolean} [payload.json=false] Determine if given variables input is in json format
     * @param {String} [payload.text="text"] Key of variable object to be displayed as text
     * @param {String} [payload.value="value"] Key of variable object to be handled as value
     * @param {Boolean} [payload.showValue=false] Determine if value of variable should be displayed in tooltip
     * @param {Array, Object} [payload.variables=null] Actual variables either as Array or JSON with key-value-pairs (option json=true)
     */
    [ADD_CUSTOM_SET](context, payload) {
      context.commit(ADD_CUSTOM_SET, payload);
    },
    /**
     * Remove a set of custom variables
     * @param context
     * @param {String} payload Name of set to be deleted
     */
    [REMOVE_CUSTOM_SET](context, payload) {
      context.commit(REMOVE_CUSTOM_SET, payload);
    },
    /**
     * Update options of an existing set
     * @param context
     * @param payload
     * @param {String} payload.name Name of set to be updated
     * @param {Object} payload.set New set data
     */
    [UPDATE_CUSTOM_SET](context, payload) {
      context.commit(UPDATE_CUSTOM_SET, payload);
    },
    /**
     * Add new variables to a set
     * @param context
     * @param payload
     * @param {String} payload.set Set name
     * @param {Array, Object} payload.variables New variables
     */
    [ADD_CUSTOM_VARIABLES](context, payload) {
      context.commit(ADD_CUSTOM_VARIABLES, payload);
    },
    /**
     * Remove variables from a set
     * @param context
     * @param payload
     * @param {String} payload.set Set name
     * @param {Array|String|Integer} payload.ids IDs of variables to be removed
     */
    [REMOVE_CUSTOM_VARIABLES](context, payload) {
      context.commit(REMOVE_CUSTOM_VARIABLES, payload);
    },
    /**
     * Remove all variables from a set
     * @param context
     * @param {String} payload Set name
     */
    [REMOVE_ALL_CUSTOM_VARIABLES](context, payload) {
      context.commit(REMOVE_ALL_CUSTOM_VARIABLES, payload);
    },
    /**
     * Update a variable from a set
     * @param context
     * @param payload
     * @param {String} payload.set Set name
     * @param {String, Integer} payload.id ID of variable to be updated
     * @param {Object} payload.variable New variable data
     * @param {String|Integer} payload.variable.text New variable text
     * @param {any} payload.variable.value New variable value
     */
    [UPDATE_CUSTOM_VARIABLE](context, payload) {
      context.commit(UPDATE_CUSTOM_VARIABLE, payload);
    }
  },
  mutations: {
    [LOAD_CONFIG_VALUES](state, payload) {
      const normalizePayload = {
        text: "name",
        variables: payload
      };
      let normalized = normalizeVariables(normalizePayload);
      let flat = flattenVariables(normalized);
      state.configValues = {
        variables: payload,
        variablesNormalized: normalized,
        variablesFlat: flat
      };
    },
    [ADD_CUSTOM_SET](state, payload) {
      // Define set data
      const data = {
        json: payload.json ?? false,
        name: payload.name,
        prefix: payload.prefix,
        showValue: payload.showValue ?? false,
        text: payload.text ?? "text",
        value: payload.value ?? "value",
        variables: {},
        variablesFlat: []
      };
      // Add set to store
      Vue.set(state.custom, payload.name, data);
      // If initially variables are given, add them to set
      if (!_.isEmpty(payload.variables)) {
        // Define payload for adding variables
        const variablesPayload = {
          set: payload.name,
          variables: payload.variables
        };
        // Call mutation
        this.commit(ADD_CUSTOM_VARIABLES, variablesPayload);
      }
    },
    [REMOVE_CUSTOM_SET](state, payload) {
      Vue.delete(state.custom, payload);
    },
    [UPDATE_CUSTOM_SET](state, payload) {
      // Get props allowed to be updated
      let props = (({ json, name, prefix, showValue, text, value }) => ({
        json,
        name,
        prefix,
        showValue,
        text,
        value
      }))(payload.set);
      // Filter undefined keys
      Object.keys(props).forEach(key => {
        if (props[key] === undefined) {
          delete props[key];
        }
      });
      // Overwrite keys to be updated
      Object.assign(state.custom[payload.name], props);
    },
    [ADD_CUSTOM_VARIABLES](state, payload) {
      const set = { ...state.custom[payload.set] };
      const data = {
        json: set.json,
        text: set.text,
        value: set.value,
        variables: payload.variables
      };
      // Update variables in set
      Object.assign(set.variables, normalizeVariables(data));
      // Update flatten variables in set
      set.variablesFlat = flattenVariables(set.variables);
      // Update set in store
      Vue.set(state.custom, payload.set, set);
    },
    [REMOVE_CUSTOM_VARIABLES](state, payload) {
      let ids = [...payload.ids];
      let set = JSON.parse(JSON.stringify(state.custom[payload.set]));
      // Return if no set was found
      if (!set) {
        return;
      }
      // Loop through ids to delete
      ids.forEach(id => {
        // Skip if id doesn't exist
        if (!Object.hasOwn(set.variables, id)) {
          return;
        }
        delete set.variables[id];
      });
      // Update flatten variables in set
      set.variablesFlat = flattenVariables(set.variables);
      // Update set in store
      Vue.set(state.custom, payload.set, set);
    },
    [REMOVE_ALL_CUSTOM_VARIABLES](state, payload) {
      let set = JSON.parse(JSON.stringify(state.custom[payload]));
      // Return if no set was found
      if (!set) {
        return;
      }
      set.variables = {};
      set.variablesFlat = [];
      // Update set in store
      Vue.set(state.custom, payload, set);
    },
    [UPDATE_CUSTOM_VARIABLE](state, payload) {
      let set = JSON.parse(JSON.stringify(state.custom[payload.set]));
      // Return if no set was found
      if (!set) {
        return;
      }
      let variable = set.variables[payload.id];
      // Return if no variable was found
      if (!variable) {
        return;
      }
      // If variable has no text, remove it
      if (payload.data[set.text] === "") {
        this.commit(REMOVE_CUSTOM_VARIABLES, {
          set: payload.set,
          ids: payload.id
        });
        return;
      }
      variable.text = payload.data[set.text];
      variable.value = payload.data[set.value];
      // Update flatten variables in set
      set.variablesFlat = flattenVariables(set.variables);
      // Update set in store
      Vue.set(state.custom, payload, set);
    }
  }
};
