export const prices = {
  advancedPricingActive: "Activate advanced pricing settings",
  advancedPricingActiveHelp:
    "All sales prices with customer groups, graduated prices, different currencies and their validity are imported here, provided the customer groups and currencies have been assigned.",
  currencyAssignmentHelp:
    "Here you can assign the currencies from Xentral to the corresponding currencies in {appName}.",
  customerGroupAssignmentStrategy:
    "How should the extended prices be assigned?",
  customerGroupAssignmentStrategyCustomerGroup:
    "Assign customer groups from Xentral to the customer groups in the shop",
  customerGroupAssignmentStrategyPriceRule:
    "Assign customer groups from Xentral to the price rules in the shop",
  customerGroupAssignmentTableId: "Customer group assignment",
  customerGroupAssignmentTableIdHelp:
    "Here the customer groups from Xentral can be assigned to the corresponding customer groups in {appName}",
  priceGroup: "Customer or customer group",
  priceGroupHelp:
    "Here you can specify which price should be transferred from Xentral to the sales channel. Please enter the name of the customer or customer group here. If you leave this field empty, the standard price will be used.",
  priceRulesAssignmentTableId: "Assignment of the price rules",
  priceRulesAssignmentTableIdHelp:
    "Here the customer groups from Xentral can be assigned to the corresponding price rules in {appName}",
  roundPrices: "Pre-round prices before transferring them to the shop?",
  roundPricesHelp:
    "Here you can select whether prices should be rounded before being transferred to the sales channel. This can eliminate possible rounding differences.",
  transferGraduatedPrices: "Transfer graduated prices",
  transferNetPrice: "Transfer prices as net"
};
