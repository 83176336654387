export const settings = {
  barcode: "Barcode",
  customsTariffNumber: "Zolltarifnummer",
  ean: "EAN Nr./Barcode",
  name: "Name",
  productAssignmentLabel:
    "Wie sollen die Artikel aus Xentral den Artikeln im Verkaufskanal zugeordnet werden?",
  productAssignmentSelectXentral: "Artikelnummern aus Xentral übernehmen",
  productAssignmentSource: "Feld aus Xentral",
  productAssignmentTarget: "Feld aus {appName}",
  productnumber: "Artikelnummer",
  sku: "SKU",
  title: "Titel",
  dangerZone: "Gefahrenbereich",
  dangerZoneInfo:
    "Hier können Sie Ihre Integration unwiderruflich löschen. Dies bedeutet, dass alle verbundenen " +
    "Daten und Einstellungen entfernt werden und die Integration nicht wiederhergestellt werden kann. ",
  deletionText:
    'Möchten Sie die Integration "{integration}" wirklich löschen? Dieser Vorgang ist unwiderruflich.',
  deletionSuccess: "Integration erfolgreich gelöscht",
  deletionError: "Fehler beim Löschen der Integration"
};
