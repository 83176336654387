export const general = {
  acceptAndNext: "Accept and next",
  active: "Active",
  back: "Back",
  backToOverview: "Back to overview",
  cancel: "Cancel",
  caution: "Caution",
  changeImage: "Change image",
  confirm: "Confirm",
  close: "Close",
  copied: "Successfully copied to clipboard",
  copy: "Copy",
  copyToClipboard: "Copy to clipboard",
  copyGroupToClipboard: "Copy group to clipboard",
  delete: "Delete",
  deleted: "Deleted",
  details: "Details",
  edit: "Edit",
  email: "E-Mail",
  fileReaderNotSupported: "Sorry, this file reader API is not supported.",
  inactive: "Inactive",
  language: "Language",
  loading: "Loading",
  minutes: "minutes",
  next: "Next",
  nextStep: "Next step",
  no: "No",
  noConfigAvailable: "No configuration available",
  noRecords: "Records 0 from 0",
  notAvailable: "Not available",
  overview: "Overview",
  phone: "Telephone",
  preview: "Preview",
  previous: "Previous",
  previousStep: "Previous step",
  records: "Records {from}-{to} from {total}",
  recordsPerPage: "Records per page",
  reload: "Reload",
  remove: "Remove",
  removeImage: "Remove image",
  reset: "Reset",
  save: "Save",
  saveChanges: "Save changes",
  search: "Search",
  seconds: "seconds",
  selectClientFirst: "Please select a tenant first",
  sendMessage: "Send message",
  activate: "Activate",
  deactivate: "Deactivate",
  send: "Send",
  status: "Status",
  uploadImage: "Upload image",
  uploadedImage: "Uploaded image",
  yedi: "YEDI",
  yes: "Yes",
  yourContactPerson: "Your contact person",
  noContactPerson: "No contact person assigned",
  noPartner: "No partner assigned",
  yourPartner: "Your partner",
  sessionExpiredTitle: "Session expired",
  sessionExpiredMessage: "You have benn automatically logged out",
  timeoutLimitExceeded:
    "Looks like the server is taking to long to respond, please try again in sometime",
  selectionOther: "(+{count} others)",
  takeOn: "Accept",
  autoUpdate: "Auto update",
  expandedMenu: "Expanded Menu",
  directInput: "Direct input",
  addField: "+ add field",
  logout: "Logout",
  help: "Help",
  done: "Done",
  noSelectOptions: "No options available",
  beta: "Beta environment - not for production use",
  resourceOwner: "Resource owner",
  open: "Open",
  versions: "Versions",
  showVersions: "Show versions",
  controlKey: "ctrl+",
  presetProjectForbiddenRoutes:
    "This action is not allowed in a preset project",
  example: "e.g. {example}",
  presetVersion: "Version {version}",
  authenticationError:
    "The credentials you entered are incorrect. Please check your input and try again."
};
