export const queuedJobs = {
  queue: "Queue",
  queues: "Queues",
  queuedJobs: "Queued Jobs",
  jobClass: "Job class",
  jobAbortFailed: "Jobs with the status of '{status}' cannot be canceled",
  processId: "Process ID",
  processName: "Process name",
  deleteConfirm: "Confirm Job canceling",
  deleteConfirmText: 'Do you really want to cancel the job "{queue}"?',
  deleteSucessText: 'Job "{queue}" has been canceled',
  processIterationId: "Process Iteration ID",
  tenantId: "Tenant ID",
  tenantName: "Tenant name",
  confirmBulkDelete: "Confirm bulk cancel",
  allSelectedRowsDelete: "All selected ({count}) jobs will be canceled",
  allFilteredRowsDelete: "All filtered ({count}) jobs will be canceled",
  selectedRowsDeleted: "{count} jobs have been deleted",
  selectAll: "Select all",
  unselectAll: "Unselect all",
  deleteSelected: "Cancel selected Jobs",
  startedBy: "Started by",
  queuesFilterSelect: "Filter by queues",
  deleteAllFilteredButton: "Cancel all filtered Jobs",
  processFilterSelect: "Process filter",
  others: "others",
  yesCancel: "Confirm cancellation",
  noCancel: "Abort cancellation",
  requeueSelectedSuccess: "Selected jobs were successfully requeued!",
  cancelSuccessText: "The job was successfully canceled"
};
