export const returnTypeIcons = {
  text: "fal fa-text",
  number: "fal fa-arrow-down-1-9",
  variable: "fal fa-brackets-curly",
  true: "fal fa-toggle-on",
  false: "fal fa-toggle-off",
  null: "fal fa-empty-set",
  array: "fal fa-list"
};

export const customRegex = {
  html: /<[^>]*>?/,
  variable: /(^({{)\s?\w+(\.{1}\w+)*(-{1}\w+)*(\.{1}\w+)*\s?}}$)/,
  alphaSpace: /^[a-zA-Z\s]*$/,
  alphaNumSpace: /^[a-zA-Z0-9\s]*$/,
  integer: /(^[0-9]*$)|(^-[0-9]+$)/,
  float: /^[-]?\d*(\.\d+)?$/
};

export const simpleValidators = [
  "required",
  "alpha",
  "alphaNum",
  "alphaSpace",
  "alphaNumSpace",
  "variable",
  "json",
  "email",
  "ipAddress",
  "url"
];
export const singleParameterValidators = [
  "minLength",
  "maxLength",
  "minValue",
  "maxValue"
];
export const numberValidators = ["numeric", "integer", "decimal"];
export const locatorValidators = ["requiredIf", "requiredUnless", "sameAs"];

export const fieldDefaults = {
  array: [],
  checkbox: false,
  code: "",
  condition: {},
  date: "",
  datetime: "",
  file: null,
  float: null,
  group: [],
  hidden: "",
  image: null,
  json: [],
  multiselect: [],
  number: null,
  password: "",
  range: 0,
  select: "",
  text: "",
  textarea: "",
  texteditor: "",
  time: ""
};

export const fieldDataTypes = {
  array: ["array"],
  checkbox: ["boolean", "variable"],
  code: ["text", "variable"],
  condition: ["object"],
  date: ["text", "array", "variable"],
  datetime: ["text", "variable"],
  file: ["null", "object"],
  group: ["array", "text"],
  hidden: ["any"],
  image: ["null", "object", "text"],
  json: ["object", "array", "variable"],
  multiselect: ["array", "variable"],
  number: ["null", "number", "variable", "text"],
  float: ["null", "number", "decimal", "text"],
  password: ["text", "variable"],
  range: ["number", "variable"],
  select: ["any"],
  text: ["text", "null", "number", "boolean", "variable"],
  textarea: ["text"],
  texteditor: ["text"],
  time: ["text", "variable"]
};

export const noValueOperators = [
  "is null",
  "is empty",
  "is not null",
  "is not empty"
];

export const defaultOperators = [
  "==",
  "===",
  "!==",
  "!=",
  "<",
  ">",
  "<=",
  ">=",
  "in",
  "notIn"
].concat(noValueOperators);

export const arrayOperators = ["in", "notIn", "$in", "$nin"];

// These field types are not actual form input elements and should be skipped if, for example, the input is to be validated
export const skipFields = [
  "action",
  "alert",
  "headline",
  "label",
  "description"
];
