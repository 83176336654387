import Vue from "vue";
import store from "./index";

const _ = require("lodash");

export const SET_FIELD = "setField";
export const SET_FIELD_ID = "setFieldId";
export const SET_FIELD_STRUCTURE = "setFieldStructure";
export const SET_FIELD_COLLECTION_KEYS = "setFieldCollectionKeys";
export const SET_CONNECTIONS = "setConnections";
export const SET_SELECTED_TRANSFORMER_ID = "setSelectedTransformerId";

export const SET_TRANSFORMERS = "setTransformers";
export const ADD_TRANSFORMER = "addTransformer";
export const UPDATE_TRANSFORMER = "updateTransformer";
export const REMOVE_TRANSFORMER = "removeTransformer";
export const UPDATE_TRANSFORMERS = "updateTransformers";

export const SET_STATIC = "setStatic";

export const CLEAR_ALL = "clearAll";

const getDefaultState = () => {
  return {
    fieldId: "",
    fieldStructure: "", // "source" or "target" data structure,
    collectionKeys: [], // keys of parents' collection items
    connections: [], // connections connected to current field
    transformers: [], // transformers of this field,
    selectedTransformerId: "",
    isStatic: false // If field is static collection
  };
};

export default {
  namespaced: true,
  state: getDefaultState,
  getters: {
    fieldId: state => {
      return state.fieldId;
    },
    fieldStructure: state => {
      return state.fieldStructure;
    },
    collectionKeys: state => {
      return state.collectionKeys;
    },
    transformers: state => {
      return state.transformers.sort((a, b) => a.position - b.position);
    },
    connections: state => {
      return state.connections;
    },
    // Only input vars coming from connections
    inputVars: state => {
      let vars = [];
      state.connections.forEach(c => {
        let name = store.getters["mapping/variableName"](c.attrs.data.start);
        vars.push(name);
      });
      return _.uniq(vars);
    },
    outputVars: state => position => {
      let vars = [];
      state.transformers.forEach(t => {
        if (
          !t.config.output ||
          t.position >= position ||
          t.config.output === "collection"
        ) {
          return;
        }
        vars.push(t.config.output);
      });
      return vars;
    },
    // All vars including configured transformer output vars
    vars: (state, getters) => position => {
      return [...getters.inputVars, ...getters.outputVars(position)];
    },
    selectedTransformer: state => {
      return state.transformers.find(t => t.id === state.selectedTransformerId);
    },
    selectedTransformerId: state => {
      return state.selectedTransformerId;
    },
    isStatic: state => {
      return state.isStatic;
    }
  },
  actions: {
    // [SET_FIELD] payload = {id, (String) structure}
    [SET_FIELD](context, payload) {
      context.commit(SET_FIELD_ID, payload.id);
      context.commit(SET_FIELD_STRUCTURE, payload.structure ?? "target");
      context.commit(SET_FIELD_COLLECTION_KEYS, payload.collectionKeys);
    },
    // [SET_CONNECTIONS] payload = (Array) connections
    [SET_CONNECTIONS](context, payload) {
      let connections = payload;
      if (context.getters.fieldId) {
        connections = connections.filter(c => {
          return (
            c.attrs.data[
              context.getters.fieldStructure === "source" ? "start" : "end"
            ] === context.getters.fieldId
          );
        });
      }
      context.commit(SET_CONNECTIONS, connections);
    },
    // [SET_TRANSFORMERS] payload = (Array) transformers
    [SET_TRANSFORMERS](context, payload) {
      context.commit(SET_TRANSFORMERS, payload);
    },
    // [ADD_TRANSFORMER] payload = transformer
    [ADD_TRANSFORMER](context, payload) {
      context.commit(ADD_TRANSFORMER, payload);
    },
    // [UPDATE_TRANSFORMER] payload = {id, transformer}
    [UPDATE_TRANSFORMER](context, payload) {
      context.commit(UPDATE_TRANSFORMER, payload);
    },
    // [REMOVE_TRANSFORMER] payload = id
    [REMOVE_TRANSFORMER](context, payload) {
      context.commit(REMOVE_TRANSFORMER, payload);
    },
    // [UPDATE_TRANSFORMER_POSITIONS] payload = (Array) transformers
    [UPDATE_TRANSFORMERS](context, payload) {
      context.commit(UPDATE_TRANSFORMERS, payload);
    },
    [SET_SELECTED_TRANSFORMER_ID](context, payload) {
      context.commit(SET_SELECTED_TRANSFORMER_ID, payload);
    },
    [CLEAR_ALL](context) {
      context.commit(CLEAR_ALL);
    },
    [SET_STATIC](context, payload) {
      context.commit(SET_STATIC, payload);
    }
  },
  mutations: {
    [SET_FIELD_ID](state, payload) {
      state.fieldId = payload;
    },
    [SET_FIELD_STRUCTURE](state, payload) {
      state.fieldStructure = payload;
    },
    [SET_FIELD_COLLECTION_KEYS](state, payload) {
      state.collectionKeys = payload;
    },
    [SET_CONNECTIONS](state, payload) {
      state.connections = payload;
    },
    [SET_TRANSFORMERS](state, payload) {
      state.transformers = payload;
    },
    [ADD_TRANSFORMER](state, payload) {
      state.transformers.push(payload);
    },
    [UPDATE_TRANSFORMER](state, payload) {
      const id = payload.id;
      const transformer = payload.transformer;
      let stateTransformer = state.transformers.find(t => t.id === id);
      let index = state.transformers.indexOf(stateTransformer);
      if (!stateTransformer || index < 0) {
        return;
      }
      const allowedKeys = ["input", "output"];
      // remove everything except the keys "input", "output",
      // and any entry where the value is undefined to overwrite the transformer config
      transformer.config = Object.keys(transformer.config)
        .filter(
          key =>
            allowedKeys.includes(key) || transformer.config[key] === undefined
        )
        .reduce((acc, key) => {
          acc[key] = transformer.config[key];
          return acc;
        }, {});
      // Use Vue.set() for deeply nested values
      Vue.set(state.transformers, index, transformer);
    },
    [REMOVE_TRANSFORMER](state, payload) {
      const id = payload;
      let stateTransformer = state.transformers.find(t => t.id === id);
      const index = state.transformers.indexOf(stateTransformer);
      if (!stateTransformer || index < 0) {
        return;
      }
      state.transformers.splice(index, 1);
    },
    [UPDATE_TRANSFORMERS](state, payload) {
      state.transformers = payload;
    },
    [SET_SELECTED_TRANSFORMER_ID](state, payload) {
      state.selectedTransformerId = payload;
    },
    [CLEAR_ALL](state) {
      Object.assign(state, getDefaultState());
    },
    [SET_STATIC](state, payload) {
      state.isStatic = !!payload;
    }
  }
};
