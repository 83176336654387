import ApiService from "@/core/services/api.service";
import { BaseService } from "@/components/Tools/baseService";

let url = process.env.VUE_APP_API_ADMIN;

class Presets extends BaseService {
  get(id) {
    return ApiService.get(url, "preset_configs/" + id);
  }

  getAll(
    params = { noPagination: true },
    filter = [],
    headers = {},
    disableAbort = false
  ) {
    let filterParams = this.getParams(filter, params);
    let options = new URLSearchParams(params).toString();

    return ApiService.get(
      url,
      "preset_configs?" + options + filterParams,
      headers,
      disableAbort
    );
  }

  store(data, disableAbort = false) {
    return ApiService.post(
      url + "/preset_configs",
      data,
      {},
      false,
      disableAbort
    );
  }

  update(id, data, disableAbort = false) {
    return ApiService.put(
      url + "/preset_configs/" + id,
      data,
      {},
      true,
      disableAbort
    );
  }

  delete(id) {
    return ApiService.delete(url + "/preset_configs/" + id);
  }
}
export default new Presets();
