export const formHelper = {
  acceptedFileLength: "Only one file can be uploaded at a time",
  acceptedFileTypes: "Filetypes: {fileTypes}",
  addGroup: "Add group",
  array: "Array",
  arrayAddEntry: "Add entry",
  browse: "Select",
  cancel: "Cancel",
  condition: "Condition",
  conditionClear: "Empty condition",
  conditionNest: "Nest condition",
  conditionRemove: "Remove condition",
  copy: "Copy value",
  datePicker: {
    itemsSelected: "{0} selected"
  },
  default: "Default",
  directInput: "Direct input",
  dropFilesToUpload: "Drop files here to upload",
  dropZoneLabel: "Drag files or click here to upload them",
  fileSizeLimitError:
    "The selected file exceeds the upload size ({size}) | The selected files exceed the upload size ({size})",
  imageAlt: "Image file",
  imageCropLabel: "Crop",
  imageDropZoneLabel: "Drag image anywhere to upload",
  imageRemove: "Remove",
  imageUploadLabel: "Upload image",
  jsonAddField: "Add field",
  jsonRemoveField: "Remove field",
  key: "Key",
  noActionDefined: "No action submitted for execution",
  noFileChosen: "No file selected",
  noMatchingVariables: "No matching variables found.",
  noSelectOptions: "No options available.",
  noValueFound: "No value found.",
  noVariables: "No variable available.",
  notPictureError: "Selected file is not an image file.",
  ok: "OK",
  remove: "Remove",
  removeGroup: "Delete group",
  reloadOptions: "Reload Options",
  requestCanceled: "Request was aborted",
  returnType: "Return value",
  selectDate: "Select date",
  selectDateTime: "Select date and time",
  selectTime: "Select time",
  uploadLabel: "Drag files or click here to upload them",
  uploadSizeLimit: "Maximum file size: {limit}",
  value: "Value",
  variableFieldPlaceholder: "Select variable",
  variablesFieldSwitch: "Variable selection",
  selectVariable: "Select variable",
  selectOption: "Select option",
  moveConditionUp: "Move condition to the left",
  moveConditionDown: "Move condition to the right",
  errors: {
    required: 'Field "{label}" is a mandatory field.',
    minLength: "Minimum length: {min}.",
    maxLength: "Maximum length: {max}.",
    alpha: "Only letters allowed.",
    alphaNum: "Only alphanumeric characters allowed.",
    alphaSpace: "Only letters and spaces allowed.",
    alphaNumSpace: "Only alphanumeric characters and spaces allowed.",
    email: "No valid email-Address.",
    numberOrVariable: "Only numbers or variables allowed.",
    numeric: "Only natural numbers allowed.",
    integer: "Only integers allowed.",
    decimal: "Only whole and floating point numbers allowed.",
    json: "Input is not in JSON format.",
    invalidNumberValue: "Only numeric values allowed.",
    invalidNumberOrVariableValue: "Only numeric values or variables allowed.",
    variable: "Value must be variable."
  },
  returnTypes: {
    text: "Text",
    number: "Number",
    variable: "Variable",
    true: "Bool (true)",
    false: "Bool (false)",
    null: "Null"
  },
  editor: {
    paragraph: "Paragraph",
    heading1: "Headline 1",
    heading2: "Headline 2",
    heading3: "Headline 3"
  },
  dataOptions: {
    loading: "The options are loading. Please wait a moment.",
    notFound: "No options could be found.",
    search: "Tap to search for an option."
  },
  jsonStructure: {
    title: "JSON structure",
    invalid: "Invalid or empty JSON",
    string: "String",
    number: "Number",
    boolean: "Boolean",
    object: "Object",
    array: "Array",
    unknown: "Unknown"
  }
};
