export const table = {
  actions: "Actions",
  active: "Active",
  author: "Author",
  availableAt: "Available at",
  baseData: "Base data",
  baseDataStructureType: "Base DataStructure type",
  caseIdentifier: "Case identifier",
  className: "Class name",
  comment: "Comment",
  contentIdentifier: "Content identifier",
  createdAt: "Created at",
  createdBy: "Created by",
  datetime: "Date/Time",
  description: "Description",
  detailsHide: "Hide details",
  dispatched: "Dispatched",
  end: "End",
  finishedAt: "Finished at",
  id: "ID",
  initialized: "Initialized",
  isLocked: "Is locked",
  is_locked: "Locked",
  label: "Label",
  language: "Language",
  lastRun: "Last run",
  logo: "Logo",
  mail: "Email",
  message: "Message",
  name: "Name",
  nameDisplay: "Display name",
  noRecords: "No records found",
  numberClient: "Client number",
  numberPartner: "Partner number",
  offer: "Offers",
  orderIndex: "Order index",
  overview: "Overview",
  parameter: "Parameter",
  partner: "Partner",
  preview: "Preview",
  price: "Prices",
  primary: "Primary key",
  productNumber: "Article Nr.",
  progress: "Progress",
  project: "Project",
  queue: "Queue",
  receiver: "Receiver",
  release: "Release",
  reservedAt: "Reserved at",
  role: "Role",
  schedule: "Schedule",
  sender: "Sender",
  short: "Short",
  showLess: "Show less",
  showMore: "Show more",
  source: "Source",
  start: "Start",
  startedAt: "Started at",
  status: "Status",
  stock: "Stock",
  subject: "Subject",
  tableName: "Table name",
  tenant: "Tenant",
  timestamp: "Timestamp",
  title: "Title",
  token: "Token",
  total: "Total",
  type: "Type",
  updatedAt: "Updated at",
  url: "URL",
  user: "User",
  uuid: "#UUID",
  value: "Value",
  version: "Version",
  workflowComponent: "Workflow component",
  workflows: "Workflows"
};
