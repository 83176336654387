export const stock = {
  availableStock:
    "Available stock (items in stock - items from open orders - reserved items)",
  inReservation: "Items on stock reservations",
  inStock: "Item in stock",
  inStockSubtractedReserved: "Items in stock - reserved items",
  pseudoStockRule: "Pseudo stock rule",
  pseudoStockRuleHelp:
    "Any pseudo stock rule to be applied to the stock can be entered here. The rule must begin with a calculation character (+, -, /,'or *).",
  pseudoWarehouse: "Pseudo warehouse",
  pseudoWarehouseHelp: "Pseudo warehouse rules",
  stockBase: "Stock base",
  stockBaseHelp: "On what basis should stock levels be calculated?",
  warehouse: "Select warehouse",
  warehouseShopify: "Select Shopify warehouse",
  warehouseXentral: "Select Xentral warehouse",
  warehouseBigcommerce: "BigCommerce warehouse selection",
  warehouseAssignmentHelp:
    "Here, warehouses from Xentral can be assigned to the corresponding warehouses of the online store.",
  warehouseAssignmentSelect:
    "How should the warehouses from Xentral be assigned in the online store?",
  warehouseAssignmentSelectHelp: "How should warehouses be assigned?",
  warehouseAssignmentXentral: "Transfer warehouse from Xentral",
  warehouseHelp:
    "Select warehouse in which the stock is to be written. This warehouse must already be created in the online store.",
  considerReserved: "consider reservations",
  considerReservedHelp:
    "Should reservations be deducted from the physical stock? Please note that open orders may automatically lead to reservations depending on the project settings.",
  considerOpenOrders: "Consider open orders",
  considerOpenOrdersHelp:
    "Should open orders be deducted from physical stock? Please note that open orders may automatically lead to reservations depending on the project settings.",
  considerStockAdjustments: "Consider stock adjustment value",
  considerStockAdjustmentsHelp:
    "Should the stock adjustment value be deducted from physical stock or added to physical stock?",
  considerPseudoStock: "Use pseudo stock number instead of real stock numbers",
  considerPseudoStockHelp:
    "Here you can select whether the pseudo stock number should be used per item instead of the real stock numbers. If there are several warehouses on the sales channel page, the pseudo stock number is then written to each selected warehouse.",
  warehouseIds: "Selection Xentral warehouse",
  warehouseIdsHelp:
    "Here you can select one or more Xentral warehouses from which the stock is to be written to the sales channel. If several warehouses are selected, the stock of these warehouses is totalled.",
  warehouseAssignmentTableId: "Warehouse assignment",
  warehouseAssignmentTableIdHelp:
    "Here, warehouses from Xentral can be assigned to the corresponding warehouses of the online sales channel. An online sales channel warehouse can be assigned to several Xentral warehouses, but an Xentral warehouse can only be assigned to one online sales channel warehouse."
};
