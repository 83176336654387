<template>
  <v-app>
    <router-view data-app></router-view>
  </v-app>
</template>

<style lang="scss">
// 3rd party plugins css
@import "~bootstrap-vue/dist/bootstrap-vue.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "~animate.css";
@import "~@awesome.me/kit-387be6ad37/icons/css/all.min.css";
@import "~vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";

// Main demo style scss
@import "assets/sass/style.vue";
@import "assets/sass/yedi.vue";

// Check documentation for RTL css
// Update HTML with RTL attribute at public/index.html
/*@import "assets/css/style.vue.rtl";*/
html {
  overflow-y: auto !important;
}

body {
  background: $color-connect-background;
}
</style>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/core/services/api.service";
import { SET_INSTANCE } from "@/core/services/store/instance.module";

export default {
  name: "YEDI",
  components: {},
  metaInfo() {
    return {
      title: this.title,
      titleTemplate: this.titleTemplate
    };
  },
  data() {
    return {
      title: "",
      titleTemplate: "CONNECT"
    };
  },
  computed: {
    ...mapGetters(["pageTitle"])
  },
  mounted() {
    window.addEventListener("click", () => {
      this.$root.$emit("bv::hide::popover");
    });

    const appRoutePartials = [
      "salesChannelManagement",
      "transferApp",
      "watchdog"
    ];

    const skipMetaInfo = appRoutePartials.some(
      partial =>
        window.location.hash.includes(partial) &&
        process.env.VUE_APP_SCMA_ENVIRONMENT !== "local"
    );

    if (!skipMetaInfo) {
      this.setMetaInfo();
    }
    /**
     * this is to override the layout config using saved data from localStorage
     * remove this to use config only from static json (@/core/config/layout.config.json)
     */
    //this.$store.dispatch(OVERRIDE_LAYOUT_CONFIG);
  },
  methods: {
    setMetaInfo() {
      const url = process.env.VUE_APP_API_ADMIN + "/instance";
      ApiService.get(url)
        .then(response => {
          this.$store.dispatch("instance/" + SET_INSTANCE, response.data);
          const instanceName = response.data.name ?? "";
          this.title = instanceName;
          if (instanceName) {
            this.titleTemplate = `%s - CONNECT`;
          }
        })
        .catch(error => {
          this.$error(error);
        });
    }
  }
};
</script>
