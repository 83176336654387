import ApiService from "@/core/services/api.service";

let url = process.env.VUE_APP_API_ADMIN;

class FileExplorer {
  get(id) {
    return ApiService.get(url, "file_explorer/" + id);
  }

  getAll(params) {
    let options = "?" + new URLSearchParams(params).toString();
    if ("sort" in params) {
      options += "&orderBy[]=" + params.sort;
    }
    return ApiService.get(url, "file_explorer" + options);
  }

  createFolder(filePath, folderName) {
    let folderPath =
      filePath === "files" ? folderName : filePath + "/" + folderName;
    let path = { folderPath: folderPath };
    return ApiService.post(url + "/file_explorer/createFolder", path);
  }

  updateName(filePath, fileName) {
    let body = { filePath: filePath, fileName: fileName };
    return ApiService.put(url + "/file_explorer", body);
  }

  upload(file, path, name) {
    let body = new FormData();
    body.append("file", file);
    body.append("path", path);
    body.append("name", name);
    ApiService.removeHeaderByKey("Content-Type");
    return ApiService.post(url + "/file_explorer/upload", body, {}, true);
  }

  bulkDownload(filePaths) {
    return ApiService.post(url + "/file_explorer/download", filePaths, {
      responseType: "blob"
    });
  }

  getFileContent(data) {
    let body = {
      filePath: data.filePath
    };
    return ApiService.post(url + "/file_explorer", body);
  }

  downloadFile(data) {
    let body = {
      filePath: data.filePath,
      fileType: data.fileType
    };
    return ApiService.post(url + "/file_explorer/downloadFile", body, {
      responseType: "blob"
    });
  }

  move(data) {
    let body = {
      from: data.from,
      to: data.to
    };
    return ApiService.post(url + "/file_explorer/move", body);
  }

  delete(filePath) {
    let body = { filePath: filePath };
    return ApiService.post(url + "/file_explorer/delete", body);
  }
}

export default new FileExplorer();
