import ApiService from "@/core/services/api.service";
import { BaseService } from "@/components/Tools/baseService";
let url = process.env.VUE_APP_API_ADMIN;

class FlowElements extends BaseService {
  get(id) {
    return ApiService.get(url, "flow_elements/" + id);
  }
  getAll(
    fields = [
      "config",
      "documentation",
      "dummyData",
      "flow_element_exists",
      "icon",
      "id",
      "label",
      "name",
      "type"
    ]
  ) {
    let params = this.getParams([], {}, fields);
    let options = "?" + params.toString();
    return ApiService.get(url, "flow_elements/all" + options);
  }

  getTypes() {
    return ApiService.get(url, "flow_elements/types");
  }
  action(id, data, header = {}) {
    return ApiService.post(url + "/flow_elements/action/" + id, data, header);
  }
}
export default new FlowElements();
