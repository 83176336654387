export const stock = {
  availableStock:
    "Verfügbarer Bestand (Artikel auf Lager - Artikel aus offenen Aufträgen - reservierte Artikel)",
  inReservation: "Artikel auf Lager-Reservierungen",
  inStock: "Artikel auf Lager",
  inStockSubtractedReserved: "Artikel auf Lager - reservierte Artikel",
  pseudoStockRule: "Pseudolager Regel",
  pseudoStockRuleHelp:
    "Hier kann eine beliebige Pseudolager Regel eingegeben werden, die auf den Bestand angewendet werden soll. Die Regel muss mit einem Rechenzechen (+, -, /,´oder *) beginnen.",
  pseudoWarehouse: "Pseudolager",
  pseudoWarehouseHelp: "Pseudolager-Regeln",
  stockBase: "Lagerbestand Basis",
  stockBaseHelp: "Auf welcher Basis sollen Lagerbestände berechnet werden?",
  warehouse: "Auswahl Lager",
  warehouseShopify: "Auswahl Shopify Lager",
  warehouseXentral: "Auswahl Xentral Lager",
  warehouseAssignmentHelp:
    "Hier können Lager aus Xentral den entsprechenden Lagern des Verkaufskanals zugeordnet werden.",
  warehouseBigcommerce: "Auswahl BigCommerce Lager",
  warehouseAssignmentSelect:
    "Wie sollen die Lager aus Xentral im Verkaufskanal zugeordnet werden?",
  warehouseAssignmentSelectHelp: "Wie soll die Zuordnung von Lagern erfolgen?",
  warehouseAssignmentXentral: "Lager aus Xentral übernehmen",
  warehouseHelp:
    "Lager auswählen, in den der Bestand geschrieben werden soll. Dieses Lager muss bereits im Verkaufskanal angelegt sein.",
  considerReserved: "Reservierungen beachten",
  considerReservedHelp:
    "Sollen Reservierungen vom physischen Bestand abgezogen werden? Bitte beachte, dass offene Aufträge je nach Projekteinstellung automatisch auch zu Reservierungen führen können.",
  considerOpenOrders: "Offene Aufträge beachten",
  considerOpenOrdersHelp:
    "Sollen offene Aufträge vom physischen Bestand abgezogen werden? Bitte beachte, dass offene Aufträge je nach Projekteinstellung automatisch auch zu Reservierungen führen können.",
  considerStockAdjustments: "Lagerkorrekturwert beachten",
  considerStockAdjustmentsHelp:
    "Soll der Lagerkorrekturwert vom physischen Bestand abgezogen bzw. zum physischen Bestand hinzugefügt werden?",
  considerPseudoStock: "Pseudo Lagerzahl statt echten Lagerzahlen verwenden",
  considerPseudoStockHelp:
    "Hier kann ausgewählt werden, ob pro Artikel die Pseudo Lagerzahl statt der echten Lagerzahlen verwendet werden soll. Falls es mehrere Lager auf Verkaufskanal Seite gibt, wird die Pseudo Lagerzahl dann in jedes ausgewählte Lager geschrieben.",
  warehouseIds: "Auswahl Xentral Lager",
  warehouseIdsHelp:
    "Hier können ein oder mehrere Xentral Lager ausgewählt werden, aus denen der Bestand in den Verkaufskanal geschrieben werden soll. Wenn mehrere Lager ausgewählt werden, wird der Bestand dieser Lager aufsummiert.",
  warehouseAssignmentTableId: "Lager Zuordnung",
  warehouseAssignmentTableIdHelp:
    "Hier können Lager aus Xentral den entsprechenden Lagern des Verkaufskanals zugeordnet werden. Ein Verkaufskanal Lager kann mehreren Xentral Lagern zugewiesen werden, ein Xentral Lager kann jedoch nur einem Verkaufskanal Lager zugewiesen werden."
};
