export const variables = {
  configValues: "Konfigurationswerte",
  parameters: "Parameter",
  presetValues: "Presetwerte",
  inputVariables: "Input Variablen",
  outputVariables: "Output Variablen",
  objectVariables: "Object Variablen",
  sourceVariables: "Source Variablen",
  demo: "Demo Variablen",
  dataSetFields: "Data-Set Felder",
  dataSetParameters: "Data-Set Parameter",
  outputValues: "Output Values",
  errorValues: "Error Values",
  scmaXentralFields: "Felder aus Xentral"
};
