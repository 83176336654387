export const queues = {
  queue: "Warteschlange",
  queues: "Warteschlangen",
  queuedJobs: "Warteschlangen Jobs",
  jobClass: "Jobklasse",
  processID: "Prozess ID",
  processName: "Prozess name",
  deleteConfirm: 'Warteschlange "{queue}" löschen ?',
  deleteConfirmText:
    'Möchten Sie die Warteschlange "{queue}" wirklich löschen? Sie können dies nach dem Löschen nicht wiederherstellen.',
  deleteSucessText: "Warteschlange wurde erfolgreich gelöscht",
  processIterationId: "Prozessdurchlauf ID",
  tenantId: "Tenant ID",
  tenantName: "Tenant name",
  time: "Zeit",
  confirmBulkDelete: "Massenlöschung bestätigen",
  confirm: "{action} bestätigen?",
  command: "Kommando",
  allSelectedRowsDelete: "Alle ausgewählten ({count}) Zeilen werden gelöscht",
  selectAll: "Alle auswählen",
  supervisorRecreateConfig: "Config generieren",
  supervisorStart: "Supervisor starten",
  supervisorRestart: "Supervisor neustarten",
  supervisorStop: "Supervisor stoppen",
  supervisorRecreateConfigSuccess: "Config erfolgreich generiert",
  supervisorStartSuccess: "Supervisor erfolgreich gestartet",
  supervisorRestartSuccess: "Supervisor erfolgreich neugestartet",
  supervisorStopSuccess: "Supervisor erfolgreich gestoppt",
  output: "Output",
  unselectAll: "Alle abwählen",
  deleteSelected: "Ausgewählte löschen",
  startedBy: "Gestartet von",
  maxParallelJobs: "Maximale parallele Jobs",
  name: "Name",
  nameEnter: "Namen eingeben",
  queueCreate: "Warteschlange erstellen",
  queueCreated: "Warteschlange erstellt",
  queueUpdate: "Warteschlange bearbeiten",
  queueUpdated: "Warteschlange aktualisiert",
  tries: "Anzahl Versuche bis Abbruch",
  timeout: "Laufzeit",
  memory: "Maximaler RAM-Verbrauch",
  high: "High",
  low: "Low",
  beforeFiveMinutes: "Vor 5 Minuten",
  beforeTenMinutes: "Vor 10 Minuten",
  beforeFifteenMinutes: "Vor 15 Minuten",
  beforeThirtyMinutes: "Vor 30 Minuten",
  customDateTime: "Zeitraum auswählen"
};
