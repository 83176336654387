import Vue from "vue";
import _ from "lodash";

export const UPDATE_FORM = "updateForm";
export const UPDATE_FIELD = "updateField";
export const UPDATE_FIELD_ATTRIBUTE = "updateFieldAttribute";
export const SET_ACTIVE_FIELD = "setActiveField";
export const UPDATE_OPTIONS = "updateOptions";
export const ADD_PROJECT = "addProject";
export const UPDATE_PROJECT = "updateProject";
export const REMOVE_PROJECT = "removeProject";
export const LOAD_PROJECT = "loadProject";
export const NO_PROJECT = "noProject";

const getDefaultState = () => {
  return {
    form: [],
    options: {},
    activeFieldId: null,
    projects: {},
    selectedProject: ""
  };
};

export default {
  namespaced: true,
  state: getDefaultState,
  getters: {
    form: state => {
      return _.cloneDeep(state.form);
    },
    field: (state, getters) => index => {
      return getters.form[index];
    },
    activeFieldId: state => {
      return state.activeFieldId;
    },
    activeField: (state, getters) => {
      return getters.form.find(f => f.id === getters.activeFieldId);
    },
    options: state => {
      return _.cloneDeep(state.options);
    },
    projects: state => {
      return _.cloneDeep(state.projects);
    },
    selectedProject: state => {
      return state.selectedProject;
    }
  },
  actions: {
    /**
     * Update form
     * @param context
     * @param {Array} payload Form
     */
    [UPDATE_FORM](context, payload) {
      context.commit(UPDATE_FORM, payload);
    },
    /**
     * Update field in form
     * @param context
     * @param payload
     * @param {Number} payload.id Id of field
     * @param {object} payload.field Field data
     */
    [UPDATE_FIELD](context, payload) {
      context.commit(UPDATE_FIELD, payload);
    },
    /**
     * Update field in form
     * @param context
     * @param payload
     * @param {Number} payload.id Id of field
     * @param {String} payload.name Attribute name
     * @param {any} payload.value Attribute value
     */
    [UPDATE_FIELD_ATTRIBUTE](context, payload) {
      context.commit(UPDATE_FIELD_ATTRIBUTE, payload);
    },
    /**
     * Set active field by id
     * @param context
     * @param {String} payload Field id
     */
    [SET_ACTIVE_FIELD](context, payload) {
      context.commit(SET_ACTIVE_FIELD, payload);
    },
    /**
     * Update options
     * @param context
     * @param {object} payload Options
     */
    [UPDATE_OPTIONS](context, payload) {
      context.commit(UPDATE_OPTIONS, payload);
    },
    /**
     * Add project
     * @param context
     * @param payload
     * @param {String} payload.name Project name
     * @param {object} payload.value Form and options
     * @param {object} payload.value.form Form
     * @param {object} payload.value.options Options
     */
    [ADD_PROJECT](context, payload) {
      context.commit(ADD_PROJECT, payload);
    },
    /**
     * Update project
     * @param context
     * @param payload
     * @param {String} payload.name Project name
     * @param {object} payload.value Form and options
     * @param {object} payload.value.form Form
     * @param {object} payload.value.options Options
     */
    [UPDATE_PROJECT](context, payload) {
      context.commit(UPDATE_PROJECT, payload);
    },
    /**
     * Remove project
     * @param context
     * @param payload
     * @param {String} payload Project name
     */
    [REMOVE_PROJECT](context, payload) {
      context.commit(REMOVE_PROJECT, payload);
    },
    /**
     * Load project
     * @param context
     * @param payload
     * @param {String} payload Project name
     */
    [LOAD_PROJECT](context, payload) {
      context.commit(LOAD_PROJECT, payload);
    },
    /**
     * Load no project
     * @param context
     */
    [NO_PROJECT](context) {
      context.commit(NO_PROJECT);
    }
  },
  mutations: {
    [UPDATE_FORM](state, payload) {
      state.form = payload;
    },
    [UPDATE_FIELD](state, payload) {
      let index = state.form.map(f => f.id).indexOf(payload.id);
      if (index === -1) {
        return;
      }
      Vue.set(state.form, index, payload.field);
    },
    [UPDATE_FIELD_ATTRIBUTE](state, payload) {
      let index = state.form.map(f => f.id).indexOf(payload.id);
      if (index === -1) {
        return;
      }
      Vue.set(state.form[index], payload.name, payload.value);
    },
    [SET_ACTIVE_FIELD](state, payload) {
      state.activeFieldId = payload;
    },
    [UPDATE_OPTIONS](state, payload) {
      Vue.set(state, "options", payload);
    },
    [ADD_PROJECT](state, payload) {
      Vue.set(state.projects, payload.name, payload.value);
      state.selectedProject = payload.name;
    },
    [UPDATE_PROJECT](state, payload) {
      Vue.set(state.projects, payload.name, payload.value);
      state.selectedProject = payload.name;
    },
    [REMOVE_PROJECT](state, payload) {
      Vue.delete(state.projects, payload);
    },
    [LOAD_PROJECT](state, payload) {
      state.form = state.projects[payload].form;
      state.options = state.projects[payload].options;
      state.selectedProject = payload;
    },
    [NO_PROJECT](state) {
      state.form = [];
      state.options = {};
      state.selectedProject = "";
    }
  }
};
