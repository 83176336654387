export const scheduleMethods = {
  cron: "Cronjob",
  between: "Between two times",
  unlessBetween: "Unless between two times",
  inTimeInterval: "In time interval",
  everyMinute: "Every Minute",
  everyFiveMinutes: "Every five minutes",
  everyTenMinutes: "Eevry ten minutes",
  everyFifteenMinutes: "Every 15 minutes",
  everyThirtyMinutes: "Every 30 minutes",
  hourly: "Hourly",
  hourlyAt: "Hourly at specified time",
  daily: "Daily",
  dailyAt: "Daily at specified time",
  at: "At specified time",
  twiceDaily: "Twice daily",
  weekdays: "On weekdays",
  weekends: "On weekends",
  mondays: "Mondays",
  tuesdays: "Tuesdays",
  wednesdays: "Wednesdays",
  thursdays: "Thursdays",
  fridays: "Fridays",
  saturdays: "Saturdays",
  sundays: "Sundays",
  weekly: "Weekly",
  weeklyOn: "Weekly at specified time",
  monthly: "Monthly",
  monthlyOn: "Monthly at specified time",
  twiceMonthly: "Twice monthly",
  quarterly: "Quarterly",
  yearly: "Yearly",
  days: "At specified days",
  timezone: "Set timezone",
  spliceIntoPosition: "Splice into positions",
  never: "Never",
  cronWithParams: "Cronjob: {expression}",
  betweenWithParams: "Between {startTime} and {0}",
  unlessBetweenWithParams: "Unless between {0} and {1}",
  inTimeIntervalWithParams: "In time interval from {0} to {1}",
  hourlyAtWithParams: "{0} min. after every full hour",
  atWithParams: "At {0}",
  dailyAtWithParams: "Daily at {0}",
  twiceDailyWithParams: "Twice daily at {0} and {1}",
  weeklyOnWithParams: "Weekly: {0}, {1}",
  monthlyOnWithParams: "Monthly: {0}, {1}",
  twiceMonthlyWithParams: "Twice monthly: {ß}, {1}",
  daysWithParams: "At days: {0}",
  timezoneWithParams: "In timezone {0}",
  spliceIntoPositionWithParams: "In position {0} with value {1}",
  cronExpressionWithParams: "Cronjob: {expression}"
};
