export const migration = {
  activeFeatures: "Active features",
  category: "Default category",
  categoryHelp: "The category in which the articles are written by default.",
  changeFinishMigrationStatus:
    "The status of the migration has been successfully changed.",
  changeMigrationActiveStatusTextActive:
    "Attention! If you deactivate the initial data import, production mode is automatically activated. Please make sure that you have completed the migration, otherwise errors may occur. This change will only take effect the next time you save.",
  changeMigrationActiveStatusTextInactive:
    "Attention! If you activate the initial data import, production mode is automatically deactivated. Please make sure that no more workflows are running in production mode, otherwise errors may occur. This change will not take effect until the next save.",
  changeMigrationActiveStatusTitle: "Change mode of integration",
  changeMigrationTitle: "Initial data import",
  createFirstIntegrationTitle: "No integrations found",
  createFirstIntegrationDescription:
    "Create an integration to continue. You have the choice to migrate an existing integration to Connect, if they exist they exist or create a new integration.",
  currency: "Currency",
  currencyHelp: "Please enter the currency of your prices here.",
  development: "Development",
  ean: "EAN no. / barcode",
  failed: "All mandatory fields must be filled in.",
  finish: "Finish migration",
  finishDescription: "Do you really want to complete the migration?",
  finished: "The migration has been completed.",
  grossPrices: "Prices incl. taxes (gross)",
  grossPricesHelp:
    "Please indicate here whether you maintain the item prices in the sales channel incl. taxes.",
  migrationActive: "Activate initial data import",
  migrationMissing:
    "This integration can only be used once the initial data import has been completed.",
  migrationStrategy: "Migration strategy",
  migrationStrategyHelp:
    "To connect your sales channel to Xentral, we need to initially link the items between the two systems. Please specify which situation applies to you.",
  name: "Name",
  primary: "Primary key",
  primaryHelp:
    "If there are items in both systems (Shop & Xentral), we need to know which field we should use to assign the items. To do this, please enter the primary key here, i.e. the field in Xentral that is suitable for matching the products.",
  productive: "Productive",
  productNumber: "Item number",
  shop: "There are only items in the shop",
  shopAndXentral: "There are items both in the sales channel and in Xentral",
  start: "Save integration & start initial import of articles",
  started: "The migration has been started.",
  noChannelSelected: "No integration was selected.",
  noChannelsFound:
    "No integrations could be found for the migration to Connect.",
  migrateExistingIntegration: "Apply integration",
  selectIntegration: "Which integration should be migrated?",
  integrationSetupConfirmation:
    'Click on "Apply integration" to complete the migration of your integration. To be able to use the new integration, please note the following information:<br><br>' +
    "1. Check the selection of items selected for the transfer in the 'Item assignment' tab. You can change the filter settings at any time. Only items listed here will be synchronised to your shop<br><br>" +
    "2. Please check the configuration settings of the individual workflows. We have automatically adopted the existing configuration. However, you can change these settings at any time.<br><br>" +
    "3. Items from Xentral are assigned to the items in the sales channel based on the item numbers. If you also want to update items instead of just creating them, please make absolutely sure that the item numbers from Xentral match the item numbers or SKUs from the sales channel.<br><br>" +
    "4. No sales channel-specific product information will be transferred. Please make sure that your product data is suitable for all your sales channels.<br><br>" +
    '5. By default, the standard price of the item is transferred. If you want to transfer a specific price (e.g. of a customer or a customer group) to your shop, you can set this in the "Workflows" area in the "Prices" tab.<br><br>' +
    "6. To activate the new interface, activate live mode for each workflow. Please make absolutely sure that you deactivate the old interfaces that are still active for this sales channel beforehand in order to avoid conflicts.",
  shopifyIntegrationLink:
    'You can find further helpful information <a href="https://help.xentral.com/hc/en-us/sections/13235038667548-NEW-Shopify-connector-Xentral-Connect-beta" target="_blank">here</a>.',
  shopware6IntegrationLink:
    'You can find further helpful information <a href="https://help.xentral.com/hc/en-us/sections/14527994696604-NEW-Shopware-6-connector-Xentral-Connect-beta" target="_blank">here</a>.',
  woocommerceIntegrationLink:
    'You can find further helpful information <a href="https://help.xentral.com/hc/en-us/sections/13235026588060-NEW-WooCommerce-connector-Xentral-Connect-beta" target="_blank">here</a>.',
  formTitle: "The following information is required to set up the integration:",
  integrationSetupConfirmationTitle: "Important Information",
  video: "Video Tutorial",
  handbook: "Manual",
  faq: "FAQ",
  isMigrating:
    "The integration is being transferred. This may take a moment. Please do not close this page.",
  finishedTitle: "The integration has been successfully transferred",
  finishedText:
    "Your integration has been successfully created. You can find more information on how to use your new integration at the links below.",
  finishedTextSingular:
    "Your integration has been successfully created. You can find more information on how to use your new integration at the link below.",
  finishedErrorTitle: "An error occurred while migrating the integration",
  finishedErrorText: "Error message:",
  form: {
    salesChannel: "Sales Channel",
    salesChannelHelp:
      "Select a sales channel from the list for which you want to automatically create a Connect integration.",
    reducedTaxRateCollectionId: "Collection for reduced tax rate",
    reducedTaxRateCollectionIdHelp:
      'In Shopify, items are assigned to a reduced tax rate via collections. This collection must be created in the sales channel and the ID of the collection (e.g. "gid://shopify/Collection/452487217835") must be entered here.',
    exemptedTaxCollectionId: "Collection for exempted tax rate",
    exemptedTaxCollectionIdHelp:
      'In Shopify, items are assigned to an exempt tax rate via collections. This collection must be created in the sales channel and the ID of the collection (e.g. "gid://shopify/Collection/452487217835") must be entered here.',
    id: "Access ID",
    idHelp:
      'You can find the access ID in the Shopware 6 backend under "Settings > System > Integrations"',
    secret: "Security key",
    secretHelp:
      'You can find the security key in the Shopware 6 backend under "Settings > System > Integrations". The security key is only displayed when the integration is created',
    selectionStrategy: "Article assignment",
    selectionStrategyHelp:
      "How are the items synchronised between Xentral and sales channel identified?",
    externalReferences:
      "Transfer article assignment from the existing integration",
    ruleBased:
      "Assign items based on a defined rule (e.g. project, free field, etc.)",
    syncImages: "Image transfer",
    syncImagesHelp:
      "Attention: If image transfer is activated, all images must be maintained in Xentral. Images that are only available in the sales channel and not in Xentral will be removed by the synchronisation.",
    projectCreateFailure: "Project creation failed. Please contact support.",
    migrationSuccessful: "The integration was successfully transferred.",
    migrationFailure: "The migration has failed. Please contact support."
  },
  migratedFromSalesChannel:
    'Migrated from Shop Importer "{channelName}". Please complete the configuration and activate the production mode to use the new integration.',
  moreInformation: "More Information"
};
