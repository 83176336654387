import FileExplorer from "@/components/FileExplorer/fileExplorer";

export const LOAD_FILES = "loadFiles";
export const UPLOAD_FILE = "uploadFile";
export const CHANGE_DIRECTORY = "changeDirectory";
export const SET_FILES = "setFiles";
export const SET_FOLDERS = "setFolders";
export const SET_META = "addMeta";
export const TOGGLE_ISBUSY = "toggleIsBusy";
export const ADD_FILES = "addFiles";
export const ADD_FOLDER = "addFolder";
export const REMOVE_FILE = "removeFile";
export const RESET_BREADCRUMBS = "resetBreadcrumbs";
export const REMOVE_BREADCRUMB = "removeBreadcrumb";
export const SET_CURRENT_DIRECTORY = "setCurrentDirectory";
export const RENAME_DIRECTORY_OR_FILE = "renameDirectoryOrFile";
export const DELETE_DIRECTORY_OR_FILE = "deleteDirectoryOrFile";

export default {
  state: {
    files: [],
    folders: [],
    currentDirectory: "",
    directoryBreadcrumbs: [],
    meta: {},
    isBusy: false
  },
  getters: {
    files(state) {
      return state.files;
    },
    folders(state) {
      return state.folders;
    },
    currentDirectory(state) {
      return state.currentDirectory;
    },
    directoryBreadcrumbs(state) {
      return state.directoryBreadcrumbs;
    },
    meta(state) {
      return state.meta;
    },
    isBusy(state) {
      return state.isBusy;
    }
  },
  actions: {
    [LOAD_FILES](context, payload) {
      const directory = payload.directory.length ? payload.directory : "files";
      context.commit(SET_CURRENT_DIRECTORY, directory);
      if (directory === "files" && payload.changeDir) {
        context.commit(CHANGE_DIRECTORY, directory);
      }
      context.commit(TOGGLE_ISBUSY);
      FileExplorer.getAll(payload.params)
        .then(response => {
          let resp = response?.data;
          context.commit(SET_FOLDERS, resp.folders);
          delete resp["folders"];
          context.commit(SET_FILES, resp);
          context.commit(SET_META, response?.meta);
        })
        .then(() => {
          context.commit(TOGGLE_ISBUSY);
        });
    },
    [UPLOAD_FILE](context, payload) {
      let uploadFile = payload.data;
      let directory = JSON.parse(JSON.stringify(payload.directory));
      let path = directory.at(-1) === "files" ? "" : directory.at(-1);
      FileExplorer.upload(uploadFile.file, path, uploadFile.name).then(
        response => {
          let file = response.file[0];
          file["type"] = "file";
          const params = {
            ...{ perPage: 100 },
            directory: path
          };
          context.dispatch(LOAD_FILES, {
            params: params,
            directory: path,
            changeDir: false
          });
        }
      );
    },
    [CHANGE_DIRECTORY](context, payload) {
      context.commit(SET_CURRENT_DIRECTORY, payload);
      context.commit(CHANGE_DIRECTORY, payload);
    },
    [REMOVE_BREADCRUMB](context, payload) {
      context.commit(REMOVE_BREADCRUMB, payload);
    },
    [RESET_BREADCRUMBS](context) {
      context.commit(RESET_BREADCRUMBS);
    },
    [SET_FILES](context, payload) {
      context.commit(SET_FILES, payload);
    },
    [SET_FOLDERS](context, payload) {
      context.commit(SET_FOLDERS, payload);
    },
    [SET_META](context, payload) {
      context.commit(SET_META, payload);
    },
    [TOGGLE_ISBUSY](context) {
      context.commit(TOGGLE_ISBUSY);
    },
    [ADD_FILES](context, payload) {
      context.commit(ADD_FILES, payload);
    },
    [ADD_FOLDER](context, payload) {
      context.commit(ADD_FOLDER, payload);
    },
    [REMOVE_FILE](context, payload) {
      context.commit(REMOVE_FILE, payload);
    },
    [SET_CURRENT_DIRECTORY](context, payload) {
      context.commit(SET_CURRENT_DIRECTORY, payload);
    },
    [RENAME_DIRECTORY_OR_FILE](context, payload) {
      context.commit(RENAME_DIRECTORY_OR_FILE, payload);
    },
    [DELETE_DIRECTORY_OR_FILE](context, payload) {
      context.commit(DELETE_DIRECTORY_OR_FILE, payload);
    }
  },
  mutations: {
    [SET_FILES](state, payload) {
      state.files = [];

      if (state.currentDirectory !== "files") {
        state.files.push({
          fileName: "..",
          type: "back"
        });
      }

      for (const [key] of Object.entries(payload)) {
        state.files.push(payload[key]);
      }
    },
    [SET_FOLDERS](state, payload) {
      if (state.currentDirectory === "files") {
        state.folders = payload;
      }
    },
    [TOGGLE_ISBUSY](state) {
      state.isBusy = !state.isBusy;
    },
    [SET_META](state, payload) {
      state.meta = payload;
    },
    [ADD_FILES](state, payload) {
      let maxKey = Math.max(...Object.keys(state.files));
      let newKey = String(parseInt(maxKey) + 1);

      state.files[newKey] = payload;
    },
    [ADD_FOLDER](state, payload) {
      let folderPath = "files|" + payload.replaceAll("/", "|");
      state.folders.push(folderPath);
    },
    [REMOVE_FILE](state, payload) {
      const targetIndex = Object.keys(state.files).find(
        key => state.files[key].filePath === payload
      );
      delete state.files[targetIndex];
    },
    [CHANGE_DIRECTORY](state, directory) {
      if (directory !== "..") {
        state.directoryBreadcrumbs.push(directory);
      }
    },
    [SET_CURRENT_DIRECTORY](state, payload) {
      state.currentDirectory = payload;
    },
    [REMOVE_BREADCRUMB](state, index) {
      index = index === 0 ? index : index + 1;
      state.directoryBreadcrumbs.splice(index);
    },
    [RESET_BREADCRUMBS](state) {
      state.directoryBreadcrumbs = [];
    },
    [RENAME_DIRECTORY_OR_FILE](state, payload) {
      state.files = payload;
    },
    [DELETE_DIRECTORY_OR_FILE](state, payload) {
      delete state.files[payload];
    }
  }
};
