import ApiService from "@/core/services/api.service";
import { BaseService } from "@/components/Tools/baseService";
const url = process.env.VUE_APP_API_ADMIN;

class Forms extends BaseService {
  getScmaForm(appName, params = {}) {
    return ApiService.post(url + "/apps/form/scma/" + appName, params);
  }

  getWatchdogForm() {
    return ApiService.get(url + "/apps/form/watchdog/");
  }
}

export default new Forms();
