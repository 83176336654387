import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: "#5b64ee",
        secondary: "#9BABBD",
        tertiary: "#F1F3FF",
        accent: "#FF3553",
        error: "#F64E60",
        info: "#8950FC",
        success: "#1BC5BD",
        warning: "#FFA800",
        background: "#F2F5FA"
      }
    }
  },
  icons: {
    iconfont: "fa"
  }
});
