import Vue from "vue";

export const SET_MAPPING = "setMapping";
export const CLEAR_MAPPING = "clearMapping";

export const SET_MAPPING_CONNECTIONS = "setMappingConnections";
export const ADD_MAPPING_CONNECTION = "addMappingConnection";
export const REMOVE_MAPPING_CONNECTION = "removeMappingConnection";

export const UPDATE_MAPPING_FIELD_TRANSFORMER = "updateMappingFieldTransformer";
export const UPDATE_MAPPING_FIELD_TRANSFORMERS =
  "updateMappingFieldTransformers";
export const ADD_MAPPING_FIELD_TRANSFORMERS = "addMappingFieldTransformers";
export const REMOVE_MAPPING_FIELD_TRANSFORMERS =
  "removeMappingFieldTransformers";

export const SET_TRANSFORMERS = "setTransformers";
export const CLEAR_TRANSFORMERS = "clearTransformers";

export const SET_CONFIG_VALUES = "setConfigValues";

export const SET_MAPPING_RESULT = "setMappingResult";
export const CLEAR_MAPPING_RESULT = "clearMappingResult";

export const SET_COLLECTION_TRANSFORMER_ID = "setCollectionTransformerId";

export const SET_DATA_SETS = "setDataSets";
export const REMOVE_MAPPING_FIELDS = "removeMappingFields";
export const SET_MAPPING_WORK_NOTE = "setMappingWorkNote";

const MAPPING_STRUCTURE = {
  mappingConnections: [],
  mappingFieldTransformers: [],
  source: {
    fields: []
  },
  target: {
    fields: []
  }
};

let connectionsIndex = 0;

export default {
  namespaced: true,
  state: {
    mapping: MAPPING_STRUCTURE,
    transformers: [],
    mappingResult: {},
    configValues: [],
    connections: {},
    connectionsByField: {},
    collectionTransformerIds: {
      Collection: null,
      CollectionFilter: null
    },
    dataSets: []
  },
  getters: {
    mapping: state => {
      let mapping = Object.assign({}, state.mapping);
      mapping.mappingConnections = Object.values(state.connections);
      return mapping;
    },
    mappingConnections: state => {
      return Object.values(state.connections);
    },
    findMappingConnection: state => (sourceId, targetId) => {
      return Object.values(state.connections).find(
        c => c.source_field_id === sourceId && c.target_field_id === targetId
      );
    },
    mappingConnectionsBySourceField: state => id => {
      let connections = state.connectionsByField[id];
      return connections.map(index => state.connections[index]);
    },
    mappingConnectionsByTargetField: state => id => {
      let connections = state.connectionsByField[id];
      return connections.map(index => state.connections[index]);
    },
    mappingFieldTransformers: state => {
      return state.mapping.mappingFieldTransformers;
    },
    mappingFieldTransformersById: state => id => {
      return state.mapping.mappingFieldTransformers.filter(
        c => c.target_field_id === id
      );
    },
    source: state => {
      return state.mapping.source;
    },
    sourceFields: state => {
      return state.mapping.sourceFields;
    },
    findSourceField: (state, getters) => id => {
      return getters.sourceFields.find(field => field.id === id);
    },
    target: state => {
      return state.mapping.target;
    },
    targetFields: state => {
      return state.mapping.targetFields;
    },
    findTargetField: (state, getters) => id => {
      return getters.targetFields.find(field => field.id === id);
    },
    transformers: state => {
      return state.transformers;
    },
    transformerById: state => id => {
      return state.transformers.find(t => t.id === id);
    },
    mappingResult: state => {
      return state.mappingResult;
    },
    findField: (state, getters) => id => {
      let sourceField = getters.findSourceField(id);
      let targetField = getters.findTargetField(id);
      return sourceField ?? targetField;
    },
    structureByField: (state, getters) => id => {
      let sourceField = getters.findSourceField(id);
      let targetField = getters.findTargetField(id);
      return sourceField ? "source" : targetField ? "target" : undefined;
    },
    variableName: (state, getters) => id => {
      let field = getters.findField(id);
      return getters.structureByField(id) + "." + field.full_name;
    },
    configValues: state => {
      return state.configValues;
    },
    collectionTransformerIds: state => {
      return Object.values(state.collectionTransformerIds);
    },
    collectionTransformerId: state => name => {
      return state.collectionTransformerIds[name];
    },
    dataSets: state => {
      return state.dataSets;
    }
  },
  actions: {
    [SET_MAPPING](context, payload) {
      context.commit(SET_MAPPING, payload);
      context.dispatch(SET_MAPPING_CONNECTIONS, payload.mappingConnections);
    },
    [CLEAR_MAPPING](context) {
      context.commit(CLEAR_MAPPING);
    },
    [SET_TRANSFORMERS](context, payload) {
      context.commit(SET_TRANSFORMERS, payload);
    },
    [CLEAR_TRANSFORMERS](context, payload) {
      context.commit(CLEAR_TRANSFORMERS, payload);
    },
    [SET_MAPPING_CONNECTIONS](context, payload) {
      context.commit(SET_MAPPING_CONNECTIONS, payload);
    },
    [ADD_MAPPING_CONNECTION](context, payload) {
      context.commit(ADD_MAPPING_CONNECTION, payload);
    },
    [REMOVE_MAPPING_CONNECTION](context, payload) {
      context.commit(REMOVE_MAPPING_CONNECTION, payload);
    },
    [UPDATE_MAPPING_FIELD_TRANSFORMER](context, payload) {
      context.commit(UPDATE_MAPPING_FIELD_TRANSFORMER, payload);
    },
    [UPDATE_MAPPING_FIELD_TRANSFORMERS](context, payload) {
      let oldTransformers = context.getters.mappingFieldTransformersById(
        payload.id
      );
      context.commit(REMOVE_MAPPING_FIELD_TRANSFORMERS, oldTransformers);
      context.commit(ADD_MAPPING_FIELD_TRANSFORMERS, payload.transformers);
    },
    [SET_CONFIG_VALUES](context, payload) {
      context.commit(SET_CONFIG_VALUES, payload);
    },
    [SET_MAPPING_RESULT](context, payload) {
      context.commit(SET_MAPPING_RESULT, payload);
    },
    [CLEAR_MAPPING_RESULT](context) {
      context.commit(CLEAR_MAPPING_RESULT);
    },
    [SET_COLLECTION_TRANSFORMER_ID](context, payload) {
      context.commit(SET_COLLECTION_TRANSFORMER_ID, payload);
    },
    [SET_DATA_SETS](context, payload) {
      context.commit(SET_DATA_SETS, payload);
    },
    [REMOVE_MAPPING_FIELDS](context, payload) {
      context.commit(REMOVE_MAPPING_FIELDS, payload);
    },
    [SET_MAPPING_WORK_NOTE](context, payload) {
      context.commit(SET_MAPPING_WORK_NOTE, payload);
    }
  },
  mutations: {
    [SET_MAPPING](state, payload) {
      state.mapping = payload;
      state.mapping.sourceFields.forEach(
        field => (state.connectionsByField[field.id] = [])
      );
      state.mapping.targetFields.forEach(
        field => (state.connectionsByField[field.id] = [])
      );
      state.mapping.mappingFields = state.mapping.sourceFields.concat(
        state.mapping.targetFields
      );
    },
    [CLEAR_MAPPING](state) {
      Object.assign(state.mapping, MAPPING_STRUCTURE);
    },
    [SET_TRANSFORMERS](state, payload) {
      state.transformers = payload;
    },
    [CLEAR_TRANSFORMERS](state) {
      state.transformers = [];
    },
    [SET_MAPPING_CONNECTIONS](state, payload) {
      state.connections = { ...payload };
      connectionsIndex = payload.length;
      let byField = Object.assign({}, state.connectionsByField);
      Object.keys(state.connections).forEach(key => {
        let c = state.connections[key];
        byField[c.source_field_id].push(key);
        byField[c.target_field_id].push(key);
      });
      state.connectionsByField = byField;
    },
    [ADD_MAPPING_CONNECTION](state, payload) {
      let index = connectionsIndex;
      Vue.set(state.connections, index, payload);

      Vue.set(state.connectionsByField, payload.source_field_id, [
        ...state.connectionsByField[payload.source_field_id],
        index
      ]);
      Vue.set(state.connectionsByField, payload.target_field_id, [
        ...state.connectionsByField[payload.target_field_id],
        index
      ]);
      connectionsIndex++;
    },
    [REMOVE_MAPPING_CONNECTION](state, payload) {
      let key = parseInt(
        Object.keys(state.connections).find(
          key => state.connections[key] === payload
        )
      );
      Vue.delete(state.connections, key);
      state.connectionsByField[payload.source_field_id] =
        state.connectionsByField[payload.source_field_id].filter(
          index => parseInt(index) !== key
        );
      state.connectionsByField[payload.target_field_id] =
        state.connectionsByField[payload.target_field_id].filter(
          index => parseInt(index) !== key
        );
    },
    [ADD_MAPPING_FIELD_TRANSFORMERS](state, payload) {
      state.mapping.mappingFieldTransformers.push(...payload);
    },
    [REMOVE_MAPPING_FIELD_TRANSFORMERS](state, payload) {
      payload.forEach(t => {
        let index = state.mapping.mappingFieldTransformers.indexOf(t);
        if (index < 0) return;
        state.mapping.mappingFieldTransformers.splice(index, 1);
      });
    },
    [UPDATE_MAPPING_FIELD_TRANSFORMER](state, payload) {
      let transformer = state.mapping.mappingFieldTransformers.find(
        t => t.id === payload.id
      );
      let index = state.mapping.mappingFieldTransformers.indexOf(transformer);
      if (index === -1) return;
      state.mapping.mappingFieldTransformers[index] = payload;
    },
    [SET_CONFIG_VALUES](state, payload) {
      state.configValues = payload;
    },
    [SET_MAPPING_RESULT](state, payload) {
      state.mappingResult = payload;
    },
    [CLEAR_MAPPING_RESULT](state) {
      state.mappingResult = {};
    },
    [SET_COLLECTION_TRANSFORMER_ID](state, payload) {
      state.collectionTransformerIds[payload.key] = payload.value;
    },
    [SET_DATA_SETS](state, payload) {
      state.dataSets = payload;
    },
    [REMOVE_MAPPING_FIELDS](state, payload) {
      let entriesToDelete = [
        "updated_by_user",
        "mappingFields",
        "source",
        "sourceFields",
        "target",
        "targetFields"
      ];

      for (let i = 0; i < entriesToDelete.length; i++) {
        delete payload[entriesToDelete[i]];
      }

      state.mapping = payload;
    },
    [SET_MAPPING_WORK_NOTE](state, payload) {
      state.mapping.work_note = payload;
    }
  }
};
