export const companyData = {
  accounting: "Buchhaltung",
  accountingInvoicesEmail: "E-Mail für Rechnungen",
  accountingVatNumber: "Umsatzsteuer-ID",
  baseData: "Stammdaten",
  billing: "Rechnungsadresse",
  billingAddress1: "Adresszeile 1",
  billingAddress2: "Adresszeile 2",
  billingAddress3: "Adresszeile 3",
  billingCity: "Ort",
  billingCountry: "Land",
  billingStreet: "Straße, Hausnummer",
  billingZipcode: "PLZ",
  companyData: "Unternehmensdaten",
  companyUpdate: "Unternehmensdaten bearbeiten",
  companyUpdated: "Unternehmensdaten aktualisiert",
  contactData: "Kontaktdaten",
  contactEmail: "E-Mail Adresse",
  contactFax: "Fax",
  contactPhone: "Telefon",
  contactWebsite: "Website",
  id: "ID",
  language: "Sprache",
  logo: "Logo",
  name: "Firmenname",
  nameEnter: "Namen eingeben",
  nameShort: "Kürzel",
  bankDetails: "Bankverbindung",
  accountHolder: "Kontoinhaber",
  bankName: "Bankname",
  iban: "IBAN",
  swiftBic: "SWIFT/BIC",
  uploadLogo: "Logo hochladen",
  removeLogo: "Logo entfernen",
  cropLogo: "Logo zuschneiden"
};
