export const validation = {
  unique: {
    email: "Diese E-Mail wurde bereits vergeben",
    name: "Dieser Name wurde bereits vergeben",
    workspace: "Arbeitsbereichsdomäne '{domain}' wurde bereits vergeben"
  },
  required: {
    email: "Die E-Mail-Adresse ist erforderlich",
    name: "Der Name ist erforderlich",
    user: "Der Benutzer ist erforderlich",
    firstname: "Der Vorname ist erforderlich",
    lastname: "Der Nachname ist erforderlich",
    display_name: "Der Anzeigename ist erforderlich",
    recipient: "Der Empfänger ist erforderlich",
    mailable_classname: "Die E-Mail Klasse ist erforderlich",
    code: "Der Code ist erforderlich",
    password: "Das Passwort ist erforderlich",
    ipAddress: "Die IP-Adresse ist erforderlich",
    label: "Die Bezeichnung ist erforderlich",
    queryName: "Der Abfragename ist erforderlich",
    general: "{label} ist erforderlich",
    token: "Der Token ist erforderlich",
    technicalName: "Technischer Name ist erforderlich",
    route: "Die Route ist erforderlich",
    ipAddresses: "Die IP-Adressen sind erforderlich",
    title: "Der Titel ist erforderlich",
    workflowComment: "Kommentarinhalt inhalt ist erforderlich"
  },
  email: {
    email: "Die E-Mail Adresse ist ungültig",
    recipient: "Der Empfänger ist ungültig"
  },
  phone: {
    phone: "Die Telefonnummer ist ungültig."
  },
  pleaseCorrectData:
    "Die angegebenen Daten waren ungültig. Bitte korrigieren Sie die rot markierten Felder",
  minChars: "Bitte geben Sie mindestens {chars} Zeichen ein",
  maxChars: "Bitte geben Sie höchstens {chars} Zeichen ein",
  samePassword: "Passwörter müssen identisch sein.",
  dates: "Das Enddatum muss größer als das Startdatum sein.",
  alphaNum: "Der Wert muss alphanumerisch sein",
  upLowCase:
    "Der Wert muss mindestens einen Groß- und einen Kleinbuchstaben enthalten",
  dataStructure: {
    classname: {
      validation: {
        unique: "Der Klassenname existiert bereits ! Muss einzigartig sein"
      }
    },
    tablename: {
      validation: {
        unique: "Der Tabellenname existiert bereits ! Muss einzigartig sein"
      }
    },
    label: {
      validation: {
        unique: "Bezeichnung erforderlich"
      }
    }
  }
};
