import Auth from "@/components/General/Auth/auth";
import router from "@/router";
import Swal from "sweetalert2";
import i18n from "@/core/plugins/vue-i18n";

export const AppAuth = error => {
  if (typeof error.type !== "undefined" && error.status === 401) {
    Swal.fire({
      title: i18n.t("general.sessionExpiredTitle"),
      text: i18n.t("general.sessionExpiredMessage"),
      icon: "error"
    });
    Auth.logout();
    router.push({ name: "login" });
  }
};
