export const settings = {
  name: "Name",
  settings: "Settings",
  settingCreate: "Create setting",
  nameEnter: "Enter name",
  label: "Label",
  type: "Type",
  value: "Value",
  settingCreated: "Setting created",
  settingUpdate: "Update setting",
  settingUpdated: "Setting updated",
  settingsUpdated: "Settings updated",
  settingDeleteMessage:
    'Do you really want to delete the "{setting}"? This process is irreversible.',
  settingDeleteTitle: "Delete setting",
  settingDeleted: "Setting deleted successfully",
  mailTemplates: "Mail templates",
  numberRanges: "Number ranges",
  tenantNumberRange: "Number range for customer numbers",
  partnerNumberRange: "Number range for partners numbers",
  loginSecurity: "Login Security",
  settingNameUpdated: 'The "{setting}" has been updated',
  settingNameNotUpdated: 'The "{setting}" cannot be updated',
  languages: "Languages",
  countries: "Countries",
  securityMaxAttempts: "Maximum tries until throttling",
  securityLockMinutesAfterMaxAttempts: "Throttled for X minutes",
  securityMaxAttemptsBeforeLock: "Max attempts before lock the account",
  securityCheckIp: "Block all ips except those in the User-Whitelist",
  securityCheckRouteIp: "Block all ips except those in the Route-Whitelist",
  securityTwoFaCheck: "Two-Factor authentication activated",
  securityTwoFaCodeExpireAfterMinutes:
    "Validity in minutes for the two factor authentication code",
  twoFaDeviceExpireAfterDays: "Validity in days for the stored device",
  workspaceDomain: "Workspace domain",
  archiving: "Archiving",
  keepLogForDays: "Archive logs",
  day: "Day",
  days: "Days",
  month: "Month",
  months: "Months",
  zipLogArchives: "Zip archive files after a month",
  keepLogArchiveForMonths: "Keep log archives",
  processIterationNumberRange: "Number range process iterations",
  processLogNumberRange: "Number range process logs",
  supervisorManagment: "Supervisor management",
  editIps: "Edit IP-Addresses",
  dailySummary: "Daily summary",
  dailySummaryRecipient: "E-Mail recipient",
  dailySummaryAt: "Time",
  sendDailySummary: "Activate summary",
  oneOrMoreSettingsCannotBeUpdated: "One or more settings cannot be updated."
};
