export const dataExplorer = {
  confirm: "Confirm",
  cancel: "Cancel",
  dataExplorer: "Data Explorer",
  dataExplorerResources: "Data Explorer - Resources",
  dataStructures: "Data Structures",
  dataSets: "Data Sets",
  dataStructure: "Data Structure",
  dataSet: "Data Set",
  noDescription: "No description available",
  details: "Details",
  preview: "Preview",
  schema: "Schema",
  entriesCount: "Records",
  dirtyEntriesCount: "Dirty records",
  lastUpdated: "Last change",
  dataLastUpdated: "Last data change",
  editDataSet: "Edit DataSet",
  editDataStructure: "Edit DataStructure",
  truncateDataStructure: "Truncate DataStructure",
  importData: "Import data from json",
  importDataSuccess: "Data imported successfully",
  importDataError: "Error occurred during data import",
  setCleanDataStructure: "Set all records clean",
  setDirtyDataStructure: "Set all records dirty",
  setCleanDataStructureEntry: "Set this record clean",
  setDirtyDataStructureEntry: "Set this record dirty",
  truncateText:
    'Are you sure you want to empty the "{name}" DataStructure? This process is irreversible.',
  truncateTitle: "Clear DataStructure",
  setCleanText:
    'Are you sure you want to set all records of DataStructure "{name}" to processed? This process is irreversible.',
  setCleanTitle: "Edit DataStructure",
  setCleanSuccess: "Entries set to clean",
  setDirtyText:
    'Are you sure you want to set all records of DataStructure "{name}" to unprocessed? This process is irreversible.',
  setDirtyTitle: "Edit DataStructure",
  setDirtySuccess: "Entries set to dirty",
  filter: "Filter",
  dirty: "Dirty",
  clean: "Clean",
  noFilter: "Remove filter",
  openDetails: "View data",
  deletedSuccess: "All entries have been successfully deleted",
  dataStructureFromRequest: "DataStructure (Request)",
  massDeleteSuccess: "The selected entries were successfully deleted."
};
