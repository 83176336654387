export const textTemplates = {
  textTemplates: "Text-Templates",
  type: "Typ",
  textTemplateCreate: "Text-Template erstellen",
  textTemplateDeleted: "Text-Template wurde gelöscht",
  deleteTextTemplateTitle: 'Text-Template "{title}" löschen?',
  deleteTextTemplateText:
    "Möchten Sie das Text-Template wirklich endgültig löschen? Dieser Vorgang kann nicht rückgängig gemacht werden.",
  textTemplateSaved: "Text-Template wurde gespeichert",
  textTemplateUpdated: "Text-Template wurde aktualisiert",
  textTemplateLabel: "Bezeichnung",
  textTemplateSave: "Speichern",
  variables: "Parameter",
  edit: 'Text-Template "{name}" bearbeiten',
  variablesHelp:
    "Parameter können im Text-Template genutzt werden, um dynamische Daten auszugeben.",
  addVariable: "Parameter hinzufügen",
  detailsBasicConfiguration: "Basiskonfiguration",
  templateSaved: "Template wurde gespeichert!",
  download: "Herunterladen",
  valid: "Datei valide",
  invalid: "Datei nicht valide",
  name: "Name",
  dataStructure: "Datenstruktur",
  projects: "Projekte",
  test: "Testen",
  label: "Bezeichnung",
  parameters: "Parameter",
  parametersAdd: "Parameter hinzufügen",
  noParameters: "Keine Paramter verfügbar.",
  selectDebugData: "Debug Daten auswählen",
  output: "Ausgabe",
  types: {
    csv: "CSV",
    xml: "XML",
    html: "HTML",
    edifact: "EDIFACT",
    datanorm: "Datanorm",
    json: "JSON",
    other: "Sonstige"
  },
  variablesTypes: {
    string: "Text",
    dataStructure: "DataStructure",
    dataStructureList: "DataStructure Liste"
  },
  helpers: {
    twig: {
      twig: "Twig",
      helperFunctions: "Hilfsfunktionen",
      conditions: "Bedingung",
      loop: "Schleife",
      variable: "Variable",
      output: "Ausgabe",
      textOperators: "Text-Operatoren",
      replaceText: "Text ersetzen",
      maskText: "Text maskieren",
      toLowerCase: "Text zu Kleinschreibung",
      toUpperCase: "Text zu Großschreibung",
      trim: "Trim",
      stripHtmlTags: "HTML Tags entfernen",
      encodeUrl: "URL encodieren",
      formatDate: "Datum formatieren",
      numberOperators: "Nummern-Operatoren",
      numberFormat: "Nummer formatieren",
      numberRound: "Nummer runden",
      absolute: "Betrag",
      listOperators: "Listen-Operatoren",
      firstElement: "Erstes Element",
      lastElement: "Letztes Element",
      listLength: "Länge der Liste",
      joinText: "Verknüpfung zu Text",
      mergeLists: "Verknüpfung zweier Listen",
      sliceList: "Teilliste",
      otherOperators: "Sonstige Operatoren",
      countryName: "Ländername",
      currencyName: "Währungsname",
      currencySymbol: "Währungszeichen",
      defaultValue: "Standardwert",
      jsonEncode: "JSON Encode"
    },
    xml: {
      xml: "XML",
      helperFunctions: "Hilfsfunktionen",
      xmlHeader: "XML-Header",
      element: "Element"
    },
    edifact: {
      edifact: "EDIFACT"
    },
    html: {
      html: "HTML",
      helperFunctions: "Hilfsfunktionen",
      htmlFramework: "HTML-Gerüst"
    }
  }
};
