export const product = {
  catalogue: "Katalog",
  categories: "Kategorien",
  categoryAssignmentSelectXentral: "Artikelbaum aus Xentral übernehmen",
  correctState: "Bei Erreichen des richtigen Zahlungs-/ und oder Versandstatus",
  country: "Land des Firmensitzes",
  countryHelp: "In welchem Land ist deine Firma eingetragen ?",
  crossSeller: "Cross-selling Artikel",
  customFieldAssignmentHelp:
    "Hier können Freifelder aus Xentral den entsprechenden Freifeldern des Verkaufskanals zugeordnet werden",
  customFieldAssignmentStrategy:
    "Wie sollen die Freifelder aus Xentral an den Verkaufskanal übergeben werden?",
  customFieldAssignmentStrategyXentral: "Freifelder aus Xentral übernehmen",
  customFieldAssignmentStrategyXentralCustomProperties:
    "Freifelder aus Xentral den im Verkaufskanal verfügbaren Eigenschaften manuell zuordnen",
  customFieldAssignmentStrategyXentralProperties:
    "Freifelder aus Xentral automatisch in verfügbare Eigenschaften schreiben",
  customFieldPropertiesAssignmentHelp:
    "Hier können Freifelder aus Xentral den entsprechenden Eigenschaften des Verkaufskanals zugeordnet werden",
  deleteProducts: "Artikel automatisch in Verkaufskanal löschen?",
  deleteProductsHelp:
    "Hier kannst du aktivieren, dass Artikel, die du in Xentral löschst, auch im Verkaufskanal gelöscht werden. Wenn ein Matrixartikel gelöscht wird, werden alle zugehörigen Varianten im Verkaufskanal gelöscht.",
  exemptedTaxCollectionId: "Collection für befreiten Steuersatz",
  exemptedTaxCollectionIdHelp:
    "In Shopify werden Artikel über Collections einem befreiten Steuersatz zugewiesen. Diese Collection muss im Verkaufskanal angelegt und hier ausgewählt werden.",
  images: "Bilder",
  importNettoPrices: "Netto Preise übertragen",
  importNettoPricesHelp:
    "Wenn du Nettopreise statt Bruttopreise in den Verkaufskanal übertragen möchtest, kannst das hier auswählen.",
  layout: "Auswahl der Artikelansicht",
  layoutHelp:
    "Hier kann ausgewählt werden, welche Ansicht für die Artikelseite im Verkaufskanal verwendet werden soll. Wird keine Ansicht ausgewählt, wird die Standardansicht des Verkaufskanals verwendet.",
  mediaFolder: "Auswahl Medienordner",
  mediaFolderHelp:
    "Hier kannst du auswählen, in welchem Ordner die Artikelbilder im Verkaufskanal abgelegt werden.",
  mediaUpdateStrategy: "Bilder Update Strategie",
  mediaUpdateStrategyCreate: "Ich möchte Bilder nur anlegen",
  mediaUpdateStrategyCreateAndDelete: "Ich möchte Bilder anlegen und löschen",
  mediaUpdateStrategyHelp:
    "Hier kannst du entscheiden, wie bei der Übertragung der Bilder vorgegangen werden soll.",
  metaTextsActive: "Meta Titel und Beschreibung übertragen?",
  metaTextsActiveHelp:
    "Hier kannst du festlegen, ob die Meta bzw. Verkaufskanal Texte (Titel und Beschreibung) zum Verkaufskanal übertragen werden sollen. Diese werden in dafür vorgesehene Meta-Text bzw. SEO-Felder geschrieben.",
  metaTextsKeywordsActive: "Meta Titel, Beschreibung und Keywords übertragen?",
  metaTextsKeywordsActiveHelp:
    "Hier kannst du festlegen, ob die Meta bzw. Verkaufskanal Texte (Titel, Beschreibung und Keywords) zum Verkaufskanal übertragen werden sollen. Diese werden in dafür vorgesehene Meta-Text bzw. SEO-Felder geschrieben.",
  newUpdate: "Neu anlegen / updaten von Artikeln",
  newUpdateHelp:
    "Sollen Artikel neu angelegt werden, wenn noch nicht im Verkaufskanal vorhanden und/ oder geupdated werden, wenn bereits im Verkaufskanal vorhanden?",
  newUpdateNew: "Nur neu anlegen",
  newUpdateNewUpdate: "Neu anlegen und updaten",
  newUpdateUpdate: "Nur updaten",
  orderInput: "Bei Auftragseingang",
  productAssignmentHelp:
    "Welche Artikel sollen von Xentral in den Verkaufskanal importiert werden?",
  productAssignmentSelect:
    "Wie sollen die Artikel aus Xentral den Artikeln im Verkaufskanal zugeordnet werden?",
  productAssignmentSelectXentral: "Artikelnummern aus Xentral übernehmen",
  propertyAssignmentStrategyXentralCustomFields:
    "Eigenschaften aus Xentral automatisch in verfügbare Freifelder schreiben",
  propertyAssignmentStrategyXentralCustomCustomFields:
    "Eigenschaften aus Xentral den im Verkaufskanal verfügbaren Freifeldern manuell zuordnen",
  properties: "Eigenschaften",
  propertyCustomFieldsAssignmentHelp:
    "Hier können Eigenschaften aus Xentral den entsprechenden Freifeldern des Verkaufskanals zugeordnet werden",
  propertiesVisible: "Eigenschaften sichtbar machen?",
  propertiesVisibleHelp:
    'Hier kannst du festlegen, ob übertragene Eigenschaften auf der Registerkarte "Zusätzliche Informationen" auf der Artikelseite sichtbar sein sollen.',
  reducedTaxClassId: "Steuerklasse für reduzierte Steuersätze",
  reducedTaxClassIdHelp: "Steuerklasse für reduzierte Steuersätze",
  reducedTaxRateCollectionId: "Collection für reduzierten Steuersatz",
  reducedTaxRateCollectionIdHelp:
    "In Shopify werden Artikel über Collections einem reduzierten Steuersatz zugewiesen. Diese Collection muss im Verkaufskanal angelegt und hier ausgewählt werden.",
  salesChannel: "Sales Channels",
  salesChannelHelp:
    "In {appName} muss ein Sales Channel angelegt werden, in dem du deine Artikel verkaufen willst. Diesen musst du hier auswählen.",
  selectProduct: "Auswahl Artikel",
  selectProductHelp: "Welche Artikel sollen übertragen werden?",
  stockLessZeroBuyable:
    "Sollen Artikel mit Bestand < 0 im Verkaufskanal kaufbar sein?",
  stockLessZeroShown:
    "Sollen Artikel mit Bestand < 0 im Verkaufskanal angezeigt werden?",
  stockLessZeroTransferred: "Sollen Artikel mit Bestand < 0 übertragen werden?",
  tags: "Tags",
  taxClass: "Steuerklasse",
  taxRateFree: "Steuersatz (befreit)",
  taxRateFreeHelp:
    "Dieser Steuersatz wird verwendet, um die Preise für steuerbefreite Artikel vom Verkaufskanal zu Xentral zu übertragen.",
  taxRateNormal: "Steuersatz (normal)",
  taxRateNormalHelp:
    "Dieser Steuersatz wird verwendet, um die Preise von normal besteuerten Artikeln aus dem Verkaufskanal in Brutto umzurechnen und in Xentral zu übertragen.",
  taxRateReduced: "Steuersatz (ermäßigt)",
  taxRateReducedHelp:
    "Dieser Steuersatz wird verwendet, um die Preise von reduziert besteuerten Artikeln aus dem Verkaufskanal in Brutto umzurechnen und in Xentral zu übertragen.",
  taxes: "Steuern",
  updateStrategy: "Update Strategie",
  updateStrategyCreate: "Ich möchte nur neue Artikel anlegen",
  updateStrategyCreateAndUpdate:
    "Ich möchte bestehende Artikel updaten und neue Artikel automatisch anlegen",
  updateStrategyHelp:
    "Hier kannst du entscheiden, wie du Artikel von Xentral zu deinem Verkaufskanal übertragen willst. Hinweis: Wenn du Artikel anlegen möchtest, müssen mindestens Stammdaten übertragen werden.",
  updateStrategyUpdate: "Ich möchte nur bestehende Artikel updaten",
  variants: "Varianten (exkl. Preise und Bestände)",
  useNetWeight: "Nettogewicht übertragen?",
  useNetWeightHelp:
    "Hier kannst du festlegen, ob das Gewicht oder das Nettogewicht zum Verkaufskanal übertragen werden soll.",
  warehouseCountry: "Standort des Xentral Lagers",
  warehouseCountryHelp: "In welchem Land ist dein Xentral Lagerhaus?",
  articleExported: "Artikel exportiert"
};
