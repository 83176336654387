export const assignments = {
  assignments: "Assignment table",
  assignmentsCreate: "Create assignment table",
  assignmentsEdit: "Edit assignment table",
  assignmentsName: "Name",
  assignmentExportSuccess: "Successfully exported assignment table: '{name}'",
  assignmentImportSuccess: "The assignment table was imported successfully.",
  description: "Description",
  values: "Values",
  key: "Key",
  value: "Value",
  addValue: "Add values",
  assignmentsSave: "Save",
  assignmentsSaved: "Assignment table has been saved",
  deleteAssignmentsTitle:
    'Are you sure you want to delete the assignment tabel "{title}"?',
  deleteAssignmentsText:
    "Do you really want to permanently delete the assignment table? This action can not be undone.",
  assignmentsDeleted: "Assignment table was deleted",
  projects: "Projects",
  export: "Export",
  import: "Import"
};
