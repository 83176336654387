export const workflowDesigner = {
  active: "Aktiv",
  activeCondition: "Aktiv Bedingung",
  advanced: "Advanced",
  backToOverview: "Zurück zur Übersicht",
  backToReporting: "Zurück zum Reporting",
  baseData: "Basisinformationen",
  billingFrom: "Abrechnung seit",
  billingTo: "Abrechnung bis",
  centerView: "Workflow zentrieren",
  childProcesses: "Kind Prozesse",
  className: "Klassenname",
  classNameHelp:
    "Eindeutige technische Bezeichnung des Workflows, z.B. 'ArticleImport'.",
  classNameHint: "Keine Leer- oder Sonderzeichen erlaubt.",
  clipboard: "Zwischenablage",
  clipboardClear: "Zwischenablage leeren",
  columnComments: "Kommentare",
  columnConfiguration: "Konfiguration",
  columnData: "Daten",
  columnElements: "Elemente",
  columnLogging: "Logging",
  columnParameters: "Parameter",
  columnWorkflow: "Workflow",
  collapseAll: "Alle öffnen",
  collapseNone: "Alle schließen",
  comment: "Kommentar",
  contentIdentifier: "Content Identifier",
  contentIdentifierHelp: "Was repräsentiert der Content Identifier?",
  copyParameter: "Hier klicken um die Parameter zu kopieren",
  create: "Workflow hochladen",
  createDataStructureJsonError: "{name} enthält kein gültiges JSON",
  cropIcon: "Icon zuschneiden",
  cropLogo: "Grafik zuschneiden",
  dangerZone: "Gefahrenbereich",
  dangerZoneDeleteTitle: "Workflow löschen",
  dangerZoneDeleteText:
    "Sind Sie sicher, dass Sie den Workflow '{workflow}' löschen möchten? Dieser Vorgang kann nicht rückgängig gemacht werden.",
  dangerZoneText:
    "Sie befinden sich im Gefahrenbereich. Alle Aktionen, die Sie hier vornehmen sind unwiderruflich.",
  daysSelected: "Tage ausgewählt",
  deleteWorkflow: "Workflow löschen",
  deleteWorkflowSubtitle: "Das Löschen eines Workflows ist unwiderruflich.",
  description: "Beschreibung",
  dependency: "Abhängigkeiten",
  designer: "Designer",
  designerProcess: "Designer Prozess",
  dontConsiderInBilling: "Nicht in Abrechnung Berücksichtigen",
  dropAreaText: "Legen Sie eine Grafik zum Hochladen hier ab",
  edit: "Workflow konfigurieren",
  dropError: "Element kann hier nicht platziert werden",
  editGroup: "Gruppenname ändern",
  deleteGroup: "Gruppe löschen",
  deleteGroupTitle: "Workflowgruppe löschen",
  deleteGroupText:
    'Soll die Workflowgruppe "{title}" endgültig gelöscht werden? Der Vorgang ist irreversibel.',
  elementsApiGateway: "API-Gateway",
  elementsCommunication: "Kommunikation",
  elementsHelper: "Helper",
  elementsConnector: "Konnektoren",
  elementsData: "Datenspeicherung",
  elementsMapping: "Mappings",
  elementsOperator: "Operatoren",
  elementsTrigger: "Trigger",
  elementsDataStructures: "Data Structures",
  elementsFiles: "Dateien",
  elementsReporting: "Reporting",
  elementsTransformer: "Transformer",
  elementsClipboard: "Zwischenablage",
  execute: "Ausführen",
  executeImmediately: "Workflow sofort ausführen",
  externallyTriggerable: "Extern triggerbar",
  externallyTriggerableHelp:
    "Wählen Sie hier aus, ob der Workflow per öffentlicher URL ausgelöst werden darf.",
  fromElement: "vom Element {value}",
  fromJob: "von Job {value}",
  hidePreview: "Vorschau schließen",
  icon: "Icon",
  jobs: "Jobs",
  label: "Bezeichnung",
  logo: "Grafik",
  modeBuild: "Konfiguration",
  modeCode: "Code",
  modeDebug: "Debug",
  modePlanning: "Planung",
  name: "Bezeichnung",
  noChildProcesses: "Dieser Workflow hat keine Kindprozesse zugewiesen.",
  noClipboardElements:
    "Es wurden noch keine Elemente in die Zwischenablage kopiert.",
  noElementsOfType:
    "Es wurden bisher noch keine Elemente dieses Typs angelegt.",
  none: "Keinen",
  noJobs: "Keine Jobs ausgeführt",
  noProject: "Keinem Projekt zugeordnet",
  noSelectData: "Keine Daten vorhanden",
  openCode: "Workflow Code Editor öffnen",
  openDesigner: "Workflow Designer öffnen",
  openJournal: "Journal öffnen",
  openReporting: "Reporting öffnen",
  others: "(+ {value} andere)",
  parameter: "Parameter",
  parametersPreview: "Vorschau",

  jobStatusInfo: "Info",
  jobStatusStopped: "Job erfolgreich abgeschlossen",
  jobStatusWarning: "Jobs teilweise erfolgreich",
  jobStatusError: "Job fehlerhaft",
  jobStatusPending: "Job wartet auf Ausführung",
  jobStatusWorking: "Job wird ausgeführt",
  jobStatusAborted: "Job wurde abgebrochen",

  logStatusInfo: "Info",
  logStatusStopped: "Gestoppt",
  logStatusWarning: "Warnung",
  logStatusError: "Error",
  logStatusPending: "Wartend",
  logStatusWorking: "Arbeitend",
  logStatusAborted: "Abgebrochen",

  projectChoose: "Projekt auswählen",
  properties: "Eigenschaften",
  operatorBranch: "Verzweigung",
  operatorChange: "Operator ändern",
  operatorConnection: "Einfache Verbindung",
  queue: "Queue",
  queueChoose: "Queue auswählen",
  queueChooseHelp:
    "Auf welcher Queue soll der Workflow standardmäßig ausgeführt werden?",
  recipientEmail: "Empfänger E-Mail",
  removeAll: "Alle Elemente entfernen",
  removeEndPoint: "Endpunkt entfernen",
  removeIcon: "Icon entfernen",
  removeLogo: "Grafik entfernen",
  reportingHeader: "Reporting Workflow {workflow}",
  reportingHeaderDetail: "Jobdetails zu {workflow} vom {date}",
  reset: "Zurücksetzen",
  saveAndTest: "Speichern und testen",
  setParameters: "Parameter setzen",
  searchElements: "Elemente durchsuchen...",
  searchElementsNotFound:
    "Es wurden leider keine Elemente gefunden, die zu Ihrem Suchbegriff gepasst haben.",
  selectNextElement: "Nächstes Element",
  selectPlaceholder: "Auswählen...",
  selectWidget: "Bitte wählen Sie zuerst ein Element im Designer aus.",
  setEndPoint: "Endpunkt festlegen",
  share: "Exportieren / Teilen",
  shared: "Workflow exportiert!",
  showParameter: "Parameter anzeigen",
  startProcessFirst: "Workflow ausführen um Logs anzuzeigen.",
  tabCode: "Code",
  tabCodePhp: "PHP Code",
  tabStructure: "Struktur",
  tabStructureYaml: "YAML Struktur",
  tabVisual: "Visuell",
  testWorkflowTableTitle: "Letzte Jobs mit Parametern",
  testWorkflowTableDescription:
    "Fügen Sie hier die Parameter ein, mit denen der Workflow gestartet werden soll.",
  timeTooltip:
    "Verwenden Sie das Format <b>2d 5h 30m</b><ul>\n" +
    "  <li>d = Tage</li>\n" +
    "  <li>h = Stunden (0-23)</li>\n" +
    "  <li>m = Minuten (0-59)</li>\n" +
    "</ul>",
  token: "Token",
  tokenHelp:
    "Mit Hilfe eines oder mehrerer Token lässt sich eine eindeutige URL generieren, über welche der Besitzer vom System authentifiziert werden und somit den Workflow starten kann.",
  trigger: "Trigger",
  triggerChoose: "Trigger auswählen",
  triggerChooseHelp: "In welchem Intervall soll der Workflow ausgelöst werden?",
  triggerConfig: "Trigger konfigurieren",
  triggerDisabledText:
    "Dieser Workflow wird durch einen vorausgehenden Workflow ausgelöst.",
  triggerLink: "Trigger-Link",
  type: "Datensatz-Typ",
  typeHelp: "Von welchem Typ ist der im Workflow verarbeitete Datensatz?",
  uploadIcon: "Icon hochladen",
  uploadLogo: "Grafik hochladen",
  workflowCancel: "Jobs abbrechen",
  workflow: "Workflow",
  workflowCreate: "Workflow erstellen",
  workflowGroupCreate: "Workflowgruppe erstellen",
  workflowImportHelp: "Workflow importieren",
  workflowExportAs: "Workflow exportieren als",
  workflowExport: "Exportieren",
  workflowExportTitle: "Workflow exportieren",
  workflowImportSuccess:
    "Workflow wurde erfolgreich importiert. Der Workflow muss jedoch noch konfiguriert werden",
  workflowImportError: "Es gab einen Fehler beim importieren des Workflows",
  workflowExportSuccess: "Workflow: '{name}' wurde erfolgreich exportiert",
  workflowExportError: "Es gab einen Fehler beim exportieren des Workflows",
  workflowDesigner: "Workflow Designer",
  workflowDesignerTable: "Workflow-Übersicht",
  workflowSaved: "Workflow wurde gespeichert!",
  workflowStart: "Workflow starten",
  workflowStarted: "Workflow wurde gestartet",
  workflowTest: "Workflow testen",
  workflowChoose: "Vorausgehender Workflow",
  clearDesignerTitle: "Alle Elemente löschen",
  clearDesignerText:
    "Wenn Sie die Aktion bestätigen, werden alle Element aus ihrem Workflow entfernt. Dieser Vorgang kann nur so lange zurückgesetzt werden, bis der Workflow das nächste Mal abgespeichert wird.",
  toggleCriticalPath: "Systemkritischen Pfad ein-/ausblenden",
  toggleMap: "Minimap ein-/ausblenden",
  toggleGrid: "Raster ein-/ausblenden",
  toggleFullscreen: "Vollbild ein-/ausschalten",
  zoomIn: "Vergrößern",
  zoomOut: "Verkleinern",
  saveAsImage: "Als Bild speichern",
  clearWorkflow: "Alle Elemente löschen",
  undo: "Undo",
  redo: "Redo",
  inactive: "Inaktiv",
  queueLow: "Low",
  queueHigh: "High",
  queueNone: "Keine",
  selectElement: "Workflow Element auswählen...",
  configureElement: "{label} konfigurieren",
  showGitHistory: "Git-Historie anzeigen",
  gitHistory: "Git-Historie",
  modeHistory: "Git-Historie",
  revertToThisChange: "zu dieser Änderung zurückkehren",
  areYouSureYouWantToRevert:
    "Sind Sie sicher, dass Sie wiederherstellen möchten?",
  revert: "Zurückkehren",
  input: "Eingabe Parameter",
  output: "Ausgabe Parameter",
  noDebugData: "Keine Debugdaten",
  contactPerson: "Zuständiger Entwickler",
  validationError: "Validierungsfehler",
  requiredFieldsInvalid:
    "Es wurden nicht alle Pflichtfelder richtig ausgefüllt",
  unsavedChangesTitle: "Ungespeicherte Änderungen entdeckt!",
  unsavedChangesText:
    "Es wurden ungespeicherte Änderungen entdeckt. Diese gehen unwiderruflich verloren. Möchten Sie dennoch fortfahren?",
  discardChanges: "Änderungen verwerfen",
  queueSignal: "Achtung: Queue-Unterbrechung",
  queueSignalInfoText:
    "Die Abarbeitung der Queue wurde unterbrochen. Sobald alle aktuell laufenden Prozesse abgearbeitet wurden, werden die Queue-Worker neu gestartet und weitere Jobs wieder abgearbeitet.",
  queueCurrentProcesses: "Aktuell laufende Prozesse:",
  queueWarning: "Queue-Unterbrechung",
  emailRecipient: "Empfänger E-Mail",
  emailNotSending: "keine E-Mails versenden",
  emailOnlyErrors: "nur bei Fehlern",
  emailOnlyWarnings: "nur bei Warnungen",
  emailWarningsErrors: "bei Fehlern und Warnungen",
  configurationUpdated: "Konfiguration aktualisiert",
  option: {
    planning: "Planung",
    authentication: "Authentifizierung",
    configuration: "Konfiguration",
    input: "Eingabe",
    output: "Ausgabe",
    error: "Fehlerverhalten",
    documentation: "Dokumentation"
  },
  componentLibrary: {
    close: "Komponentenbibliothek schließen",
    open: "Komponentenbibliothek öffnen"
  },
  debugView: {
    close: "Debug-View schließen",
    open: "Debug-View öffnen"
  },
  openMapping: "Mapping öffnen",
  openDataSet: "DataSet öffnen",
  openTextTemplate: "Text-Template öffnen",
  openAssignmentTable: "Zuordnungstabelle öffnen",
  openDataStructure: "datenstruktur öffnen",
  openPhpEditor: "PHP-Editor öffnen",
  openWorkflow: "Workflow öffnen",
  openFileExplorer: "File Explorer öffnen ",
  xMoreProcesses: " und {number} mehr",
  sendEmailOn: "E-Mail senden bei",
  createNewWorkflow: "Starte jetzt mit der Erstellung deines ersten Workflow!",
  createNewQueue:
    "Um einen Workflow zu erstellen, müssen Sie zunächst eine Warteschlange erstellen. Klicken Sie hier, um eine neue Warteschlange zu erstellen.",
  status: "Status",
  from: "From",
  to: "To",
  requeue: "Jobs erneut starten",
  selectStatus: "Status auswählen",
  triggerChildProcesses: "Kind-Workflows triggern"
};
